import React from 'react';
// import WorldCloud  from './WorldCloud';
import TopCarousel from './TopCarousel';
import TeaserBanner from './TeaserBanner';
import Tiles from './Tiles';
import Header from './Header';
import Footer from './Footer';
import SearchLogo from './SearchLogo';
import GoogleAnalytics from './GoogleAnalytics';
import ReactLoading from 'react-loading';
var fs = require('browserify-fs')
const generateSitemap = require('./sitemapGenerator');
// import Flinque from './Flinque';
// const logo: any = require('../images/Logo_2021_wvib_Schwarzwald_AG_PFADE_A4_Office.png');

export default function App(this: any) {
  // var jsonNewsData =require("../assets/json/shortNews.json");
  const [newsData, setNews] = React.useState<any[]>([]);
  React.useEffect(() => {
    if(newsData.length===0)
    {
      fetch('/assets/json/shortNews.json').then((res) => res.json())
      .then((jsonNewsData) => {
        setNews(jsonNewsData);
      });
    }
    // document.title = "wvib Schwarzwald AG";
    window.scrollTo(0, 0);
  }, [newsData]);
  if(newsData.length!==0)
  {
  return (
    <div className="App" >
        {/* <Helmet>
            <title>wvib Schwarzwald AG</title>
             <meta property="og:title" content="wvib Schwarzwald AG"/>
              <meta name="twitter:title" content="wvib Schwarzwald AG"/>
              <meta name="description" content="wvib Schwarzwald AG"/>
              <meta property="og:description" content="wvib Schwarzwald AG Home"/>
              <meta name="twitter:description" content="wvib Schwarzwald AG Twitter Home"/>
              <meta property="og:image" content="https://www.digitalphoto.de/media/digitalphoto/styles/tec_frontend_large/public/images/2018/09/image-369055--1346891.jpeg?itok=PRmDtVDU"/>
              <meta name="twitter:image" content="https://www.digitalphoto.de/media/digitalphoto/styles/tec_frontend_large/public/images/2018/09/image-369055--1346891.jpeg?itok=PRmDtVDU"/>
              <meta property="og:image:width"  content="850"/>
              <meta property="og:image:height"  content="350"/>
          </Helmet> */}
      <header className="App-header">
        <Header />
        <div className="container-fluid">
          <div className="search-row">
            <div className="container-row">
              <SearchLogo />
            </div>
          </div>
          <div className="row container-row ">
            {/* Top Carousel  compenent */}
            <TopCarousel newsData={newsData} />
            <TeaserBanner newsData={newsData}/> 
          </div>
          <Tiles />
          {/* <div className="row container-row">
          < WorldCloud />
          </div> */}
        </div>-
      </header>
      <GoogleAnalytics/>
      {/* <Flinque/> */}
      <Footer />
    </div>
  );
  } else{
    return (<div className="row  loading-box">
      <ReactLoading color={"#293940"} width={175} type={"spin"} />
    </div>)
}

}

