import React from 'react';
// import WorldCloud  from './WorldCloud';
import TopCarousel from './TopCarousel';
import TeaserBanner from './TeaserBanner';
import Tiles from './Tiles';
import Header from './Header';
import Footer from './Footer';
import SearchLogo from './SearchLogo';
import GoogleAnalytics from './GoogleAnalytics';



// import Flinque from './Flinque';
 const logo: any = require('../images/Logo_2021_wvib_Schwarzwald_AG_PFADE_A4_Office.png');
export interface IITestProps {
  onChangeHeader:any;
}
export default function TestSeite(props:IITestProps) {
  const [newsData, setNews] = React.useState<any>(null);
  React.useEffect(() => {
    if(newsData===null)
    {
      let currShareUrl = window.location.href;
      // var jsonNewsData =require("../assets/json/shortNews.json");
      var curremdatData:any={title:"Test Seite",description:"Test Seite zweck Test",url:currShareUrl,image:{logo}};
      props.onChangeHeader(curremdatData);
      const currnews=(<div className="App" >
      <header className="App-header">
          <Header />
          <div className="container-fluid">
            <div className="search-row">
              <div className="container-row">
                <SearchLogo />
              </div>
            </div>
            <div className="row container-row ">
       
            <div className="container-fluid canavas-box"><div className=""><div className="webpart-Sectionstatic col-lg-12,col-lg-12" >
              <div className="orisecfactor: null section-1 order:10 gesamtIndex:10 zoneIndex : 1 controlIndex: 1 static-intern-links" >
                <div className="static-tile-box">
                <div className="just-Title"><h1>wvib-Präsident Thomas Burger</h1></div>
                  <div className="static-tile-justImg">
                  <img src="/assets/News/54692-wvib-Praesident-Thomas-Burger_beschnitten.jpg" alt="/assets/News/54692-wvib-Praesident-Thomas-Burger_beschnitten.jpg"/>
                  <div  className="img-Title"></div></div></div></div>
                  <div className="static-content-txt section-1 order:20 gesamtIndex:20 zoneIndex : 1 controlIndex: 2 " >
                    <p>Der Präsident der wvib Schwarzwald AG repräsentiert das größte Unternehmernetzwerk in Baden-Württemberg in der Öffentlichkeit und liefert wichtige Impulse für die strategische Weiterentwicklung. Seit 2017 bekleidet Thomas Burger, geschäftsführender Gesellschafter der Burger Group aus Schonach im Schwarzwald,&nbsp;dieses Amt. 2020 wurde er einstimmig vom wvib-Vorstand für drei weitere Jahre wiedergewählt.</p><h3>
                      <span className="fontSizeXxLarge">Personalien</span></h3>
                      <p>Geburtsdatum: 25. Januar 1961</p><p>Nationalität:&nbsp;Deutsch</p>
                      <p>Familienstand: verheiratet, drei Kinder</p><p>Hobbies:&nbsp;Familie, Eishockey, Pferde​​​​​​​</p>
                      <p><br/>
                      </p><h2>Kurzlebenslauf​​​​​​​</h2><h3>
                        <span className="fontSizeXLarge">Studium</span></h3><p><strong>1984-1989</strong>&nbsp;Studium zum Diplomkaufmann an der Universität Stuttgart</p><h3>
                          <span className="fontSizeXLarge">Ausbildung</span></h3><p>​​​​​​​<strong>1982-1984</strong>&nbsp;Lehre als Feinmechaniker</p><p><strong>1981-1982</strong>&nbsp;Bundeswehr</p><h3>
                            <span className="fontSizeXLarge">Schulausbildung</span></h3><p><strong>1981</strong>&nbsp;Abitur (Wirtschaftsgymnasium Furtwangen)</p><h3>
                              <span className="fontSizeXLarge">Berufliche Tätigkeiten</span></h3><p><strong>2017</strong>&nbsp;Gründung der SBS Drivetec Inc. und KBS Molding Inc. in Barrie, Ontario, Kanada</p><p><strong>2016</strong>&nbsp;Mehrheitsbeteiligung an der Systemtechnik LEBER GmbH &amp; Co. KG, Schwaig</p><p><strong>2015</strong>&nbsp;Übernahme der Hugger GmbH, Deißlingen</p><p><strong>2014</strong>&nbsp;Gründung der SBS-Nepron s.r.o., Tschechien</p><p><strong>2013</strong>&nbsp;Aufgabe der Beteiligung an der Grässlin KBS</p>
                              <p><strong>2012</strong>&nbsp;Übernahme der WEDO Feinbau GmbH, nunmehr WEDO Antriebstechnik GmbH</p>
                              <p><strong>2011</strong>&nbsp;Eröffnung des Werks Triberg</p><p><strong>2007</strong>&nbsp;Beteiligung an der ORCA KBS GmbH</p>
                              <p><strong>seit 2001</strong>&nbsp;Geschäftsführender Gesellschafter der KBS-Spritztechnik CH GmbH</p>
                              <p><strong>2000</strong>&nbsp;Beteiligung an der Grässlin KBS GmbH</p>
                              <p><strong>1993</strong>&nbsp;Gründung der KBS-Spritztechnik GmbH in Schonach – Alleiniger Geschäftsführer</p>
                              <p><strong>seit 1993</strong>&nbsp;Geschäftsführender Gesellschafter der SBS-Feintechnik GmbH &amp; Co. KG</p>
                              <p><strong>1991</strong>&nbsp;Eintritt in das elterliche Eigentümer geführte Familienunternehmen SBS-Feintechnik in der 5. Generation als Assistent der Geschäftsführung</p>
                              <p><strong>1990-1991</strong>​​​​​​​ Auslandstätigkeit in einem Zweigwerk eines Schweizer Automobilzulieferers in Kanada</p><p><strong>
                                <span className="fontSizeXLarge">Weitere Ämter</span></strong></p><p><strong>seit 2015&nbsp;</strong>2. Vorsitzender des Reitvereins St. Hubertus, Villingen-Schwenningen</p>
                                <p><strong>27.12.2010&nbsp;</strong>Ernennung zum Handelsrichter bei den Kammern für Handelssachen bei dem Landgericht Konstanz</p>
                                <p><strong>seit 2009&nbsp;</strong>Beiratsmitglied im wvib Wirtschaftsverband Industrieller Unternehmen Baden e.V., ab 2014 Vorstandsmitglied, ab 2016 Präsidiumsmitglied, ab 2017 Präsident</p><p><strong>seit 2009&nbsp;</strong>Mitglied des Wirtschaftsbeirates der Sparkasse Schwarzwald-Baar</p>
                                <p><strong>seit 2005&nbsp;</strong>Geschäftsführender Gesellschafter der SERC Wild Wings Vermarktungs- und Betriebs GmbH (Profi-Eishockey-Mannschaft der DEL)</p><p><strong>seit 2005​​​​​​​&nbsp;</strong>Gründungs- und Vorstandsmitglied von Technology Mountains – 2015 aus dem Vorstand ausgeschieden</p><p><strong>seit 2002&nbsp;</strong>Mitglied der IHK-Vollversammlung</p><p><strong>seit 2000&nbsp;</strong>Verwaltungsratsmitglied des BKK-Landesverbandes Süd</p><p><strong>seit 1996&nbsp;</strong>Verwaltungsratsvorsitzender der damaligen BKK Josef Burger Söhne, jetzt BKK SBH</p></div></div></div></div>
            </div>
            {/* <Tiles /> */}
            {/* <div className="row container-row">
            < WorldCloud />
            </div> */}
          </div>-
        </header>
        <GoogleAnalytics/>
        {/* <Flinque/> */}
        <Footer />
      </div>);
      setNews(currnews);
    }
  },[newsData, props])

if(newsData!==null)
{
  return newsData
}else {
  return(
  <div className="App" >
      <header className="App-header">
          <Header />
          <div className="container-fluid">
            <div className="search-row">
              <div className="container-row">
                <SearchLogo />
              </div>
            </div>
            <div className="row container-row ">
       
            <div className="container-fluid canavas-box"><div className=""><div className="webpart-Sectionstatic col-lg-12,col-lg-12" >
              <div className="orisecfactor: null section-1 order:10 gesamtIndex:10 zoneIndex : 1 controlIndex: 1 static-intern-links" >
                <div className="static-tile-box">
                <div className="just-Title"><h1>wvib-Präsident Thomas Burger</h1></div>
                  <div className="static-tile-justImg">
                  <img src="/assets/News/54692-wvib-Praesident-Thomas-Burger_beschnitten.jpg" alt="/assets/News/54692-wvib-Praesident-Thomas-Burger_beschnitten.jpg"/>
                  <div  className="img-Title"></div></div></div></div>
                  <div className="static-content-txt section-1 order:20 gesamtIndex:20 zoneIndex : 1 controlIndex: 2 " >
                    <p>Der Präsident der wvib Schwarzwald AG repräsentiert das größte Unternehmernetzwerk in Baden-Württemberg in der Öffentlichkeit und liefert wichtige Impulse für die strategische Weiterentwicklung. Seit 2017 bekleidet Thomas Burger, geschäftsführender Gesellschafter der Burger Group aus Schonach im Schwarzwald,&nbsp;dieses Amt. 2020 wurde er einstimmig vom wvib-Vorstand für drei weitere Jahre wiedergewählt.</p><h3>
                      <span className="fontSizeXxLarge">Personalien</span></h3>
                      <p>Geburtsdatum: 25. Januar 1961</p><p>Nationalität:&nbsp;Deutsch</p>
                      <p>Familienstand: verheiratet, drei Kinder</p><p>Hobbies:&nbsp;Familie, Eishockey, Pferde​​​​​​​</p>
                      <p><br/>
                      </p><h2>Kurzlebenslauf​​​​​​​</h2><h3>
                        <span className="fontSizeXLarge">Studium</span></h3><p><strong>1984-1989</strong>&nbsp;Studium zum Diplomkaufmann an der Universität Stuttgart</p><h3>
                          <span className="fontSizeXLarge">Ausbildung</span></h3><p>​​​​​​​<strong>1982-1984</strong>&nbsp;Lehre als Feinmechaniker</p><p><strong>1981-1982</strong>&nbsp;Bundeswehr</p><h3>
                            <span className="fontSizeXLarge">Schulausbildung</span></h3><p><strong>1981</strong>&nbsp;Abitur (Wirtschaftsgymnasium Furtwangen)</p><h3>
                              <span className="fontSizeXLarge">Berufliche Tätigkeiten</span></h3><p><strong>2017</strong>&nbsp;Gründung der SBS Drivetec Inc. und KBS Molding Inc. in Barrie, Ontario, Kanada</p><p><strong>2016</strong>&nbsp;Mehrheitsbeteiligung an der Systemtechnik LEBER GmbH &amp; Co. KG, Schwaig</p><p><strong>2015</strong>&nbsp;Übernahme der Hugger GmbH, Deißlingen</p><p><strong>2014</strong>&nbsp;Gründung der SBS-Nepron s.r.o., Tschechien</p><p><strong>2013</strong>&nbsp;Aufgabe der Beteiligung an der Grässlin KBS</p>
                              <p><strong>2012</strong>&nbsp;Übernahme der WEDO Feinbau GmbH, nunmehr WEDO Antriebstechnik GmbH</p>
                              <p><strong>2011</strong>&nbsp;Eröffnung des Werks Triberg</p><p><strong>2007</strong>&nbsp;Beteiligung an der ORCA KBS GmbH</p>
                              <p><strong>seit 2001</strong>&nbsp;Geschäftsführender Gesellschafter der KBS-Spritztechnik CH GmbH</p>
                              <p><strong>2000</strong>&nbsp;Beteiligung an der Grässlin KBS GmbH</p>
                              <p><strong>1993</strong>&nbsp;Gründung der KBS-Spritztechnik GmbH in Schonach – Alleiniger Geschäftsführer</p>
                              <p><strong>seit 1993</strong>&nbsp;Geschäftsführender Gesellschafter der SBS-Feintechnik GmbH &amp; Co. KG</p>
                              <p><strong>1991</strong>&nbsp;Eintritt in das elterliche Eigentümer geführte Familienunternehmen SBS-Feintechnik in der 5. Generation als Assistent der Geschäftsführung</p>
                              <p><strong>1990-1991</strong>​​​​​​​ Auslandstätigkeit in einem Zweigwerk eines Schweizer Automobilzulieferers in Kanada</p><p><strong>
                                <span className="fontSizeXLarge">Weitere Ämter</span></strong></p><p><strong>seit 2015&nbsp;</strong>2. Vorsitzender des Reitvereins St. Hubertus, Villingen-Schwenningen</p>
                                <p><strong>27.12.2010&nbsp;</strong>Ernennung zum Handelsrichter bei den Kammern für Handelssachen bei dem Landgericht Konstanz</p>
                                <p><strong>seit 2009&nbsp;</strong>Beiratsmitglied im wvib Wirtschaftsverband Industrieller Unternehmen Baden e.V., ab 2014 Vorstandsmitglied, ab 2016 Präsidiumsmitglied, ab 2017 Präsident</p><p><strong>seit 2009&nbsp;</strong>Mitglied des Wirtschaftsbeirates der Sparkasse Schwarzwald-Baar</p>
                                <p><strong>seit 2005&nbsp;</strong>Geschäftsführender Gesellschafter der SERC Wild Wings Vermarktungs- und Betriebs GmbH (Profi-Eishockey-Mannschaft der DEL)</p><p><strong>seit 2005​​​​​​​&nbsp;</strong>Gründungs- und Vorstandsmitglied von Technology Mountains – 2015 aus dem Vorstand ausgeschieden</p><p><strong>seit 2002&nbsp;</strong>Mitglied der IHK-Vollversammlung</p><p><strong>seit 2000&nbsp;</strong>Verwaltungsratsmitglied des BKK-Landesverbandes Süd</p><p><strong>seit 1996&nbsp;</strong>Verwaltungsratsvorsitzender der damaligen BKK Josef Burger Söhne, jetzt BKK SBH</p></div></div></div></div>
            </div>
            {/* <Tiles /> */}
            {/* <div className="row container-row">
            < WorldCloud />
            </div> */}
          </div>-
        </header>
        <GoogleAnalytics/>
        {/* <Flinque/> */}
        <Footer />
      </div>);
}
}
