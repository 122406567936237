import * as React from 'react';
import { FaInstagram,FaLinkedinIn, FaYoutube } from 'react-icons/fa';
// import { useHistory } from "react-router-dom";
export default function Footer() {
  // let history = useHistory();
  return (
    <footer className="page-footer font-small wvib-footer text-white">
      <div className="container-fluid mt-5">
        <div className="row mt-3 footer-txt-row">
          <div className="col-sm-12 col-md-12 col-lg-4 social-media-box">
            {/* <section className="col-sm-12 col-md-12 col-xs-12"> */}

              {/* <!-- Linkedin --> */}
              <a
                className="btn text-white btn-floating m-1"
                style={{ backgroundColor: "transparent" }}
                href="https://www.linkedin.com/company/wvib-wirtschaftsverband-industrieller-unternehmen-baden-e-v-"
                role="button">
                <FaLinkedinIn size={"30px"} />
              </a>
              {/* <!-- Instagram --> */}
              <a
                className="btn text-white btn-floating m-1"
                style={{ backgroundColor: "transparent" }}
                href="https://www.instagram.com/wvib_schwarzwald_ag/?hl=de"
                role="button">
                <FaInstagram size={"30px"} />
              </a>
              {/* <!-- Youtube --> */}
              <a
                className="btn text-white btn-floating m-1"
                style={{ backgroundColor: "transparent" }}
                href="https://www.youtube.com/@wvib"
                role="button">
                <FaYoutube size={"30px"} />
              </a>
              {/* <!-- Twitter --> 
              <a
                className="btn text-white btn-floating m-1"
                style={{ backgroundColor: "transparent" }}
                href="https://twitter.com/wvib"
                role="button">
                <FaTwitter size={"30px"} />
              </a>
              */}
            {/* </section> */}
            {/* <!-- Section: Social media --> */}
          </div>
          <div className="col-sm-12 col-lg-8 footer-left-box">
            <div className="footer-column">
              <a href='/Pages/Impressum.aspx' className="footer-item" onClick={(e) => window.open('/Pages/Impressum.aspx', '_self')}>Impressum</a>
            </div>
            <div className="footer-column">
              <a href='/Pages/Datenschutz.aspx' className="footer-item" onClick={(e) => window.open('/Pages/Datenschutz.aspx', '_self')}>Datenschutz</a>
            </div>
            <div className="footer-column">
              <a href='/Pages/Kontakt.aspx' className="footer-item" onClick={(e) => window.open('/Pages/Kontakt.aspx', '_self')} >Kontakt</a>
            </div>
            <div className="footer-column">
              <a href='/Pages/AnfahrtwvibAkademie.aspx' className="footer-item" onClick={(e) => window.open('/Pages/AnfahrtwvibAkademie.aspx', '_self')}>Anfahrt</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}