import * as React from 'react';
import {useHistory } from 'react-router-dom';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/WordCloud.css');
// require('../css/AkademieStyles.css');

export interface IVeranstaltungKachelComponentProps {
  description: string;
  veranstaltung: any;
  eventsNoExpand?:any
}

export default function VeranstaltungKachelComponent(props: IVeranstaltungKachelComponentProps) {
  const history = useHistory();
  var days:string = "Tage";


  React.useEffect(() => {
  },[]);

  if(props.veranstaltung === null)
  {
    return <></>;
  }

  if(props.veranstaltung.Dauer === "")
  {
    days = "-";
  }
  else if(props.veranstaltung.Dauer === "1" || props.veranstaltung.Dauer === 1)
  {
    days = "Tag";
  }

  if(props.veranstaltung.Ort === ", ,  ")
  {
    props.veranstaltung.Ort = "-";
  }

  var kindImg:string = `${process.env.PUBLIC_URL}/assets/images/Icon_Praesenzseminar.svg`;
  if(props.veranstaltung.Kind.toLowerCase() === "lehrgang")
  {
    kindImg = `${process.env.PUBLIC_URL}/assets/images/Icon_Praxistage.svg`;
  }
  else if(props.veranstaltung.Kind.toLowerCase() === "online-seminar" || props.veranstaltung.Kind.toLowerCase() === "online-sem")
  {
    kindImg = `${process.env.PUBLIC_URL}/assets/images/Icon_Onlineseminar.svg`;
  }

  var coloredDot:JSX.Element = <></>;
  if(props.veranstaltung.Auslastung!==undefined &&props.veranstaltung.Auslastung.indexOf("/") > -1)
  {
    var auslastungsArray:any[] = props.veranstaltung.Auslastung.split("/");
    var auslastung:number = parseInt(auslastungsArray[1]) - parseInt(auslastungsArray[0]);
    if(props.eventsNoExpand!==undefined && props.eventsNoExpand)
    {
      auslastung = parseInt(props.eventsNoExpand.Max_Participant) - parseInt(props.eventsNoExpand.Participant_total);
      if(auslastung > 0)
      {
        coloredDot = <img className="microIcon" src={`${process.env.PUBLIC_URL}/assets/images/GreenDot.png`} alt="greenDot"></img>;
      }
      else
      {
        coloredDot = <img className="microIcon" src={`${process.env.PUBLIC_URL}/assets/images/RedDot.png`} alt="redDot"></img>;
      }
    }else{
      if(auslastung > 0)
      {
        coloredDot = <img className="microIcon" src={`${process.env.PUBLIC_URL}/assets/images/GreenDot.png`} alt="greenDot"></img>;
      }
      else
      {
        coloredDot = <img className="microIcon" src={`${process.env.PUBLIC_URL}/assets/images/RedDot.png`} alt="redDot"></img>;
      }
    }
    
   
  }

  var showDate:any = props.veranstaltung.Datum;
  if(props.veranstaltung.Datum !==  props.veranstaltung.EndDatum)
  {
    showDate = props.veranstaltung.Datum +  " - " +  props.veranstaltung.EndDatum;
  }

  return (
      <table className="topTable" onClick={function() {history.push({pathname:"/VeranstaltungsDetails", search:"?key=" + props.veranstaltung.Key});} }>
        <tbody className="tbodyStyle">
          <tr className="baseTrStyle">
            <td className="typeIconCell">
              <img className="typeIcon" src={kindImg} alt="typeIcon"></img>
            </td>
            <td className="contentCell">
              <table className="tableBaseStlye">
                <tbody>
                  <tr>
                    <td colSpan={4} className="headerLineStyle">
                      {props.veranstaltung.Key} {coloredDot}   {props.veranstaltung.Kind}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="tdTitle">
                      {props.veranstaltung.Bezeichnung}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="headerLineStyle">
                      {props.veranstaltung.Text}
                    </td>
                  </tr>
                  <tr>
                    <td className="tdQuarter dateColumn">
                      <table>
                        <tbody>
                          <tr>
                            <td className="iconTd">
                              <img className="miniIcon" src={`${process.env.PUBLIC_URL}/assets/images/Kalender.png`} alt="kal"></img>
                            </td>
                            <td className="tdIconText">
                              {showDate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="tdQuarter durationColumn">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <img className="miniIcon" src={`${process.env.PUBLIC_URL}/assets/images/Uhr.png`} alt="uhrImg"></img>
                            </td>
                            <td className="tdIconText">
                              {props.veranstaltung.Dauer} {days}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    {/* <td style={tdQuarter}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <img style={miniIcon} src="{`${process.env.PUBLIC_URL}/assets/images/Euro.png"></img>
                            </td>
                            <td style={tdIconText}>
                              {props.veranstaltung.Preis}€
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td> */}
                    <td className="tdQuarter ortColumn">
                      <table>
                        <tbody>
                          <tr>
                            <td className="tdCenterIcon">
                              <img className="miniIcon" src={`${process.env.PUBLIC_URL}/assets/images/Pointer.png`} alt="pointerImg"></img>
                            </td>
                            <td className="tdIconText">
                              {props.veranstaltung.Ort}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
  );

}
