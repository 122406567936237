import * as React from 'react';
import { TextField} from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton,Dropdown } from 'office-ui-fabric-react';
import AzureConnector from '../Module/AzureConnector_Anon';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/AkademieStyles.css');

export interface INeuerBenutzerComponentProps {
  userNummer: string;
  userData: iBenutzerdaten;
  isOnly?: boolean;
  onDelete: (userNumber: string) => void;
  userOrigin: string;
  participant:boolean;
}

export type iBenutzerdaten = {
  userKey: number,
  Salutation: string,
  Surname:string,
  First_Name:string,
  Address:string,
  Address_2:string,
  Post_Code:string,
  City:string,
  E_Mail:string,
  Phone_No:string,
  Company_Name:string,
  Event_No:string,
  No:string,
  Occupation:string,
  Job_Title?:string,
  Leader:boolean,
  Remark:string,
  Portalbenutzer:boolean,
  Bill_to_as_E_Mail?:boolean,
  Bill_to_E_Mail?:string,
};

export default function NeuerBenutzerComponent(props: INeuerBenutzerComponentProps) {
  const [userData, setUserData] = React.useState<iBenutzerdaten>(null);
  const [salutations, setSalutations] = React.useState(null);
  const [tmpSalutations, setTmpSalutations] = React.useState(null);
  const urlEndPart = "/nav/salutations?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";

  let quarterTD:React.CSSProperties = {
    width: '25%'
  }

  let userTD:React.CSSProperties = {
    padding: '1%'
  }

  let userTable:React.CSSProperties = {
    width: '100%',
    backgroundColor: '#F1F1F1',
    paddingRight: '2%',
    paddingLeft: '2%'
  }

  React.useEffect(() => {
    if(salutations == null)
    {
      const azureCon = new AzureConnector();
      azureCon.get(urlEndPart)
          .then((result: any) => {
              if(result.length > 0)
              {
                var salutationObject:any[] = [];
                var tmpSalutationObject:any[] = [];

                for (var i:number = 0; i < result.length; i++)
                {
                  salutationObject.push({ key: result[i].Code, text: result[i].Sex });
                  if(result[i].Sex ==="Male")
                  {
                    tmpSalutationObject.push({key: result[i].Code, text: "Herr"});
                  }else
                  {
                    tmpSalutationObject.push({key: result[i].Code, text: "Frau"});
                  }
                }
                setTmpSalutations(tmpSalutationObject);
                setSalutations(salutationObject);
              }
          })
          .catch((error: any) => {
              console.error(error);
          });
    }
  },[salutations]);



  React.useEffect(() => {
    setUserData(InitialUserData());
  },[]);

  if(!userData || salutations == null)
  {
    return <></>;
  }

  const deleteButtonJsx = setButton();

  return (
    <div className={"CommonDivContainer"} key={"user" + props.userNummer}>
      <table style={userTable}>
        <tr>
          <td style={quarterTD} colSpan={1}></td>
          <td style={quarterTD} colSpan={1}></td>
          <td style={quarterTD} colSpan={1}></td>
          <td style={quarterTD} colSpan={1}></td>
        </tr>
        <tr>
          <td style={userTD} colSpan={2}>
  	        <Dropdown id={"userSalutation" + props.userNummer} label="Anrede *" options={tmpSalutations}
              onChange={(e, newValue) => {
                const tmpStamm = {...userData};
                let currentSalulation="Male"
                if(newValue.text==="Frau")
                currentSalulation="Female";
                tmpStamm.Salutation = currentSalulation;
                setUserData(tmpStamm);
                props.userData.Salutation = currentSalulation;
              }}
            />
          </td>
          <td style={userTD} colSpan={2}>
            <TextField id={"userTitle" + props.userNummer} value={userData.Job_Title} label="akad. Titel" 
              onChange={(e, newValue) => {
                const tmpStamm = {...userData};
                tmpStamm.Job_Title = newValue;
                setUserData(tmpStamm);
                props.userData.Job_Title = newValue;
              }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"userName" + props.userNummer} value={userData.Surname} label="Nachname *"
            onChange={(e, newValue) => {
              const tmpStamm = {...userData};
              tmpStamm.Surname = newValue;
              setUserData(tmpStamm);
              props.userData.Surname = newValue;
            }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"userVorname" + props.userNummer} value={userData.First_Name} label="Vorname *"
            onChange={(e, newValue) => {
              const tmpStamm = {...userData};
              tmpStamm.First_Name = newValue;
              setUserData(tmpStamm);
              props.userData.First_Name = newValue;
            }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"userEmail" + props.userNummer} value={userData.E_Mail.trim()} label="E-Mail *"
            onChange={(e, newValue) => {
              const tmpStamm = {...userData};
              tmpStamm.E_Mail = newValue.trim();
              setUserData(tmpStamm);
              props.userData.E_Mail = newValue.trim();
            }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"userTel" + props.userNummer} value={userData.Phone_No} label="Telefon"
            onChange={(e, newValue) => {
              const tmpStamm = {...userData};
              tmpStamm.Phone_No = newValue;
              setUserData(tmpStamm);
              props.userData.Phone_No = newValue;
            }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
              <TextField id={"userTätigkeit" + props.userNummer} value={userData.Occupation} label="Tätigkeit als *"
              onChange={(e, newValue) => {
                const tmpStamm = {...userData};
                tmpStamm.Occupation = newValue;
                setUserData(tmpStamm);
                props.userData.Occupation = newValue;
              }}
              />
            </td>
          </tr>
      </table>
      {deleteButtonJsx}
    </div>
  );

  function InitialUserData()
  {
    var emptyDummyJson:string = '{"Salutation": "", "Surname": "", "First_Name": "", "AdressHno": "", "Post_Code": "", "City": "", "E_Mail": "", "tel": "", "Company_Name": ""}';
    var jsonData:iBenutzerdaten = JSON.parse(emptyDummyJson);

    return jsonData;
  }

  function setButton()
  {
    if(props.userOrigin === "anon" && props.userNummer !== "1")
    {
      return <DefaultButton className="WvibButton_Reg" onClick={ e => {props.onDelete(props.userNummer);}}>Diesen Teilnehmer löschen</DefaultButton>
      
      // <div onClick={ e => {props.onDelete(props.userNummer);}}>
      //   <img src="images/events/Cross.png" style={{maxHeight: '10px', marginRight: '1%'}}/>
      //   <label style={{fontSize: '13px', color: 'grey'}}>Benutzer löschen</label>
      // </div>
    }
    else
    {
      return <></>;
    }
  }
}
