import * as React from 'react';
import {  useHistory, useLocation } from 'react-router-dom';
import { DefaultButton } from 'office-ui-fabric-react';
import AzureConnector from '../Module/AzureConnector_Anon';
import Header from './Header';
import Footer from './Footer';
import VeranstaltungKachelComponent from './VeranstaltungKachelComponent_Anon';
import SearchComponent from './SearchComponent_Anon';
import RubrikKachelComponent from './RubrikKachelComponent_Anon';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/WordCloud.css');
// require('../css/AkademieStyles.css');


export interface IVeranstaltungsübersichtProps {
  onChangeHeader:any
}

export default function Veranstaltungsübersicht(props: IVeranstaltungsübersichtProps) {
  // const eventsJson=require('../assets/json/events.json');
  const [eventsList,setEventsList]=React.useState<any[]>([]);
  const [eventsNoExpand,setEventsNoExpand]=React.useState<any[]>([]);
  const [veranstaltungen, setVeranstaltungenJson] = React.useState(null);
  const history = useHistory();
  const location = useLocation();
  const [rubrik, setRubrik] = React.useState(null);
  const [subRubrik, setSubRubrik] = React.useState("");
  const azureCon = new AzureConnector();
  const [veranstaltungenJsx, fillVeranstaltungenJsx] = React.useState<JSX.Element>(null);
  const [currentVeranstaltungen, setCurrentVeranstaltungen] = React.useState(null);
  const [rubriken, setRubriken] = React.useState(null);
  const [warningText, setWarningText] = React.useState<string>("Bitte warten, Daten werden geladen...");
  const [rubrikTilesJsx, setRubrikTilesJsx] = React.useState(null);
  const [searchResult, setSearchResult] = React.useState(true);
  const [searchResultText, setSearchResultText] = React.useState("");
  const [currMetaData, setcurrMetaData] = React.useState(null);
  let currShareUrl = window.location.href;

  let rubrikTable:React.CSSProperties = {
    margin: '5% -0.5em 0px -1em',
    width: '100%',
    maxWidth: '100%',
    boxShadow: 'none'
  };

  React.useEffect(() => {
    if(!searchResult)
    {
      setSearchResultText("Es wurden keine Ergebnisse zu ihrer Suche gefunden.");
      fillVeranstaltungenJsx(null);
    }
    else
    {
      setSearchResultText("");
    }
  },[searchResult]);

  React.useEffect(() => {
    if(rubrik !== null && rubrik !== "")
    {
      
      var searchValue:any = rubrik;
      
      if (subRubrik && subRubrik !== "")
      {
        searchValue = subRubrik;
      }
      if(eventsList.length===0)
      {
        var _eventsList:any[]=[];
        var _eventsListNoExpand:any[]=[];
        
        // fetch('/assets/json/eventsTST.json').then((res) => res.json())
        fetch('/assets/json/eventsNoExpand.json').then((res) => res.json())
        .then((jsonEventsData) => {
          jsonEventsData.forEach((element:any)=>{
         
                _eventsListNoExpand.push(element);
            });
          setEventsNoExpand(_eventsListNoExpand);
        })
        // fetch('/assets/json/events.json').then((res) => res.json())
        // .then((jsonEventsData) => {
        //   jsonEventsData.forEach((element:any)=>{
        //   if(element.KeyWords)
        //   {
        //     console.log("element.KeyWords",element.KeyWords);
        //     element.KeyWords.forEach((item:any)=>{
        //       console.log("item.Key_Word",item.Key_Word);
        //       if(item.Key_Word!==null&&item.Key_Word.toLowerCase()===searchValue.toLowerCase())
        //       {
        //         _eventsList.push(element);
        //       }
        //     });
        //   }
        // })
        if(_eventsList.length===0)
        {
           const urlEndPart = "/nav/keywords/" + searchValue + "/events?expand=texts,services&code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
           azureCon.get(urlEndPart)
              .then((result: any) => {
                  // setVeranstaltungenJson(getValfromNav(result));
                  setEventsList(result);
              })
              .catch((error: any) => {
                  // setVeranstaltungenJson(null);
                  setWarningText("Es wurden keine Veranstaltungen in dieser Rubrik gefunden.");
                  // console.error(error);
              });
     
            const urlEndPartRubriken = "/sp/terms/" + rubrik + "?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
            azureCon.get(urlEndPartRubriken)
                .then((result: any) => {
                    setRubriken(getRubrikFromAzure(result));
                })
                .catch((error: any) => {
                    // console.error(error);
                });
        }else{
          setEventsList(_eventsList);
        }
        // })
        // if(_eventsList.length===0)
        // {
        //    const urlEndPart = "/nav/keywords/" + searchValue + "/events?expand=texts,services&code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
        //    azureCon.get(urlEndPart)
        //       .then((result: any) => {
        //           setVeranstaltungenJson(getValfromNav(result));
        //       })
        //       .catch((error: any) => {
        //           setVeranstaltungenJson(null);
        //           setWarningText("Es wurden keine Veranstaltungen in dieser Rubrik gefunden.");
        //           console.error(error);
        //       });
      
        //     const urlEndPartRubriken = "/sp/terms/" + rubrik + "?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
        //     azureCon.get(urlEndPartRubriken)
        //         .then((result: any) => {
        //             setRubriken(getRubrikFromAzure(result));
        //         })
        //         .catch((error: any) => {
        //             console.error(error);
        //         });
        // }
      
      }else
       if(eventsList.length>0)
        {
          setVeranstaltungenJson(getValfromNav(eventsList));
        }
     
    }
  },[eventsList, rubrik, subRubrik]);

  React.useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.has('rubrik')) {
      setRubrik(search.get('rubrik'));
    }
    if (search.has('subRubrik')) {
      setSubRubrik(search.get('subRubrik'));
    }
  }, [location]);

  React.useEffect(() => {
    setCurrentVeranstaltungen(veranstaltungen);
  }, [veranstaltungen]);

  React.useEffect(() => {
    if(currentVeranstaltungen !== null && currentVeranstaltungen !== "")
    {
      fillVeranstaltungenJsx(doFillVeranstaltungenJsx(currentVeranstaltungen,eventsNoExpand));
    }
    else
    {
      fillVeranstaltungenJsx(null);
    }
  }, [currentVeranstaltungen]);

  React.useEffect(() => {
    if(rubriken !== null && rubriken !== "")
    {
      if(currMetaData===null)
      {
        setcurrMetaData({title:rubriken,description:"Seminare und Lehrgänge von "+rubriken,url:currShareUrl,image:"/assets/News/banner01.jpg"});
        props.onChangeHeader({title:"Seminarfinder",description:"Seminare und Lehrgänge",url:currShareUrl,image:"/assets/News/banner01.jpg"});
      }
      setRubrikTilesJsx(loadRubrikTiles());
    }
  }, [rubriken]);

  const searchJsx = loadSearch();

  function loadSearch()
  {
    return <SearchComponent key="searchKey" description="" setVeranstaltung={setVeranstaltungenJson} setSearchResult={setSearchResult} veranstaltungen={veranstaltungen} setCurrentVeranstaltung={setCurrentVeranstaltungen} fullSearch={true} rubrik={rubrik}/>;
  }

  try{
    // var quicklinksWebpart = document.querySelector('[data-viewport-id*="WebPart.QuickLinksWebPart"]') as HTMLElement;
    // quicklinksWebpart.style.display = "none";
    var titleImg:HTMLElement = document.querySelectorAll('[data-viewport-id*="WebPart.ImageWebPart.internal"]')[1] as HTMLElement;
    titleImg.style.display = "none";
  }
  catch{}

  function loadRubrikTiles()
  {
    var rubrikTiles:any[] = [];
    for(var i:number = 0; i < rubriken.length; i++)
    {
      // rubrikTiles.push(<div style={rubrikTileTop} key={"rubrikKey" + i} className={"col-lg-3 col-md-6 col-sm-12"} >
      //     <div style={rubrikTile}>
      //       <span style={rubrikContent}>
      //         <RubrikKachelComponent title={rubriken[i]}></RubrikKachelComponent>
      //       </span>
      //     </div>
      //   </div>);

      rubrikTiles.push(<div key={"rubrikKey" + i} className={`${"tile-Box"} col-lg-3 col-md-6 col-sm-12`} >
      <RubrikKachelComponent title={rubriken[i]}></RubrikKachelComponent>
    </div>);
    }

    return <div className={"row"} >{rubrikTiles}</div>;
  }

  if(veranstaltungen == null || veranstaltungen.length === 0)
  {
    return <div>
        <header className={"App-header"}>
          <Header/>
          <div className={"WvibGenericPageLimiter"}>
            <div className={"container-fluid"}>
                <div className={"container-row"} style={{fontFamily:'Roboto'}}>
                {warningText}
                <div>
                  <DefaultButton className={"WvibButton"} onClick={() => history.goBack()}>Zurück</DefaultButton>
                </div>
                <div className={"container"} style={rubrikTable}>
                  {rubrikTilesJsx}
                </div>
              </div>
            </div>
          </div>
        </header>
      <Footer/>
    </div>;
  }
  return <div>
      <header className={"App-header"}>
        <Header/>
        <div className={"WvibGenericPageLimiter"}>
          <div className={"container-fluid"}>
            <div className={"container-row"}>
              <div style={{fontFamily:'Roboto'}}>
                <h2>{rubrik}</h2>
                <div className='search-table-box' style={{border: '1px solid #C7C7C7', marginTop: '2%',}}>
                  {searchJsx}
                </div>
                <div>
                  <DefaultButton className={"WvibButton"} onClick={() => history.goBack()}>Zurück</DefaultButton>
                </div>
                <div style={{color: 'black', paddingTop: '1%'}}>
                  {searchResultText}
                </div>
                <div>
                  {veranstaltungenJsx}
                </div>
                <div>
                  <DefaultButton className={"WvibButton"} onClick={() => history.goBack()}>Zurück</DefaultButton>
                </div>
                <div className={"container"} style={rubrikTable}>
                  {rubrikTilesJsx}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer/>
    </div>;
}

function getValfromNav(jsonInput: string | any[])
{
  var resArray:any[] = [];

  for(var i = 0; i < jsonInput.length; i++)
  {
    var dateSplit:any[] = jsonInput[i].Start_date.split("T")[0].split("-");
    var fullDate:string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];

    // eslint-disable-next-line @typescript-eslint/no-redeclare
    var dateSplit:any[] = jsonInput[i].End_date.split("T")[0].split("-");
    var endFullDate:string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];

    var text:string = "";
    if(jsonInput[i].Texts !== undefined && jsonInput[i].Texts !== '')
    {
      for(var j:number = 0; j < jsonInput[i].Texts.length; j++)
      {
        if(jsonInput[i].Texts[j].Text_Type_No === "1")

        text = jsonInput[i].Texts[j].HTML_Content;
        text = text.replace(/<[^>]*>?/gm, '');
        text = HtmlDecode(text);
        if(text.length > 200)
        {
          text = text.substring(0, 200) + "...";
        }
        break;
      }
    }

    var price:string = "-";
    if(jsonInput[i].Services)
    {
      if(jsonInput[i].Services.length > 1)
      {
        // eslint-disable-next-line @typescript-eslint/no-redeclare
        for(var j:number = 0; j < jsonInput[i].Services.length; j++)
        {
          if(jsonInput[i].Services[j].Mitglieder_Leistung === false)
          {
            price = jsonInput[i].Services[j].Amount_LCY_net;
            break;
          }
        }
      }
      else if (jsonInput[i].Services.length === 1)
      {
        price = jsonInput[i].Services[0].Amount_LCY_net;
      }
    }
        let duration:any =jsonInput[i].CourseDuration;
         if(jsonInput[i].CourseDuration!==undefined ){
           if(( jsonInput[i].CourseDuration===0.0 || jsonInput[i].CourseDuration===0))
             duration=jsonInput[i].Duration;
         }
         resArray.push({"Bezeichnung": jsonInput[i].Description,
                      "Key": jsonInput[i].No,
                      "AuxiliaryIndex1": jsonInput[i].AuxiliaryIndex1,
                      "Datum": fullDate,
                      "EndDatum": endFullDate,
                      "Ort": jsonInput[i].Location_Name + ", " + jsonInput[i].Location_Address + ", " + jsonInput[i].Location_Post_Code + " " + jsonInput[i].Location_City,
                      "Auslastung": jsonInput[i].Participant_total + "/" + jsonInput[i].Max_Participant,
                      "Text": text,
                      "Dauer": jsonInput[i].CourseDuration!==undefined?duration:jsonInput[i].Duration,
                      "Kind": jsonInput[i].Kind,
                      "Preis": price,
                      "button": "Details test"} );
  }

  return resArray;
}

function HtmlDecode(s:any)
{
  var el:HTMLDivElement = document.createElement("div");
  el.innerHTML = s;
  s = el.innerText;
  return s;
}

function getRubrikFromAzure(jsonInput: any)
{
  return jsonInput;
}



function doFillVeranstaltungenJsx(veranstaltungen:any,eventsNoExpand:any)
{
  var innerContent:any[] = [];
  const map = new Map(); 
  if(veranstaltungen !== null && veranstaltungen.length > 0)
  {
    console.log("not sorted",veranstaltungen);
    var _veranstaltungen:any[]= veranstaltungen.sort((a:any, b:any) =>a.Datum.split('.').reverse().join().localeCompare(b.Datum.split('.').reverse().join()));
  //var _veranstaltungen:any[]=veranstaltungen.sort((a:any, b:any) => new Date(a.Datum.).getTime() - new Date(b.Datum).getTime());
    console.log("sorted",_veranstaltungen);
    // for(var i:number = 0; i < _veranstaltungen.length; i++)
    // {
    //   var _Key=_veranstaltungen[i].Key;
    //   if (!map.has(_veranstaltungen[i].Key))
    //   {
    //     let currNoExpandEvent=eventsNoExpand.filter(e=>e.Key_Word==_Key);

    //     innerContent.push(<VeranstaltungKachelComponent description = "" veranstaltung={_veranstaltungen[i]}/>);
    //     map.set(_veranstaltungen[i].Key, true); 
    //   }
    // }
    for (const _veranstalungItem of _veranstaltungen) {
      const filteredArray = eventsNoExpand.find((e:any)=>e.No===_veranstalungItem.Key);
      console.log("filteredArray",filteredArray);
      if (!map.has(_veranstalungItem.Key))
      {
        innerContent.push(<VeranstaltungKachelComponent description = "" veranstaltung={_veranstalungItem} eventsNoExpand={filteredArray}/>);
        map.set(_veranstalungItem.Key, true); 
      }
    }
  }
  return <div>{innerContent}</div>;
}


