import * as React from 'react';
// import newData from '../Model/news.json';
// import { Carousel } from 'react-bootstrap';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { PnPClientStorage } from "@pnp/common";
// import Button from 'react-bootstrap/Button';
// import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';
import AzureConnector from '../Module/AzureConnector';
const azurecon = new AzureConnector();
const storage = new PnPClientStorage();
const storageConfig: string = 'storageConfig';
let GebietResult: any[] = [];
let GebietTermResults: any[] = [];
let SzeneResult: any[] = [];
let SzeneTermResults: any[] = [];
let KreistResult: any[] = [];
let KreisTermResults: any[] = [];
let SzeneBox: any = null;
let GebietBox: any = null;
let KreisBox: any = null;
export interface ITeaserBanner{
    newsData:any[];
}
export default function TeaserBanner(props:ITeaserBanner) {
    const [gebietOpen, setGebietOpen] = React.useState(false);
    const [kreisOpen, setKreisOpen] = React.useState(false);
    const [szeneOpen, setSzeneOpen] = React.useState(false);
    const [kreisButtonTxt, setKreisButtontxt] = React.useState("expand-box");
    const [szeneButtonTxt, setSzeneButtontxt] = React.useState("expand-box");
    const [regionButtonTxt, setRegionButtontxt] = React.useState("expand-box");
    const [loadedData, setloadedData] = React.useState<any[]>([]);
    const [TermData, setTermData] = React.useState<any[]>([]);
    let history = useHistory();
    // setKreisButtontxt("expand-box");
    // setSzeneButtontxt("expand-box");
    // setRegionButtontxt("expand-box");
    React.useEffect(() => {
        let tmpNews:any[]=[];
        if(props.newsData.length!==0)
        {
            props.newsData.forEach((element)=>{
                tmpNews.push({element});
            })
            tmpNews=tmpNews.sort((a, b) => b.publishedDate - a.publisheddate);
            setloadedData(tmpNews);
        }
      
        // let configData = storage.session.get(storageConfig);
        // if (configData != null && configData.length > 0) {
        //     setTermData(configData);
        // }
        // else {
        //     azurecon.getTermConfigListPages().then((result: any) => {
        //         let tmpNews: any = null;
        //         tmpNews=result;
        //         setTermData(tmpNews);
        //     });
        // }
        fetch('/assets/json/teaserBanner.json').then((res) => res.json())
        .then((result) => {
            // setRubriken(result);
            setTermData(result);
        })
    },[])
    if (loadedData != null && loadedData.length > 0 && TermData != null && TermData.length > 0) {
        GetTeaserBannerData(loadedData);
        GenerateTermStorage(TermData);
        GenerateHtml();
        moment.locale("de");
    }

    return (
        <div className="col-lg-12 mb-12 teaserBanner" >
            <div className="row">
                <div className="col-lg-4 mb-4 teaser-content kreis-content" >
                    {KreisBox}
                </div>
                <div className="col-lg-4 mb-4 teaser-content szene-content" >
                    {SzeneBox}
                </div>
                {/* {SzeneBox} */}
                <div className="col-lg-4 mb-4 teaser-content gebiet-content" >
                    {GebietBox}
                </div>
            </div>
        </div>);
    function GenerateHtml() {
        const kreisList = KreisTermResults.map(function (element) {
            return (<div className="term-link-box"><FontAwesomeIcon className="term-link-icon" icon={faChevronRight} /><p className="termLink" onClick={() => {
                history.push('/posts/' + element.label);
            }}>{element.label}</p></div>);
        });
        //jetzt heißt das Cluster
        KreisBox = KreistResult.map(function (item) {
            return (<div className="card teaser-box">
                <button className={`collapse-button ${kreisButtonTxt}`} onClick={(ev: React.SyntheticEvent<HTMLElement>) => handlekreisButtonChange(ev)} type="button" data-toggle="collapse" data-target="#Kreis" aria-expanded="false" aria-controls="Kreis">
                    {/* <span>{kreisButtonTxt}</span> */}
                </button>
               <div className="banner-box">
                    <div className='banner-box-inner'>
                        <img className="banner-img" src={item.imgSrc} alt="Kreis" />
                        <div className="black-overlay"></div>
                        <div className="bannerTitle">
                        <h2 className="teaserTopTitle">Cluster</h2>
                        <div className="bannerTitleTxt">{item.title}</div>
                            {/* <p className="desc-box">{item.description}</p> */}
                            <span className="arrow-right-teaser" onClick={() => {
                                history.push(item.blogUrl)
                            }}>mehr dazu<span className="arrow-teaser"><img src="/assets/images/arrow-Icon-teaser.png" className="teaser-icon-img" alt="" /></span></span>
                        </div>
                    </div>
                </div>
                <div id="Kreis" className="collapse termlist-box">
                    <div className="termlist-box-inner">
                        {kreisList}
                    </div>
                </div>
            </div>
            )
        });
        SzeneBox = SzeneResult.map(function (item) {
            return (<div className="card teaser-box">
                <button className={`collapse-button ${szeneButtonTxt}`} onClick={(ev: React.SyntheticEvent<HTMLElement>) => handleSzeneChange(ev)} type="button" data-toggle="collapse" data-target="#Szene" aria-expanded="false" aria-controls="Szene">
                </button>
                <div id="Szene" className="collapse termlist-box">
                    <div className="termlist-box-inner">
                        <div className="interesse-box"><label><h3>Szene auswählen</h3></label>
                            <Select
                                classNamePrefix="Szene"
                                isClearable={true}
                                isSearchable={true}
                                onChange={_updateTaxonomyPicker}
                                options={SzeneTermResults}
                                placeholder="Auswählen..."
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgb(243, 242, 241)',
                                        primary: '#483D8B',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="banner-box">
                  
                    <img className="banner-img" src={item.imgSrc} alt="Szene" />
                    <div className="black-overlay"></div>
                    <div className="bannerTitle">
                    <h2 className="teaserTopTitle">Szenen</h2>
                    <div className="bannerTitleTxt">{item.title}</div>
                        {/* <p className="desc-box">{item.description}</p> */}
                        <span className="arrow-right-teaser" onClick={() => {
                            history.push(item.blogUrl)
                        }}>mehr dazu<span className="arrow-teaser"><img src="/assets/images/arrow-Icon-teaser.png" className="teaser-icon-img" alt="" /></span></span>
                    </div>
                </div>
            </div>
            )
        });
        GebietBox = GebietResult.map(function (item) {
            return (<div className="card teaser-box">
                <button className={`collapse-button ${regionButtonTxt}`} onClick={(e) => handleGebietChange(e)} type="button" data-toggle="collapse" data-target="#Gebiet" aria-expanded="false" aria-controls="Gebiet">
                    {/* <span>{regionButtonTxt}</span> */}
                </button>
                <div className="collapse termlist-box" id="Gebiet">
                    <div className="termlist-box-inner">
                        <div className="interesse-box"><label><h3>Region auswählen</h3></label>
                            <Select
                                classNamePrefix="Region"
                                isClearable={true}
                                isSearchable={true}
                                onChange={_updateTaxonomyPicker}
                                options={GebietTermResults}
                                placeholder="Auswählen..."
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgb(243, 242, 241)',
                                        primary: '#483D8B',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="banner-box">
                  
                    <img className="banner-img" src={item.imgSrc} alt="banner" />
                    <div className="black-overlay"></div>
                    <div className="bannerTitle">
                    <h2 className="teaserTopTitle">Regionen</h2>
                        <div className="bannerTitleTxt">{item.title}</div>
                        {/* <p className="desc-box">{item.description}</p> */}
                        <span className="arrow-right-teaser" onClick={() => {
                            history.push(item.blogUrl);
                        }}>mehr dazu<span className="arrow"><img src="/assets/images/arrow-Icon-teaser.png" className="teaser-icon-img" alt="" /></span></span>
                    </div>
                </div>
            </div>
            )
        });
       
     
    }
    function _updateTaxonomyPicker(value: any) {
        // console.log(removedValue);
        console.log(value);
        if (value !== null && value !== undefined) {
            history.push(value.url);
        }
    }
    // function _updateTaxonomyPickerRegion(value: any) {
    //     // console.log(removedValue);
    //     console.log(value);
    //     if (value !== null && value !== undefined) {
    //         setRegionButtontxt("expand-box");
    //         history.push(value.url);
            
    //     }
    // }
    function handleGebietChange(event: any) {
        let currStatus: boolean = gebietOpen;
        setGebietOpen(!currStatus);
        if (!currStatus) {
            setRegionButtontxt("close-box");
        } else {
            setRegionButtontxt("expand-box");
        }

    }
    function handleSzeneChange(event: any) {
        let currStatus: boolean = szeneOpen;
        setSzeneOpen(!currStatus);
        if (!currStatus) {
            setSzeneButtontxt("close-box");
        } else {
            setSzeneButtontxt("expand-box");
        }

    }
    function handlekreisButtonChange(event: any) {
        let currStatus: boolean = kreisOpen;
        setKreisOpen(!currStatus);
        if (!currStatus) {
            setKreisButtontxt("close-box");
        } else {
            setKreisButtontxt("expand-box");
        }

    }


    function trunCat(desc: string) {
        if (desc != null && desc.length > 0) {
            var max = 50;
            var suffix = "...";
            desc = desc.length < max ? desc : `${desc.substr(0, desc.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
        }
        return desc
    }
    function trunCatTitle(desc: string) {
        if (desc != null && desc.length > 0) {
            var max = 30;
            var suffix = "...";
            desc = desc.length < max ? desc : `${desc.substr(0, desc.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
        }
        return desc
    }
    //Method:  Generate Terms
    function GenerateTermStorage(_termDataArray: any[]) {
        GebietTermResults.length = 0;
        SzeneTermResults.length = 0;
        KreisTermResults.length = 0;
        const map = new Map();
        _termDataArray.forEach(item => {
            // eslint-disable-next-line no-loop-func
            item.term.forEach((e1: any) => {
                if (!map.has(e1.termId) && item.category.indexOf("Gebiet") !== -1) {
                    map.set(e1.termId, true);
                    // set any value to Map
                    let posUrl = '/posts/' + e1.termName;
                    GebietTermResults.push({
                        label: e1.termName,
                        value: e1.termId,
                        url: posUrl
                    });
                }
                if (!map.has(e1.termId) && item.category.indexOf("Szene") !== -1) {
                    map.set(e1.termId, true);
                    // set any value to Map
                    let posUrl = '/posts/' + e1.termName;
                    SzeneTermResults.push({
                        label: e1.termName,
                        value: e1.termId,
                        url: posUrl
                    });
                }
                if (!map.has(e1.termId) && item.category.indexOf("Kreis") !== -1) {
                    map.set(e1.termId, true);
                    // set any value to Map
                    let posUrl = '/posts/' + e1.termName;
                    KreisTermResults.push({
                        label: e1.termName,
                        value: e1.termId,
                        url: posUrl
                    });
                }
            });
        });

        if (GebietTermResults) {
           // GebietTermResults = GebietTermResults.sort((a, b) => a.label.localeCompare(b.label));
        }
        if (SzeneTermResults) {
           // SzeneTermResults = SzeneTermResults.sort((a, b) => a.label.localeCompare(b.label));
        }
        if (KreisTermResults) {
           // KreisTermResults = KreisTermResults.sort((a, b) => a.label.localeCompare(b.label));
        }
    }
    //  Method: Get Unique Terms by termid
    function GetTeaserBannerData(_teaserBannerList: any[]) {
        GebietResult.length = 0;
        SzeneResult.length = 0;
        KreistResult.length = 0;
        const map = new Map();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let i: any = Number;
        let gebietTmpresult: any[] = [];
        let kreisTmpresult: any[] = [];
        let szeneTmpresult: any[] = [];
        i = 1;
        for (const item of _teaserBannerList) {
            if (!map.has(item.element.id) && item.element.category !== null && item.element.category.indexOf("Gebiet") !== -1) {
                i++;
                map.set(item.element.id, true);    // set any value to Map
                let blogUrl = "/information/" + encodeURIComponent(item.element.urltitle);
                // let currImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
                let currImgSrc=`${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
                if (item.element.imgSrc != null) {
                    currImgSrc = `${process.env.PUBLIC_URL}/assets/News/${item.element.imgSrc}`;
                }
                gebietTmpresult.push({
                    id: item.element.id,
                    title: item.element.title,
                    category: item.element.category,
                    description: trunCat(item.element.description),
                    blogUrl: blogUrl,
                    imgSrc: currImgSrc,
                    date: new Date(item.element.publishedDate)
                });
            } else if (!map.has(item.element.id) && item.element.category !== null && item.element.category.indexOf("Szene") !== -1) {
                i++;
                map.set(item.element.id, true);    // set any value to Map
                let blogUrl = "/information/" + encodeURIComponent(item.element.urltitle);
                // let currImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
                let currImgSrc=`${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
                if (item.element.imgSrc != null) {
                    currImgSrc = `${process.env.PUBLIC_URL}/assets/News/${item.element.imgSrc}`;
                }
                szeneTmpresult.push({
                    id: item.element.id,
                    title: item.element.title,
                    category: item.element.category,
                    description: trunCat(item.element.description),
                    blogUrl: blogUrl,
                    imgSrc: currImgSrc,
                    date: new Date(item.element.publishedDate)
                });
            } else if (!map.has(item.element.id) && item.element.category !== null && item.element.category.indexOf("Kreis") !== -1) {
                i++;
                // console.log(map);
                // console.log(item.element.termId);
                map.set(item.element.id, true);    // set any value to Map
                let blogUrl = "/information/" + encodeURIComponent(item.element.urltitle);
                // let currImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
                let currImgSrc=`${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
                if (item.element.imgSrc != null) {
                    currImgSrc = `${process.env.PUBLIC_URL}/assets/News/${item.element.imgSrc}`;
                }
                kreisTmpresult.push({
                    id: item.element.id,
                    title: item.element.title,
                    category: item.element.category,
                    description: trunCat(item.element.description),
                    blogUrl: blogUrl,
                    imgSrc: currImgSrc,
                    date: new Date(item.element.publishedDate)
                });
            }
        }
        if (gebietTmpresult.length > 0) {
            if(gebietTmpresult.length > 1) 
            gebietTmpresult = gebietTmpresult.sort((a, b) => b.date - a.date);
            GebietResult.push(gebietTmpresult[0]);

        }
        if (kreisTmpresult.length > 0) {
            if (kreisTmpresult.length > 1)
            kreisTmpresult = kreisTmpresult.sort((a, b) => b.date - a.date);
            KreistResult.push(kreisTmpresult[0]);

        }
        if (szeneTmpresult.length > 0) {
            if (szeneTmpresult.length > 1)
            szeneTmpresult = szeneTmpresult.sort((a, b) => b.date - a.date);
            SzeneResult.push(szeneTmpresult[0]);

        }

    }
}


