import * as React from 'react';
import moment from 'moment';
//import newData from '../Model/news.json';
import AzureConnector from '../Module/AzureConnector_BC';
const azurecon = new AzureConnector();

const map = new Map();
export default function EventsCardbc() {
  const [eventsData, setEventsdData] = React.useState<any[]>([]);
  // const [htmlControl, setcontrolResult] = React.useState(null);
  
  React.useEffect(() => {
    azurecon.getEventsCard("eventsCard").then((result:any)=>{
        console.log("result",result);
        if(result!==null && result.length>0)
          {
            result.forEach((e1: any) => {
              if (!map.has(e1)) {
                map.set(e1, true);
                if(e1.Start_Date!==undefined &&e1.Start_Date!==null)
                { 
                  const { year: startYear, month: startMonth, day: startDay } = e1.Start_Date;
                  const [startHours, startMinutes, startSeconds] = e1.Start_Time.split(':').map(Number);
                  const startDate = new Date(startYear, startMonth - 1, startDay, startHours, startMinutes, startSeconds);
                  const { year: endYear, month: endMonth, day: endDay } = e1.End_Date;
                  const [endHours, endMinutes, endSeconds] =e1.End_Time.split(':').map(Number);
                  const endDate = new Date(endYear, endMonth - 1, endDay, endHours, endMinutes, endSeconds);
                  const auslastung= parseInt(e1.Max_Participant) - parseInt(e1.Participant_total);
                  const adresse=e1.VenueContact_Name +", " + e1.VenueContact_Address + ", " + e1.VenueContact_Code + " " + e1.VenueContact_City;
                  result.push({
                    title: e1.Description+" "+e1.Description_2,
                    startDate:startDate,
                    endDate:endDate,
                    duration:e1.Duration,
                    No:e1.No,
                    auslastung:auslastung,
                    ort:adresse,
                    type:e1.Event_Type_Code
                  });
                }
            }
            });
            setEventsdData(result);
          }
     });
  },[]);

  return (<div className="row content-container">
  {eventsData!==null&&eventsData.length>0&&eventsData.map((item, i) => (
      <div id={String(i++)} className="home-tile-Box col-lg-10 col-md-10 col-sm-10">
        <div className="home-tile-outer">
          <div className="home-tile-inner">
            <div className="home-image-box">
              {item.No}
            </div>
            <div className="home-tileTitle">
            <div className="iconTd">
              <img className="miniIcon" src="/assets/images/Kalender.png" alt="kal" />
              <div className="home-tileTitle">Von {item.startDate!==undefined&&item.startDate!==null?moment(new Date(item.startDate)).tz('Europe/Berlin').format('DD.MM.YYYY'):null} bis
              {item.endDate!==undefined&&item.endDate!==null?moment(new Date(item.endDate)).tz('Europe/Berlin').format('DD.MM.YYYY'):null}
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    ))
    }
  </div>);
}
