import * as React from 'react';
// import logo from '../images/logo.png';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
export default function SearchLogo() {
  const [searchTxt, setSearchQuery] = React.useState("");
  let history = useHistory();
  //let history = useHistory();
  return(
    <div className="col-md-12 searchArea">
    <div className="container-fluid container-search">
    <div className="row row-search">
    <div className="col-md-4">
    <div className="input-group place-holder">
      <input type="search" name="searchInput" className="form-control place-holder" placeholder="Suchen..." onKeyPress={event => event.key === 'Enter' && history.push('/search/' + searchTxt)} onChange={e => setSearchQuery(e.currentTarget.value)} />
      <div className="input-group-append">
      <button className="btn btn-secondary place-holder" disabled={!searchTxt} type="button" onClick={() => { history.push('/search/' + searchTxt) }}>
      <FontAwesomeIcon icon={faSearch} />
      </button>
      </div>
    </div>
    </div>
    <div className="col-md-4">
    </div>
    <div className="col-md-4">
    <div className="wvib-Logo"><img src={`${process.env.PUBLIC_URL}/assets/News/Logo_2021_wvib_Schwarzwald_AG_PFADE_A4_Office.png`} className="logo-img" alt="logo" onClick={() => {
             history.push("/")
              }}/></div>
    </div>
    </div>
    </div>
    </div>
)
}