import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, Dropdown } from 'office-ui-fabric-react';
import AzureConnector from '../Module/AzureConnector_Anon';
const azureCon = new AzureConnector();

interface IRubrikCheckBox {
  title: string;
  checked: boolean;
}

export interface INeuerBenutzerComponentProps {
  userNummer: string;
  userData?: iBenutzerdaten;
  isOnly?: boolean;
}

export type iBenutzerdaten = {
  userKey?: number,
  Salutation: string,
  Surname:string,
  First_Name:string,
  Address:string,
  Address_2:string,
  Post_Code:string,
  City:string,
  E_Mail:string,
  Phone_No:string,
  New_Company_Name:string,
  Event_No:string,
  No:string,
  Job_Title:string,
  Occupation:string,
  Leader?:boolean,
  Contact_Data_Entry_No:number
};

export default function Registrierung(props: INeuerBenutzerComponentProps) {
  const [userData, setUserData] = React.useState<iBenutzerdaten|undefined>();
  // const [userOrigin, setUserOrigin] = React.useState<string>("");
  // const [buttonOrigin, setButtonOrigin] = React.useState<string>("");
  const history = useHistory();
  // const location = useLocation();
  const [salutations, setSalutations] = React.useState<any[]|null>();
  const [tmpSalutations, setTmpSalutations] = React.useState<any[]|null>();
  const [rubriken, setRubriken] = React.useState<IRubrikCheckBox[]>();
  const [datenschutz, setDatenschutz] = React.useState(false);
  const [mailOk, setMailOk] = React.useState(false);
  // const [userInterests, setUserInterests] = React.useState([]);
  const urlEndPart = "/nav/salutations?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";

  let quarterTD:React.CSSProperties = {
    width: '25%'
  }

  let userTD:React.CSSProperties = {
    padding: '1%'
  }

  let userTable:React.CSSProperties = {
    width: '100%',
    backgroundColor: '#F1F1F1'
  }

  let interessenList:React.CSSProperties = {
    width: '100%',
    backgroundColor: '#F1F1F1',
    padding: '1%'
  }
  React.useEffect(() => {
    if(salutations == null)
    {
      azureCon.get(urlEndPart)
        .then((result: any) => {
          if(result.length > 0)
          {
            var salutationObject = [];
            var tmpSalutationObject = [];
            for (var i = 0; i < result.length; i++)
            {
              salutationObject.push({ key: result[i].Code, text: result[i].Sex });
              if(result[i].Sex ==="Male")
                  {
                    tmpSalutationObject.push({key: result[i].Code, text: "Herr"});
                  }else
                  {
                    tmpSalutationObject.push({key: result[i].Code, text: "Frau"});
                  }
            }
            setTmpSalutations(tmpSalutationObject);
            setSalutations(salutationObject);
          }
        })
        .catch((error: any) => {
            console.error(error);
        });
      }
      },[salutations]);

  React.useEffect(() => {
    setUserData(InitialUserData());
    const urlEndPartRubriken = "/sp/terms?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
    azureCon.get(urlEndPartRubriken)
      .then((result: any) => {
          const rubrikenArr = [];
            for (let i in result) {
              rubrikenArr.push({ title: result[i], checked: false });
            }
            setRubriken(rubrikenArr);
      })
      .catch((error: any) => {
          console.error(error);
      });
  },[]);

  // React.useEffect(() => {
  //   const search = new URLSearchParams(location.search);
  //     if (search.has('originUser')) {
  //       setUserOrigin(search.get('originUser'));
  //     }
  //     if (search.has('Region')) {
  //       setButtonOrigin(search.get('Region'));
  //     }
  // }, [location]);

  if(!userData || salutations == null)
  {
    return <div>
      
      </div>;
  }

  const interessenJsx = loadInteressen();
  const datenschutzJsx = loadDatenschutz();
if(!userData)
{
  return <div></div>
}
  return (
    <div>
      {/* <header className={"App-header"}> */}
        {/* <Header/> */}
        <div className={"container-fluid anmeldeContainer"}>
          <div className={"container-row"}>
            <div className={"CommonDivContainer"} style={{fontFamily:'Roboto', marginTop: '5%'}} key={"user" + props.userNummer}>
	            <h2 className="registerText">Mit dem wvib in Kontakt treten und Zugang zu exklusiven Inhalten erhalten</h2>
              <div className="registerText">Die wvib Schwarzwald AG bietet registrierten Benutzern erweiterte Dienstleistungen auf ihrer Website an. Dazu füllen Sie bitte dieses Formular aus. Nach Prüfung Ihrer Daten* erhalten Sie eine Email (an die hier eingetragene Adresse) in der Sie mit Klick auf einen Bestätigungslink Ihre Registrierung abschließen.</div>
              <div className="registerText">*Die wvib Schwarzwald AG behält sich vor, Registrierungen abzulehnen.</div>
              <h3 className="registerText">Kontaktdaten</h3>
             <div className="table-box">
              <table style={userTable}>
                <tbody>
                  <tr>
                    <td style={quarterTD} colSpan={1}></td>
                    <td style={quarterTD} colSpan={1}></td>
                    <td style={quarterTD} colSpan={1}></td>
                    <td style={quarterTD} colSpan={1}></td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2} >
                      <TextField id={"userComp" + props.userNummer} value={userData.New_Company_Name} label="Unternehmen *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.New_Company_Name = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.New_Company_Name = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2}>
                      <div className="userHalfTD-div">
                      <Dropdown id={"userSalutation" + props.userNummer} label="Anrede *" options={tmpSalutations}
                        onChange={(e, newValue) => {
                           const tmpStamm = {...userData};
                           let currentSalulation="Male";
                          if(typeof tmpStamm!==undefined &&newValue!==undefined)
                          {
                            if(newValue.text==="Frau")
                            currentSalulation="Female";
                            tmpStamm.Salutation =currentSalulation;
                            setUserData(tmpStamm);
                          }
                          if(props!==undefined && props.userData!==undefined && props!==null &&newValue!==undefined)
                          {
                            if(newValue.text==="Frau")
                            currentSalulation="Female";
                            props.userData.Salutation = currentSalulation;
                          }
                        }}
                      />
                      <TextField id={"userTitle" + props.userNummer} value={userData.Job_Title} label="akad. Titel" 
                        onChange={(e, newValue) => {
                           const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                          tmpStamm.Job_Title = newValue||'';
                          setUserData(tmpStamm);
                          if(props!==undefined && props.userData!==undefined && props!==null)
                          props.userData.Job_Title = newValue||'';
                        }}
                      />
                      </div>
                    </td>
                    {/* <td className={"userHalfTD"}>
                      
                    </td> */}
                  </tr>
                  <tr>
                    <td className={"userTD"}  colSpan={2}>
                      <TextField id={"userName" + props.userNummer} value={userData.Surname} label="Nachname *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.Surname = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.Surname = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"}  colSpan={2}>
                      <TextField id={"userVorname" + props.userNummer} value={userData.First_Name} label="Vorname *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.First_Name = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.First_Name = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"}  colSpan={2}>
                      <TextField id={"userEmail" + props.userNummer} value={userData.E_Mail} label="Email *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.E_Mail = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.E_Mail = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2}>
                      <TextField id={"userTel" + props.userNummer} value={userData.Phone_No} label="Telefon *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.Phone_No = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.Phone_No = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2}>
                      <TextField id={"userTätigkeit" + props.userNummer} value={userData.Occupation} label="Tätigkeit als *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.Occupation = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.Occupation = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2}>
                      <div className="datenschutz-box">
                      <input type="checkbox" className="baseCB" id={"Leader" + props.userNummer} checked={userData.Leader}
                      onChange={() => {
                         const tmpStamm = {...userData};  
                        if(typeof tmpStamm!==undefined)
                        tmpStamm.Leader = !userData.Leader||false;
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.Leader = !userData.Leader||false;
                      }}
					            />
                       Leitungsfunktion
                       </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2}>
                      <TextField id={"userStr" + props.userNummer} value={userData.Address} label="Straße und Hausnummer *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.Address = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.Address = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2}>
                    <div className="plzHalfTD-div">
                      <TextField id={"userPlz" + props.userNummer} value={userData.Post_Code} label="PLZ *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.Post_Code = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.Post_Code = newValue||'';
                      }}
                      />
                    <TextField id={"userOrt" + props.userNummer} value={userData.City} label="Ort *"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.City = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.City = newValue||'';
                      }}
                      />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={"userTD"} colSpan={2}>
                      <TextField id={"userAdressZusatz" + props.userNummer} value={userData.Address_2} label="Adresszusatz"
                      onChange={(e, newValue) => {
                         const tmpStamm = {...userData};
                        if(typeof tmpStamm!==undefined &&newValue!==undefined)
                        tmpStamm.Address_2 = newValue||'';
                        setUserData(tmpStamm);
                        if(props!==undefined && props.userData!==undefined && props!==null)
                        props.userData.Address_2 = newValue||'';
                      }}
                      />
                    </td>
                  </tr>
                  <tr>
                   <td className={"userTD"} ><TextField className="tdplatzhalter" label="platzhalter"/></td>
                  </tr>
                </tbody>
                </table>
              </div>
                {/* Interessen */}
                <h2 className="registerText">Meine Interessen</h2>
                <div className={"interessenList"}>
                  <div className="interessenList-inner">
                    {interessenJsx}
                  </div>
                </div>
                {/* Datenschutz */}
                <h2 className="registerText">Datenschutz und Einwilligungserklärung</h2>
                <div className="registerText">Bitte bestätigen Sie, dass Sie die Datenschutzerklärung gelesen haben und akzeptieren. Bestätigen Sie bitte auch, dass wir mit Ihnen für den Abschluss der Registrierung per Email in Kontakt treten dürfen.</div>
                <div className={"interessenList"}>
                    {datenschutzJsx}
                </div>
                <div className="register-box">
                  <DefaultButton className="WvibButtonPrim_Reg" id="closeButton" text="Registrieren" onClick={() => register()} /> 
                </div>
            </div>
          </div>
        </div>
      {/* </header>
      <Footer/> */}
    </div>
  );

  function InitialUserData()
  {
    var emptyDummyJson = '{"Salutation": "", "Surname": "", "First_Name": "", "AdressHno": "", "Post_Code": "", "City": "", "E_Mail": "", "tel": "", "Company_Name": ""}';
    var jsonData = JSON.parse(emptyDummyJson);

    return jsonData;
  }

  function register()
  {
    if(!datenschutz || !mailOk)
    {
      var error = "Sie müssen sowohl die Datenschutzbestimmung akzeptiert, als auch zugestimmt haben, dass wir Sie kontaktieren dürfen, um sich registrieren zu können."
      alert(error);
      return;
    }

    var user = props.userData;
    var thereWasAnError = false;
    var userErrors = [];

    var checkUser = user;
    var thereWasAnUserError = false;
    var userErrorArray = [];

    var userIntressen = [];

    if(rubriken != null)
    {
      for(var i = 0; i < rubriken.length; i++)
      {
        if(rubriken[i].checked)
        {
          userIntressen.push(rubriken[i].title);
        }
      }
    }

    if(checkUser!==undefined && checkUser.Salutation === '')

    {
      thereWasAnUserError = true;
      userErrorArray.push("Anrede");
    }
    if(checkUser!==undefined &&checkUser.First_Name == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Vorname");
    }
    if(checkUser!==undefined &&checkUser.Surname == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Name");
    }
    if(checkUser!==undefined &&checkUser.Post_Code == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Postleitzahl");
    }
    if(checkUser!==undefined &&checkUser.City == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Ort");
    }
    if(checkUser!==undefined &&checkUser.Address == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Straße");
    }
    if(checkUser!==undefined &&checkUser.E_Mail == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Email");
    }
    if(checkUser!==undefined &&checkUser.Phone_No == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Telefon");
    }
    if(checkUser!==undefined &&checkUser.New_Company_Name == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Unternehmen");
    }
    if(checkUser!==undefined &&checkUser.Occupation == '')
    {
      thereWasAnUserError = true;
      userErrorArray.push("Tätigkeit als");
    }

    if(thereWasAnUserError)
    {
      thereWasAnError = true;
      userErrors.push([userErrorArray]);
    }


    var userText = [];
    if(thereWasAnError)
    {
      var text = "";
      var userContent = userErrors[0];
      for(var j = 0; j < userContent.length; j++)
      {
        text += userContent[j];
        if(j < userContent.length-1)
        {
          text += ", ";
        }
        else if(j === userContent.length-1 && j < userErrors.length-1)
        {
          text += "; \n";
        }
        else
        {
          text += ". \n";
        }
      }
      userText.push(text);
    }

    if(thereWasAnError)
    {
      var finalErrorMessage = "Es ist ein Fehler aufgetreten. Bitte kontrollieren Sie folgende Felder: \n";
      for(var i = 0; i < userText.length; i++)
      {
        finalErrorMessage += userText[i];
      }
      finalErrorMessage += "\nBitte ergänzen Sie die Angaben um fortzufahren.";
      alert(finalErrorMessage);
      return;
    }

    ;
    user.Contact_Data_Entry_No = Math.floor(Math.random() * Date.now()/1000);
    var subscribe = {"Event_No": "", "Portalbenutzer": true};
    const urlEndPart = "/nav/memberships?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
    var registerPack = {...user, ...subscribe};
    var interestPack:string ="";
    if(userIntressen.length>0){
      userIntressen.forEach((e1: any) => {
        if (interestPack.length > 0) {
          interestPack = interestPack + `,{"Key_Word":"${e1}","Contact_Data_Entry_No":${user.Contact_Data_Entry_No}}`;
        } else {
          interestPack = `{"Key_Word":"${e1}","Contact_Data_Entry_No":${user.Contact_Data_Entry_No}}`;
        }
      });
    }
    var _currInteresse=`[${interestPack}]`;
    console.log(_currInteresse);
    delete registerPack.userKey;
    azureCon.post(urlEndPart, JSON.stringify(registerPack))
      .then((result: any) => {
        console.log("result: " + result);
        const interestsUrlEndPart = "/nav/interests?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
         azureCon.post(interestsUrlEndPart, _currInteresse)
        .then((result: any) => {
          console.log("result: " + result);
          alert ("Benutzer erfolgreich angelegt.")
        })
        .catch((error: any) => {
          console.error(error);
        });
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
    

  function loadInteressen()
  {
    var interessen = [];

    if(rubriken != null)
    {
        for(var i = 0; i < rubriken.length; i++)
        {
			  const index = i;
            interessen.push(
                <div className="div-checkbox">
                    <input type="checkbox" id={"CB" + rubriken[index].title} className="baseCB" checked={rubriken[index].checked}
                    onChange={() => {
                      const tmpRubriken = [...rubriken];
                      tmpRubriken[index].checked = !rubriken[index].checked;
                      setRubriken(tmpRubriken);
                    }}/>{rubriken[index].title}            
                </div>
            );
        }
        return interessen;
    }
    return <></>;
  }

  function loadDatenschutz()
  {
    var datenschutzArray = [];

    var link = <div className="datenSchutzLink"><span className="datenSchutzLinkSpan">
      <DefaultButton className="WvibButtonPrim_Reg" id="datenschutzButton" text="Datenschutzerklärung" onClick={(e) => {e.preventDefault(); window.open("/Pages/Datenschutz.aspx","_blank");}}/>
    </span></div>;

    datenschutzArray.push(
        <div className="datenschutz-box">
            <input type="checkbox" id={"CBDatenschutz"} className="baseCB" checked={datenschutz} onChange={
              () => {
                setDatenschutz(!datenschutz);
              }}/>{link}               
        </div>
    );
    datenschutzArray.push(
        <div className="datenschutz-box">
            <input type="checkbox" id={"CBMailOK"} checked={mailOk} className="baseCB" onChange={ 
              () => {
                setMailOk(!mailOk);
              }}/>Der wvib darf mich per Telefon oder Email kontaktieren
        </div>
    );
    // datenschutzArray.push(
    //   <div>
    //     {/* <DefaultButton className="WvibButton_Reg" id="cancelButton" text="Abbrechen" onClick={() => history.goBack()} /> */}
    //     {/* <DefaultButton className="WvibButtonPrim_Reg" id="closeButton" text="Registrieren" onClick={() => register()} /> */}
    //   </div>
    // )

    return datenschutzArray;
  }
  
}
