import React from 'react';

const GoogleAnalytics = () =>{

    React.useEffect(()=>{
      const contentscript=document.createElement("script");
      const anyalScript = document.createElement("script");
      //Test demo ID
      // anyalScript.src="https://www.googletagmanager.com/gtag/js?id=G-MF4ML84RG1";
      //  contentscript.innerHTML="window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-MF4ML84RG1')";
      // end demo
      // anyalScript.src="https://www.googletagmanager.com/gtag/js?id=G-4TFTXC50H4";
      // contentscript.innerHTML="window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-4TFTXC50H4')";
      // anyalScript.async=true;
      // document.head.appendChild(anyalScript);
      // document.head.appendChild(contentscript);

    },[])
     return (
      <div className="googlePlaceholder">
      </div>
    );
  }
  
  export default GoogleAnalytics;