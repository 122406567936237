import React from 'react';
import ReactLoading from 'react-loading';
import '../css/custom-style.css';
// import newsData from '../Model/news.json';
// import contactsData from '../Model/contacts.json';
// import themenData from '../Model/themen.json';
//import evenstData from '../Model/veranstaltung.json';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/de';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
// import { getJSDocAugmentsTag, isTemplateMiddle } from 'typescript';
import { FileIcon } from 'react-file-icon';
import parse, { domToReact } from 'html-react-parser';
import Header from './Header';
import Footer from './Footer';
import SearchLogo from './SearchLogo';
import { PnPClientStorage } from "@pnp/common";
import AzureConnector from '../Module/AzureConnector';
import GoogleAnalytics from './GoogleAnalytics';
import { Document, Page,pdfjs} from "react-pdf";
import {
  LinkedinShareButton,
} from "react-share";
import { LinkedinIcon } from "react-share";
import { Helmet } from 'react-helmet';
import {FaPrint} from 'react-icons/fa';
// import {Helmet, HelmetProvider } from 'react-helmet-async';
// import Flinque from './Flinque';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const azurecon = new AzureConnector();
const storage = new PnPClientStorage();
let storageContacts: string = 'storageContacts';
let storageThemen: string = 'storageThemenstorageThemen';
let storageDocuments: string = 'storageDocuments';
let storageCommunties: string = 'storageCommunties';
const docsMap = new Map();
let result: any[] = [];
let headerHtml: any = null;
let currSocialmMediaHtml: any =null;
//let contactResult: any[] = [];
let themenResult: any[] = [];
let testMontial: any[] = [];
let veranstaltung: any[] = [];
let documentsHTML: any[] = [];
let eventResults: any[] = [];
let eventsHtml: any = null;
let currPageTitle: any = null;
let imgSrcWithCaption: any = null;
let weiterbildungHtml: any = null;
let captionText: any = null;
let internLinkMap = new Map();
let educationArray: any[] = ["E-TRAINING", "Lehrgang", "Lehrgang Klammer mit Modulen",
  "Lehrgangsmodul", "Online-Seminar", "Seminar"];
let newsId: string;
let termName: any = null;
let loading: boolean = false;
let pageImage: any = null;
// let socialMediaMap=new Map()

export interface IInformationProps {
  onChangeHeader:any;
}
export default function Information(props:IInformationProps) {
  require("prerender-node").set('prerenderToken','nGm6OT9ctUQtJ1dL9Yy9');  
  // let jsonNewsData = require("../assets/json/news.json");
  let history = useHistory();
  const [numPages, setNumPages] =  React.useState(null);
  const [newsData, setNews] = React.useState(null);
  const [changeHeaderData, setChangeHeaderData] = React.useState(false);
  const [currNewsID, setCurrNewsID] = React.useState(null);
  const [documentsData, setstorageDocuments] = React.useState<any[]>([]);
  const [storageTerms, setStorageTerms] = React.useState("");
  const [storageEvents, setStorageEvents] = React.useState<any[]>([]);
  const [contatcsData, setcontatcsData] = React.useState<any[]>([]);
  const [themenData, setthemenData] = React.useState<any[]>([]);
  const [currTermList, setcurrTermList] = React.useState<any[]>([]);
  const [communtiesData, setCommuntiesData] = React.useState<any[]>([]);
  const [currShareUrl, setcurrShareUrl] = React.useState(null);
  let _currShareUrl = window.location.href;

  let webPartMap = new Map();
  let quickLinkTitleMap = new Map();
  let newsContent: any = null;
  let testMontialHtml: any = [];
  let contactHtml: any = [];
  let themenMapHtml: any = [];
  let docshtml: any = [];
  let communitiesHtml: any = null;
  let breadcrumbHtml: any = null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { id }: any = useParams();
  const { term }: any = useParams();
  if (typeof { id } !== "undefined" ) {
    //////console.log({ id });
    const _id={ id }.id;
    console.log("_id",_id);
    console.log("decodeURIComponent(_id):",decodeURIComponent(_id));
    newsId = decodeURIComponent(_id);
    newsId=removeSpecialCharacters(newsId);
    console.log("tmpcurrPageName",newsId);
  }
  if (typeof { term } !== "undefined") {
    //////console.log({ term });
    termName = { term }.term;
  }
  //#region get all Information, set usestate, call main functions

  if (currNewsID !== null && newsId !== currNewsID) {
    window.location.reload();
  }
  function onDocumentLoadSuccess(numPages:any) {
    setNumPages(numPages);
  }

  React.useEffect(() => {
    if(currShareUrl===null){
      // let modifiedUrl = currShareUrl.replace(/\/information\/?$/, '/information');
      let modifiedUrl = removeLastSegment(_currShareUrl);
      console.log("modifiedUrl: ",modifiedUrl);
      setcurrShareUrl(modifiedUrl);
    }
    if(currNewsID===null){
    
      setCurrNewsID(newsId);
    }
    
  }, [currNewsID,_currShareUrl]);
  React.useEffect(() => {
    if(newsData==null){
      fetch('/assets/json/news.json').then((res) => res.json())
      .then((jsonNewsData) => {
      if (jsonNewsData !== undefined && jsonNewsData !== null ) {
      jsonNewsData.forEach((element: any) => {
        // if (element != null && element.id === newsId) {
          
          if(element != null && element.urltitle===newsId){
          let tmpTermList: any[] = [];
          let currTermString: any = "";
          captionText = null;

          if (element.term.length !== 0) {
            element.term.forEach((e1: any) => {
              if (e1.termName !== "Top-Banner" && e1.termName !== "Top-Veranstaltung") {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                currTermString = currTermString + e1.termName + "|";
                tmpTermList.push({
                  termName: e1.termName,
                  termId: e1.termId
                });
              }
            });
            setStorageTerms(currTermString);
            setcurrTermList(tmpTermList);
            setNews(element);
          }
          return;
        }
      });
    }
  })

    let storDocumens = storage.session.get(storageDocuments);
   
      if (storDocumens != null && storDocumens.length > 0) {
        setstorageDocuments(storDocumens);

      }
      else {
        azurecon.getDocs().then((result: any) => {
          let tmpStatics: any[] = [];
          result.forEach((element: any) => {
            tmpStatics.push(element);
          });
          setstorageDocuments(tmpStatics);
        });
      }
    
    let storContacts = storage.session.get(storageContacts);
    if (storContacts != null && storContacts.length > 0) {
      setcontatcsData(storContacts);
    } else {
      azurecon.getContacts().then((result: any) => {
        let tmpStatics: any[] = [];
        result.forEach((element: any) => {
          tmpStatics.push(element);
        });
        setcontatcsData(tmpStatics);
      });
    }
    let storCommunities = storage.session.get(storageCommunties);
    if (storCommunities != null && storCommunities.length > 0) {
      setCommuntiesData(storCommunities);
    } else {
      azurecon.getCommunitiesList().then((result: any) => {
        let storCommunities: any[] = [];
        result.forEach((element: any) => {
          storCommunities.push(element);
        });
        //////console.log(storCommunities);
        setCommuntiesData(storCommunities);
      });
    }
    let storThemen = storage.session.get(storageThemen);
    if (storThemen != null && storThemen.length > 0) {
      setthemenData(storThemen);
    } else {
      azurecon.getThemenImages().then((result: any) => {
        let tmpStatics: any[] = [];
        result.forEach((element: any) => {
          tmpStatics.push(element);
        });
        setthemenData(tmpStatics);
      });
    }
    }
  }, [newsData]);

  React.useEffect(() => {
    if (storageTerms !== null && storageTerms !== "" && storageEvents.length === 0) {
      azurecon.getEvents("keywords/" + storageTerms + "/events").then((result: any) => {
        let tmpStatics: any[] = [];
        result.forEach((element: any) => {
          tmpStatics.push(element);
        });
        setStorageEvents(tmpStatics);
      });
    }
    window.scrollTo(0, 0)
  }, [storageEvents.length, storageTerms]);
function removeLastSegment(input:string) {
  const parts = input.split('/information/');
  if (parts.length > 1) {
    const lastPart = parts[1].replace(/\/.+$/, "");
    return parts[0] + '/information/' + lastPart;
  }
  return input;
}
function removeSpecialCharacters(title: string){
  const pattern: RegExp = /[^0-9a-zA-Z.]/g;
  if (pattern.test(title)) {
    var currNewsID = title.replace(pattern, '');
    if(termName!==undefined &&termName!=null)
    {
      history.push("/information/" + currNewsID + "/" + termName);
    }else{
      history.push("/information/" + currNewsID);  
    }
    
    window.location.reload();
  }
  return title.replace(pattern, '');
}
  /* Generate Content*/
  // //console.log("newsData in Abfrage vor each",newsData);
  if (newsData != null && newsContent == null) {
    GetNewsSetContent(newsData);
    loading = true;
  }
   else {
    newsContent = (<div className="placeholder"></div>);
  }

  /* Generate Themen List and Get Themen-Tiles for the Current Term*/
  if (themenData != null && themenData.length > 0 && currTermList !== null && currTermList.length > 0) {
    loading = true;
    GetThemenList(themenData);
  }
  else {
    testMontialHtml = (<div className="placeholder"></div>);
    themenMapHtml = (<div className="placeholder"></div>);
  }
  /* Get Contacts for current Term*/
  if (contatcsData != null && contatcsData.length > 0 && currTermList !== null && currTermList.length > 0) {
    loading = true;
    GetContact(contatcsData);
  }
  else {
    contactHtml = (<div className="placeholder"></div>);
  }

  /* Set Documents for current Term*/
  if (documentsData != null && documentsData.length > 0 && currTermList !== null && currTermList.length > 0) {
    loading = true;
    SetDocuments();
  }
  else {
    docshtml = (<div className="placeholder"></div>);
  }
  if (communtiesData != null && communtiesData.length > 0 && currTermList !== null && currTermList.length > 0) {
    if (termName !== null) {
      GetCommunitesWithTerm(communtiesData)
    } else {
      GetCommunites(communtiesData);
    }

  } else {
    communitiesHtml = (<div className="placeholder"></div>);
  }
  /* Get Events for current Term*/
  if (storageEvents !== null && storageEvents.length > 0) {
    loading = true;
    GetEvent(storageEvents);
  }
  else {
    eventsHtml = (<div className="placeholder"></div>);
    weiterbildungHtml = (<div className="placeholder"></div>);
  }
  //#endregion
  if(currPageTitle!=null&& currShareUrl!==null)
  {
    if(!(changeHeaderData))
    {    
      setChangeHeaderData(true);
      var curremdatData:any={title:currPageTitle,description:captionText!==null?captionText:"",url:currShareUrl,image:pageImage};
      props.onChangeHeader(curremdatData);
    }
  }
  //#region * Content-Render  */
  return (
    <div className="App" >
      <Helmet>
        <title>{currPageTitle}</title>
      </Helmet>
      <header className="App-header">
        <Header />
        {/* Page Content */}
        <div className="container-fluid">
          <div className="search-row">
            <div className="container-row">
              <SearchLogo />
            </div>
          </div>
          <div className="row container-row" >
            {/* Post Content Column */}
            {breadcrumbHtml}
            <div className="col-sm-12 col-lg-8" hidden={!loading}>
              {newsContent}
            </div>
            {/* Sidebar Widgets Column */}
            <div className="col-sm-12 col-lg-4 right-sidebar" hidden={!loading}>
              <div>
                {testMontialHtml}
                {communitiesHtml}
              </div>
              {/* {community} */}
              <div className="card mb-4">
                {contactHtml}
              </div>
              {eventsHtml}
              {weiterbildungHtml}
              <div className="card mb-4">
                {docshtml}
              </div>
              {/* <div>
                {themenMapHtml}
              </div> */}
            </div>
          </div>
          <div className="row  loading-box" hidden={loading}>
            <ReactLoading color={"#293940"} width={175} type={"spin"} />
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </header>
      <GoogleAnalytics />
      {/* <Flinque/> */}
      <Footer />
    </div>
    
  );
  //#endregion


  //#region  get specific infos ans set html Array
  // eslint-disable-next-line react-hooks/exhaustive-deps

  function GetNewsSetContent(item: any) {
    result.length = 0;
    let htmlControls: any[] = [];
    let currCanvas = item.canvas;
    //console.log("currCanvas: ",currCanvas);
    let webPartGuidID: any = null;
    // imgTile=null;
    // let beitragImg:any=null;
    let zoneIndexList: any[] = [];
    let zoneMap = new Map();
    let translateX = 0;
    let translateY = 0;
    let headerImgExist = false;
    let headerImgSrc = "";
    if (newsData.canvas != null && newsData.canvas !== "") {
      let zoneIndex: any = null;
      let sectionIndex: any = null;
      let controlIndex: any = null;
      let gesamtIndex = 0;
      let sectionFactor = "col-lg-12";
      let oriSectionFactor: any = null;
      let currIndex = 1;

      translateX = newsData.layoutWebpartsContent.translateX;
      translateY = newsData.layoutWebpartsContent.translateY;
      headerImgExist = newsData.layoutWebpartsContent.layoutType;
      internLinkMap.clear();
      if (newsData.imgSrc != null && headerImgExist) {
        headerImgSrc = `${process.env.PUBLIC_URL}/assets/News/${newsData.imgSrc}`;
      }
      currCanvas = parse(newsData.canvas, {

        // eslint-disable-next-line no-loop-func
        replace: ({ attribs, children }) => {

          if (attribs && typeof attribs["data-sp-controldata"] != "undefined") {
            gesamtIndex = 20;
            var controldatJson = JSON.parse(attribs["data-sp-controldata"]);
            if ((typeof controldatJson !== "undefined") && controldatJson !== null) {
              if ((typeof controldatJson.id !== "undefined") && controldatJson.id !== null)
                webPartGuidID = controldatJson.id;
              let currPosition = controldatJson.position;
              if ((typeof currPosition !== "undefined") && currPosition !== null) {
                if ((typeof currPosition.zoneIndex !== "undefined") && currPosition.zoneIndex !== null) {
                  zoneIndex = currPosition.zoneIndex;
                  if (!zoneMap.has(zoneIndex)) {
                    zoneMap.set(zoneIndex, true);
                    zoneIndexList.push(zoneIndex);
                  }
                }
                if ((typeof currPosition.controlIndex !== "undefined") && currPosition.controlIndex !== null) {
                  controlIndex = currPosition.controlIndex;
                  gesamtIndex = controlIndex * 10;
                  gesamtIndex = Math.trunc(gesamtIndex);
                }
                if ((typeof currPosition.sectionIndex !== "undefined") && currPosition.sectionIndex !== null) {
                  sectionIndex = currPosition.sectionIndex;
                }
                if ((typeof currPosition.sectionFactor !== "undefined") && currPosition.sectionFactor !== null) {
                  oriSectionFactor = currPosition.sectionFactor;
                }


                if ((typeof currPosition.sectionFactor !== "undefined") && currPosition.sectionFactor !== null) {
                  switch (currPosition.sectionFactor) {
                    case 4:
                      sectionFactor = "col-sm-12 col-lg-4";
                      break;
                    case 8:
                      sectionFactor = "col-sm-12 col-lg-8";
                      break;
                    case 6:
                      sectionFactor = "col-sm-12 col-lg-6";
                      break;
                    case 12:
                      sectionFactor = "col-sm-12 center-box";
                      break;
                    default:
                      sectionFactor = "col-sm-12 col-lg-12";
                      break;
                  }
                }
              }
            }
          }
          if (attribs && (typeof attribs["data-sp-prop-name"] !== "undefined") && attribs["data-sp-searchableplaintext"] !== null) {
            var json1 = attribs["data-sp-prop-name"];
            var src = attribs["src"];
            if ((typeof json1 !== "undefined") && json1 !== null) {
              ////console.log("hie rin coi",json1);
              if (json1 === "captionText" && typeof children !== "undefined" && children.length > 0) {
                ////console.log(" children",children);
                // TODO: get Childrn Div
                captionText = domToReact(children);
                //console.log("Title: ",captionText);
              }
              else if (json1 === "imageSource" && src !== null) {
                imgSrcWithCaption = src;
                imgSrcWithCaption = _getImgUrl(src);
                if (imgSrcWithCaption !== null && imgSrcWithCaption !== "") {
                  //////console.log(currImgSrc);
                  imgSrcWithCaption = `${process.env.PUBLIC_URL}/assets/News/${imgSrcWithCaption}`;

                }
              }

            }
          }
          if (attribs && (typeof attribs["data-sp-rte"] !== "undefined") && attribs["data-sp-rte"] !== null && (typeof (attribs["href"] === "undefined"))) {

            if (typeof children !== "undefined" && children.length > 0) {
              if (children.length > 0) {
                children = ChildrenNames(children);
              }
              // //////console.log(domToReact(children));
              if (!webPartMap.has(webPartGuidID)) {
                webPartMap.set(webPartGuidID, true);
                //////console.log("NOt exist");
                htmlControls.push({
                  id: webPartGuidID,
                  gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                  sectionIndex: sectionIndex,
                  controlIndex: controlIndex,
                  sectionFactor: sectionFactor,
                  htmlInnerControls: <div style={{ order: gesamtIndex }} className={`section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{domToReact(children)}</div>
                });
              }

            }
          }

          if (attribs && (typeof attribs["data-sp-webpartdata"] !== "undefined")) {
            if (typeof attribs["data-sp-webpartdata"] !== "undefined") {
              var json = JSON.parse(attribs["data-sp-webpartdata"]);
              if ((typeof json !== "undefined") && json !== null) {
                let contactFields = json.properties.fields;
                let newsFilters = json.properties.terms;
                let emdedCode = json.properties.embedCode;
                let propertiesiTems = json.properties.items;
                let fielviewer=json.properties.filePicker;

                if ((typeof fielviewer !== "undefined") && fielviewer.length !== 0) {
                  let fileUrl = fielviewer.fileAbsoluteUrl;
                  let fileViewer=_getFileViewer(fileUrl);
                  if(fileUrl!==undefined &&fileUrl !==null&&fileUrl!=="")
                  {
                    if (!webPartMap.has(webPartGuidID)) {
                      webPartMap.set(webPartGuidID, true);
                      htmlControls.push({
                        id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                        sectionIndex: sectionIndex,
                        controlIndex: controlIndex,
                        sectionFactor: sectionFactor,
                        htmlInnerControls: <div style={{ order: gesamtIndex }} className={`section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{fileViewer}</div>
                      });
                    }
                  }
                }
                // eslint-disable-next-line valid-typeof
                if ((typeof contactFields !== "undefined") && contactFields.length !== 0 && contatcsData !== null && contatcsData.length > 0) {
                  let contactsWbpart = _getContactHtml(attribs["data-sp-webpartdata"]);
                  if (!webPartMap.has(webPartGuidID)) {
                    webPartMap.set(webPartGuidID, true);
                    htmlControls.push({
                      id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                      sectionIndex: sectionIndex,
                      controlIndex: controlIndex,
                      sectionFactor: sectionFactor,
                      htmlInnerControls: <div style={{ order: gesamtIndex }} className={`section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{contactsWbpart}</div>
                    });
                  }
                }

                if ((typeof emdedCode !== "undefined") && emdedCode !== null) {
                  emdedCode = parse(emdedCode);
                  if (!webPartMap.has(webPartGuidID)) {
                    webPartMap.set(webPartGuidID, true);
                    htmlControls.push({
                      id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                      sectionIndex: sectionIndex,
                      controlIndex: controlIndex,
                      sectionFactor: sectionFactor,
                      htmlInnerControls: <div style={{ order: gesamtIndex }} className={`iframe-container section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{emdedCode}</div>
                    });
                  }
                }
                if ((typeof propertiesiTems === "undefined") && (typeof newsFilters === "undefined") && (typeof emdedCode === "undefined") && typeof (json.serverProcessedContent !== "undefined")) {
                  let pageUrlProp = json.serverProcessedContent.links;
                  let plainTxtProp = json.serverProcessedContent.searchablePlainTexts;
                  let imgSrcProp = json.serverProcessedContent.imageSources;
                  if ((typeof plainTxtProp !== "undefined") && plainTxtProp.length !== 0 && (typeof pageUrlProp !== "undefined") && pageUrlProp.length !== 0 && (typeof imgSrcProp !== "undefined") && imgSrcProp.length !== 0) {
                    //////console.log("hier in propertiesiTem");

                    if (typeof plainTxtProp.captionText !== "undefined") {
                      captionText = plainTxtProp.captionText;
                      //console.log(captionText);
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      captionText = null;
                    }
                    if (!webPartMap.has(webPartGuidID)) {
                      webPartMap.set(webPartGuidID, true);
                      let staticSiteWebpart = _getStaticHtml(json.serverProcessedContent);
                      if (staticSiteWebpart !== null && staticSiteWebpart.length !== 0) {
                        //////console.log("hier in propertiesiTems",webPartGuidID);
                        // webPartMap.set(webPartGuidID, true);
                        htmlControls.push({
                          id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                          sectionIndex: sectionIndex,
                          controlIndex: controlIndex,
                          sectionFactor: sectionFactor,
                          htmlInnerControls: <div style={{ order: gesamtIndex }} className={`orisecfactor: ${oriSectionFactor} section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  static-intern-links`}>{staticSiteWebpart}</div>
                        });
                      }
                    }
                  }
                }
                if (typeof propertiesiTems !== "undefined" && typeof (json.serverProcessedContent !== "undefined")) {
                  let linksSrcProp = json.serverProcessedContent.links;
                  let titlePlainTxt = json.serverProcessedContent.searchablePlainTexts;
                  let title = "";
                  let quickLinksTitle = "";
                  //////console.log("title",title);
                  if (json.properties.dataProviderId === "QuickLinks") {
                    const obj = linksSrcProp;
                    const titlkObj = titlePlainTxt;
                    let currIndex = 0;
                    Object.keys(obj).forEach((e, i) => {
                      if (e !== undefined && e !== null) {
                        if (e.indexOf("sourceItem.url") > 0) {
                          //////console.log(`key=${e}  value=${obj[e]}`);
                          let currItemIndex = e.split('.');
                          if (currItemIndex !== undefined && currItemIndex) {
                            ////console.log("index: ", currItemIndex[0]);
                            Object.keys(titlkObj).forEach((titleItem, i) => {
                              if (titleItem === "title") {
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                if (!(quickLinkTitleMap.has(titlkObj[titleItem]))) {
                                  quickLinkTitleMap.set(titlkObj[titleItem], true);
                                  quickLinksTitle = titlkObj[titleItem];
                                } else {
                                  quickLinksTitle = "";
                                }
                              }
                              let currTitleIndex = titleItem.toString().split('.');
                              if (currTitleIndex[0] === currItemIndex[0]) {
                                ////console.log("Current Title",titlkObj[titleItem])
                                title = titlkObj[titleItem];
                                // return false;
                              }

                            })
                          }
                          if (obj[e] !== undefined && obj[e] !== null) {
                            //////console.log("elemnt url",i);
                            var currentLink = obj[e];
                            var currTmpLink = decodeURIComponent(currentLink);
                            var tmpName = currentLink;
                            var webString = currentLink.split('?');
                            if (webString !== null && webString.length > 0) {
                              tmpName = webString[0].split("/").map((item: string) => item.trim());
                              if (tmpName.length > 1)
                                tmpName = tmpName[tmpName.length - 1];
                            }
                            if (obj[e] != null && (!internLinkMap.has(currTmpLink))) {
                              ////console.log("link in Quicklinks",currentLink);
                              internLinkMap.set(currTmpLink, true);
                              if (webString !== null && webString.length > 0)
                                currentLink = webString[0];
                              let currlinkHtml = _getQuickLinks(currentLink, title);
                              if (currlinkHtml !== undefined && currlinkHtml !== null) {
                                currIndex++;
                                webPartGuidID = webPartGuidID + currIndex;
                                if (!webPartMap.has(webPartGuidID)) {
                                  webPartMap.set(webPartGuidID, true);
                                  htmlControls.push({
                                    id: webPartGuidID, gesamtIndex: gesamtIndex,
                                    zoneindex: zoneIndex,
                                    sectionIndex: sectionIndex,
                                    controlIndex: controlIndex,
                                    sectionFactor: sectionFactor,
                                    htmlInnerControls: <div style={{ order: gesamtIndex }} className={`quiklinks-box doc-container section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{quickLinksTitle !== "" ? <h5 className='quicklinksTitle'>{quickLinksTitle}</h5> : ""}{currlinkHtml}</div>
                                  });
                                }
                              }

                            }
                          }
                        }
                      }

                    });

                  }
                }
              }
            }
          }
          if (attribs && typeof attribs["href"] !== "undefined" && attribs["href"] !== "" && attribs["data-sp-prop-name"] !== "linkUrl" && (!internLinkMap.has(attribs["href"]))) {
            //////console.log(attribs["href"]);
            var currTmpLink: any = decodeURIComponent(attribs["href"]);
            if (attribs.href != null && (!internLinkMap.has(currTmpLink)) && attribs.href.indexOf("forms.office.com") === 0) {
              internLinkMap.set(currTmpLink, true);
              currIndex++;
              let currlinkHtml = _getFileHtml(attribs.href);
              if (currlinkHtml != null) {
                webPartGuidID = webPartGuidID + currIndex;
                if (!webPartMap.has(webPartGuidID)) {
                  webPartMap.set(webPartGuidID, true);
                  htmlControls.push({
                    id: webPartGuidID, gesamtIndex: gesamtIndex,
                    zoneindex: zoneIndex,
                    sectionIndex: sectionIndex,
                    controlIndex: controlIndex,
                    sectionFactor: sectionFactor,
                    htmlInnerControls: <div style={{ order: gesamtIndex }} className={`doc-container section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{currlinkHtml}</div>
                  });
                }
              }
            }
          }
          return;
        }
      });
    }

    let htmlMap = new Map();
    let htmlFinalMap = new Map();
    let tmpAtrray: any[] = [];
    if (htmlControls !== null && htmlControls.length > 0) {
      htmlControls = htmlControls.sort((a, b) => a.zoneindex - b.zoneindex);
      //////console.log("before foreach",htmlControls);
      htmlControls.forEach((element) => {
        if (typeof element.zoneindex !== "undefined" && element.zoneindex !== null) {
          //////console.log(element)
          if (!htmlMap.has(element.id)) {
            htmlMap.set(element.id, true);
            tmpAtrray.push(element);
          }
        }

      });
    }
    tmpAtrray = groupByMulti(tmpAtrray, ["zoneindex", "sectionIndex"]);
    let finalTmpoArray: any[] = [];
    let tmpArraySOrt: any[] = [];
    var threeSection = "";
    if (tmpAtrray.length > 0) {
      let guidID: any = 0;
      if (tmpAtrray.length >= 3) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        threeSection = "threeSection";
      } else {
        threeSection = "";
      }
      tmpAtrray.forEach((element, i) => {
        guidID = i;
        element.forEach((interElement: any) => {
          guidID = guidID + "-" + interElement.id;
        });
        if (!htmlFinalMap.has(guidID)) {
          htmlFinalMap.set(guidID, true);
          finalTmpoArray.push({
            id: i, zoneindex: element.map((a: any) => a.zoneindex)[0], htmlInnerControls: <div style={{ display: 'flex', flexDirection: 'column' }} className={`webpart-Sectionstatic  ${threeSection} ${element.map((a: any) => a.sectionFactor)}`}>
              {element.map((a: any) => a.htmlInnerControls)}
            </div>
          });
        }
      })
    }
    if (finalTmpoArray.length > 0) {
      if (finalTmpoArray.length > 1) {
        htmlMap.clear();
        finalTmpoArray = groupByMulti(finalTmpoArray, ["zoneindex"]);
        finalTmpoArray = finalTmpoArray.sort((a, b) => a.zoneindex - b.zoneindex);
        let ID: any = 0;

        finalTmpoArray.forEach((element: any, elemtentIndex) => {
          let indexZone: any = null;
          let fullWith = "";
          let tmpSectionHtml: any[] = [];
          if (element.length === 1) {
            fullWith = "row-fullWith";
          }
          element.forEach((e1: any) => {
            ID = ID + e1.id;
            indexZone = e1.zoneindex;
          });
          if (!htmlMap.has(ID)) {
            htmlMap.set(ID, true);
            // if (elemtentIndex === 0) {
            //   let currSocialmMediaHtml: any = (<div className="col-sm-12 col-md-12 col-xs-12" >
            //     {/* <!-- Linkedin --> */}
            //     <LinkedinShareButton url={currShareUrl} title={currPageTitle}>
            //       <LinkedinIcon size={26} round />
            //     </LinkedinShareButton>
            //     {/* <!-- Twitter --> */}
            //     <TwitterShareButton url={currShareUrl} title={currPageTitle}>
            //       <TwitterIcon size={26} round />
            //     </TwitterShareButton>
            //   </div>
            //   );
            //   tmpSectionHtml.push(currSocialmMediaHtml);
            // }
            tmpSectionHtml.push(element.filter((a: any) => a.zoneindex === indexZone).map((a: any) => a.htmlInnerControls));
          }
          tmpArraySOrt.push(<div className={`row webpart-row ${fullWith} row-${indexZone}`}>{tmpSectionHtml}</div>);
        });
      } else {

        tmpArraySOrt.push(<div className="">{finalTmpoArray.map(a => a.htmlInnerControls)}</div>)
      }
    }
    let contentHtml: any = null;
    breadcrumbHtml = <div className="col-sm-12 col-md-12 col-lg-12">
      <nav className="breadcrumb-nav">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" target="_self">Home</a></li>
          <li className="breadcrumb-item" hidden={!termName}><span className="breadcrumb-link" onClick={() => { history.push('/posts/' + termName) }} >
            {termName}</span></li>
          <li className="breadcrumb-item"><span className="last-Item">{newsData.title}</span></li>
        </ol>
      </nav>
    </div>
    contentHtml = <div className="container-fluid canavas-box">{tmpArraySOrt.map((item) => (item))}</div>;
    currPageTitle = newsData.title;
    currSocialmMediaHtml= (<div className="col-sm-12 col-md-12 col-xs-12" >
                {/* <!-- Linkedin --> */}
                <LinkedinShareButton  url={currShareUrl} title={currPageTitle}>
                  <LinkedinIcon size={26} round />
                </LinkedinShareButton>
                {/* <!-- Twitter --> 
                <TwitterShareButton url={currShareUrl} title={currPageTitle}>
                  <TwitterIcon size={26} round />
                </TwitterShareButton>
                */}
                <div aria-label="print" className="share-button-box">
                <button aria-label="print" className="share-button" 
                onClick={(e) => window.print()}>
                <FaPrint size={"16px"} />
                </button>
                </div>
              </div>
              );
    if (headerImgExist) {
      headerHtml = (<div className="header-box" ><div className="header-inner " style={{ backgroundImage: "url('" + headerImgSrc + "')", backgroundPositionX: translateX + "%", backgroundPositionY: translateY + "%", backgroundSize: "cover" }}>
        <div className="header-overly" >
        </div>
      </div>
        <div className="container-row" >
          <div className="container-fluid canavas-box" >
            <div className="static-header-title">{newsData.title}</div>
          </div>
        </div>
      </div>
      )
    } else {
      headerHtml = (<h2 className="info-title title-h1">{newsData.title}</h2>);
    }
    newsContent = <div className="card mb-4 post-box" key={newsData.id}>
      {headerHtml}
      {currSocialmMediaHtml}
      {/* <h2 className="info-title">{newsData.title}</h2> */}
      {/* <img className="card-img-top post-img" src={currImgSrc} alt={currImgSrc} /> */}
      {/* <p>{moment.utc(date).format('L')}</p> */}
      <p>{contentHtml}</p>
      <div className='col-lg-12 print-area'>
        <div className='col-lg-12'>
        {newsData !=null?<span>Veröffentlicht {moment(new Date(newsData.publishedDate)).tz('Europe/Berlin').format('DD.MM.YYYY')}</span>:null}
        </div>
        <div className='col-lg-12'>
        <ul className='hashtag-list'>
        {currTermList.length!==0?currTermList.map((e)=>{
          return<li className='hashtag-list-item'>#{e.termName}</li> 
        }):null}
        </ul>
        </div>
      </div>
    </div>;
    return;
  }

  function GetEvent(_eventsList: any[]) {

    eventsHtml = null;
    weiterbildungHtml = null;
    veranstaltung.length = 0;
    eventResults.length = 0;
    let educationResults: any[] = [];
    let weiterbildung: any[] = [];
    let eventsList: any[] = [];
    let educationList: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const divMap = new Map();
    const eduMap = new Map();
    if (_eventsList.length > 9)
      _eventsList = _eventsList.slice(0, 9);
    _eventsList = _eventsList.sort((a, b) => new Date(a.Start_date).getTime() - new Date(b.Start_date).getTime());
    educationList = _eventsList.filter(a => educationArray.includes(a.Kind));
    eventsList = _eventsList.filter(a => !(educationArray.includes(a.Kind)));

    eventsList.forEach((v, i) => {
      eventResults.push({
        id: i,
        title: v.Description,
        // description: trunCat(v.Description_2, 50),
        description: v.Description_2,
        location: v.Location_City,
        endDate: v.End_date,
        startDate: v.Start_date,
        eventLink: {pathname:"/VeranstaltungsDetails", search:"?key=" +  v.No }
        // eventLink: "/AnonEvents.html#/VeranstaltungsDetails?key=" + v.No + "&Aux=" + 
      });
    });
    eventResults = eventResults.sort((a, b) => a.startDate - b.startDate);
    eventResults.forEach(item => {
      if (!divMap.has(item.id)) {
        divMap.set(item.id, true);
        // const startDate = moment.utc(new Date(item.startDate)).format('DD.MM.YYYY');
        const startDate = moment(new Date(item.startDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
        if (item.endDate !== "" && item.endDate !== item.startDate && new Date(item.endDate) > new Date(item.startDate)) {
          // const endDate = moment.utc(new Date(item.endDate)).format('DD.MM.YYYY');
          const endDate = moment(new Date(item.endDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
          veranstaltung.push(
            <div className="row event-item" onClick={() => {history.push(item.eventLink) }}>
              <div className="col-md-2 col-sm-2 event-date">
                <div className="startEndDate">
                  <div className="startDate-box">{startDate}</div><hr />
                  <div className="endDate-box">{endDate}</div>
                </div>
              </div>
              <div className="col-md-10 col-sm-10 event-Box-Info">
                <div className="card-body">
                  <h5 className="card-title event-title">{item.title !== null ? parse(item.title) : null}</h5>
                  {<p className="card-text event-desc">{item.description !== undefined && item.description !== null ? parse(item.description) : null}</p>}
                  {/* <p className="card-text event-loc">{_bottomStartDate}</p> */}
                </div>
              </div>
            </div>
          );
        } else {
          let day, restDate = null;
          if (item.startDate !== null && item.startDate !== "") {
            day = moment(item.startDate).locale('de').format('DD');
            restDate = moment(item.startDate).locale('de').format('MMM YYYY');
          }
          veranstaltung.push(
            <div className="row event-item" onClick={() => {history.push(item.eventLink) }}>
            {/* <div className="row event-item" onClick={() => item.eventLink}> */}
              <div className="col-md-2 col-sm-2 event-date">
                <div className="startDate">
                  <div className="day-box">{day}</div>
                  <div className="year-box">{restDate}</div>
                </div>
              </div>
              <div className="col-md-10 col-sm-10 event-Box-Info">
                <div className="card-body">
                  <h5 className="card-title event-title">{item.title !== null ? parse(item.title) : null}</h5>
                  {<p className="card-text event-desc">{item.description !== undefined && item.description !== null ? parse(item.description) : null}</p>}
                  {/* <p className="card-text event-loc">{item.location}</p> */}
                  {/* <p className="card-text event-loc">{_bottomStartDate}</p> */}
                </div>
              </div>
            </div>
          );
        }
      }
    });

    educationList.forEach((v, i) => {
      educationResults.push({
        id: i,
        title: v.Description,
        // description: trunCat(v.Description_2, 50),
        description: v.Description_2,
        location: v.Location_City,
        endDate: v.End_date,
        startDate: v.Start_date,
        // eventLink:{pathname:"/VeranstaltungsDetails", search:"?key=" +  v.No + "&Aux=" + v.AuxiliaryIndex1}
        eventLink:{pathname:"/VeranstaltungsDetails", search:"?key=" +  v.No}
      });
    });
    educationResults = educationResults.sort((a, b) => a.startDate - b.startDate);
    educationResults.forEach(item => {
      if (!eduMap.has(item.id)) {
        eduMap.set(item.id, true);
        // const startDate = moment.utc(new Date(item.startDate)).format('DD.MM.YYYY');
        const startDate = moment(new Date(item.startDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
        if (item.endDate !== "" && item.endDate !== item.startDate && new Date(item.endDate) > new Date(item.startDate)) {
          // const endDate = moment.utc(new Date(item.endDate)).format('DD.MM.YYYY');
          const endDate = moment(new Date(item.endDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
          weiterbildung.push(
            <div className="row event-item" onClick={() => {history.push(item.eventLink) }}>
              <div className="col-md-2 col-sm-2 event-date">
                <div className="startEndDate">
                  <div className="startDate-box">{startDate}</div><hr />
                  <div className="endDate-box">{endDate}</div>
                </div>
              </div>
              <div className="col-md-10 col-sm-10 event-Box-Info">
                <div className="card-body">
                  <h5 className="card-title event-title">{item.title !== null ? parse(item.title) : null}</h5>
                  {<p className="card-text event-desc">{item.description !== undefined && item.description !== null ? parse(item.description) : null}</p>}
                  {/* <p className="card-text event-loc">{_bottomStartDate}</p> */}
                </div>
              </div>
            </div>
          );
        } else {
          let day, restDate = null;
          if (item.startDate !== null && item.startDate !== "") {
            day = moment(item.startDate).locale('de').format('DD');
            restDate = moment(item.startDate).locale('de').format('MMM YYYY');
          }
          weiterbildung.push(
            <div className="row event-item" onClick={() => {history.push(item.eventLink) }}>
              <div className="col-md-2 col-sm-2 event-date">
                <div className="startDate">
                  <div className="day-box">{day}</div>
                  <div className="year-box">{restDate}</div>
                </div>
              </div>
              <div className="col-md-10 col-sm-10 event-Box-Info">
                <div className="card-body">
                  <h5 className="card-title event-title">{item.title !== null ? parse(item.title) : null}</h5>
                  {<p className="card-text event-desc">{item.description !== undefined && item.description !== null ? parse(item.description) : null}</p>}
                  {/* <p className="card-text event-loc">{item.location}</p> */}
                  {/* <p className="card-text event-loc">{_bottomStartDate}</p> */}
                </div>
              </div>
            </div>
          );
        }
      }
    });
    if (veranstaltung.length > 0) {
      let tmpEvents: any = null;
      tmpEvents = veranstaltung.map(function (item) {
        return <div className="event-item-box">{item}</div>;
      });
      eventsHtml = <div className="card mb-3 event-Box"><h4 className="webpart-Title col-lg-12">Veranstaltungen</h4>{tmpEvents}</div>

    }
    if (weiterbildung.length > 0) {
      let tmpEvents: any = null;
      tmpEvents = weiterbildung.map(function (item) {
        return <div className="event-item-box">{item}</div>;
      });
      weiterbildungHtml = <div className="card mb-3 event-Box"><h4 className="webpart-Title col-lg-12">Weiterbildung</h4>{tmpEvents}</div>

    }
  }
  function GetContact(_contactList: any[]) {
    const map = new Map();
    let contactTMpResult: any[] = [];
    let contactResult: any[] = [];
    let tmpContactHtml: any = null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const item of _contactList) {
      // eslint-disable-next-line no-loop-func
      item.term.forEach((e1: any) => currTermList.forEach((e2: any) => {
        if ((!map.has(item.email)) && e1.termId === e2.termId) {
          map.set(item.email, true);
          contactTMpResult.push(item);
        }
      }));
    }
    // sort Contacts per Ranking
    if (contactTMpResult.length > 0)
      contactTMpResult = contactTMpResult.sort((a, b) => a.ranking.length - b.ranking.length);
    for (const item of contactTMpResult) {
      // eslint-disable-next-line no-loop-func
      // item.term.forEach((e1: any) => currTermList.forEach((e2: any) => {
      //   if ((!map.has(item.email)) && e1.termId === e2.termId) {
      //     map.set(item.email, true);
      let currEmail = "mailto:" + item.email + "?subject=Kontakt";
      contactResult.push(
        <div className={`contact-col col-md-12`} >
          <div className="contact-box row">
            <div className="contact-col col-md-3 col-sm-2">
              <div className="imageArea-85">
                <div className="image-134">
                  <img className="image-135" src={`${process.env.PUBLIC_URL}/assets/Kontakte/${item.imgSrc}`} alt={item.name} />
                </div>
              </div>
            </div>
            <div className="text-box col-md-9 col-sm-9">
              <div className="name-box">{item.name}</div>
              {/* <div className="desc-box">{e2.Unternehmen}</div>*/}
              {<div className="desc-box">{item.position}</div>}
              <div className="desc-box"><a href={currEmail}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-email.svg`} className="icon-box" alt="email" /></a><div className="phone-box"><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-phone.svg`} className="icon-box" alt="phone" />{item.telefon}</div></div>
              {/* <div className="desc-box"></div> */}
            </div>
          </div>
        </div>);
      //   }
      // }
      // ));
    }
    if (contactResult.length > 0) {
      tmpContactHtml = contactResult.map(function (item) {
        return <div className="contact-item">{item}</div>;
      });
      contactHtml = <div className="row contact-row"><h4 className="webpart-Title col-lg-12">​​​​​​​Ansprechpartner</h4>{tmpContactHtml}</div>

    }

  }
  function GetCommunitesWithTerm(_communitiesList: any) {
    let linksHTMLArray: any[] = [];
    communitiesHtml = null;
    let listMap = new Map();

    for (const item of _communitiesList) {
      // eslint-disable-next-line no-loop-func
      item.terms.forEach((e1: any) => {
        if (e1.termName === termName && !listMap.has(item.id)) {
          listMap.set(item.id, true);
          let linkUrl = "";
          let title = item.title;
          if (item.link != null && item.link !== "") {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            linkUrl = _getSiteUrl(item.link);
            linksHTMLArray.push(
              <div className="row quickLink-Item" onClick={() => history.push(linkUrl)}>
                <div className="col-md-1">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/quikLinkIcon.png`} className="quicklinksIcon" alt="icon" />
                </div>
                <div className="col-md-10 quicklink-txt" data-automation-id="quick-links-item-title">
                  {title}
                </div>
              </div>);
          }

        }
      });
    }
    if (linksHTMLArray.length > 0)
      communitiesHtml = <div className="col-md-12"><h4 className="webpart-Title col-lg-12">Communities</h4>{linksHTMLArray}</div>
  }
  function GetCommunites(_communitiesList: any) {
    let linksHTMLArray: any[] = [];
    communitiesHtml = null;
    let listMap = new Map();

    for (const item of _communitiesList) {
      // her
      // eslint-disable-next-line no-loop-func
      item.terms.forEach((e1: any) => currTermList.forEach((e2: any) => {
        if (e1.termId === e2.termId && !listMap.has(item.id)) {
          listMap.set(item.id, true);
          let linkUrl = "";
          let title = item.title;
          if (item.link != null && item.link !== "") {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            linkUrl = _getSiteUrl(item.link);
            linksHTMLArray.push(
              <div className="row quickLink-Item" onClick={() => history.push(linkUrl)}>
                <div className="col-md-1">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/quikLinkIcon.png`} className="quicklinksIcon" alt="icon" />
                </div>
                <div className="col-md-10 quicklink-txt" data-automation-id="quick-links-item-title">
                  {title}
                </div>
              </div>);
          }

        }
      }
      ));
    }
    // if(linksHTMLArray.length>5)
    // linksHTMLArray=linksHTMLArray.slice(0,5);
    if (linksHTMLArray.length > 0)
      communitiesHtml = <div className="col-md-12"><h4 className="webpart-Title col-lg-12">Communities</h4>{linksHTMLArray}</div>
  }
  function SetDocuments() {
    let tmpDocResult: any = [];
    docshtml = null;
    for (const item of documentsData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      var currentICon: JSX.Element;
      if (item.term.length > 0) {
        // eslint-disable-next-line no-loop-func
        item.term.forEach((e1: any) => currTermList.forEach((e2: any) => {
          if (e1.termId === e2.termId && !docsMap.has(item.imgSrc)) {

            docsMap.set(item.imgSrc, true);
            var exst = item.imgSrc.toString().split(".").map((item: string) => item.trim());
            if (exst != null && exst.length > 0) {
              switch (exst[exst.length - 1]) {
                case 'pdf':
                  currentICon = <FileIcon extension="pdf" />;
                  break;
                case 'docx':
                  currentICon = <FileIcon extension="docx" />;
                  break;
                case 'xlsx':
                  currentICon = <FileIcon extension="xslx" />;
                  break;
                case 'pptx':
                  currentICon = <FileIcon extension="pptx" />;
                  break;
                case 'potx':
                  currentICon = <FileIcon extension="pptx" />;
                  break;
              }
            }
            documentsHTML.push(
              <div className="row doc-Item" onClick={() => { window.open(`${process.env.PUBLIC_URL}/assets/dokumente/${item.imgSrc}`, '_self') }}>
                <div className="col-md-1">
                  <div className="doc-icon">
                    {currentICon}
                  </div>
                </div>
                <div className="col-md-11">
                  <div className="card-body doc-item">
                    {/* <h5 className="card-title doc-title">{item.imgSrc}</h5> */}
                    <h5 className="card-title doc-title">{item.name !== null ? item.name : item.imgSrc}</h5>
                  </div>
                </div>
              </div>
            );
          }
        }
        ));
      }
    }
    if (documentsHTML.length > 0) {
      tmpDocResult = documentsHTML.map(function (item) {
        return <div className="doc-item">{item}</div>;
      });
      docshtml = <div className="row"><h4 className="webpart-Title col-lg-12">Dokumente</h4>{tmpDocResult}</div>

    }
  }
  function GetThemenList(_themenList: any[]) {
    const map = new Map();
    testMontial.length = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const item of _themenList) {
      // eslint-disable-next-line no-loop-func
      item.term.forEach((e1: any) => currTermList.forEach((e2: any) => {
        if (e1.termId === e2.termId) {

          themenResult.push({
            title: item.title,
            category: item.category,
            termId: Number(e1.termId),
            description: item.description,
            themeUrl: item.themeUrl,
            imgSrc: item.imgSrc,
            date: new Date(item.publishedDate)
          });
        }
      }
      ));
    }
    if (themenResult.length > 1)
      themenResult = themenResult.sort((a, b) => b.date - a.date);
    themenResult.forEach(e1 => {
      switch (e1.category) {
        case 'Testimonial':
          if (!map.has(e1.category)) {
            map.set(e1.category, true);
            testMontialHtml = (
              <div className="col-sm-6 col-lg-12 themenItem" key={e1.category}>
                {/* <a href={e1.themeUrl}> */}
                <img src={`${process.env.PUBLIC_URL}/assets/themen/${e1.imgSrc}`} className='card-img-top themen-img' alt={e1.imgSrc} />
                {/* </a> */}
              </div>);
          };
          break;
        case 'Werbung':
          if (!map.has(e1.category)) {
            map.set(e1.category, true);
            themenMapHtml = (
              <div className="col-sm-6 col-lg-12 themenItem mapLink" key={e1.category} onClick={e => { window.open(e1.themeUrl, '_self') }}>
                <img src={`${process.env.PUBLIC_URL}/assets/Themen/${e1.imgSrc}`} className='card-img-top themen-img' alt={e1.imgSrc} />
              </div>);
          };
          break;
      }
    });

  }
  //#endregion
  //#region Help medthod to rendering html in Canvas
  function trunCat(desc: string, max: number) {
    if (desc != null && desc.length > 0) {
      var suffix = "...";
      desc = desc.length < max ? desc : `${desc.substr(0, desc.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
    }
    return desc
  }
  function ChildrenNames(obj: any[]) {
    obj.forEach((subObj: any) => {
      if (typeof subObj.name !== "undefined" && subObj.name === "a") {
        if ((typeof subObj.attribs.href !== "undefined") && subObj.attribs.href !== "") {
          if (!internLinkMap.has(subObj.attribs.href)) {
            internLinkMap.set(subObj.attribs.href, true);
            subObj.attribs.href = _getInternLink(subObj.attribs.href);
            internLinkMap.set(subObj.attribs.href, true);
          }

        }
      }
      else if (typeof subObj.children !== "undefined") {
        ChildrenNames(subObj.children);
      }
    });
    return obj
  }
  function _getInternLink(imgUrl: any) {
    let paegUrlFullName = "";
    let pageUrl = imgUrl;
    if (imgUrl !== null && imgUrl.indexOf("sites/WVIB_Akademie#/") > 0) {
      let _tmpUrl = imgUrl.split('#');
      pageUrl = history.push(_tmpUrl[1]);
    }
    else if (imgUrl !== null && imgUrl !== "" && imgUrl.indexOf("sites/WVIBWeb/") > 0) {
      var file = imgUrl.split("/").map((item: string) => item.trim());
      var exst = file[file.length - 1].toString().split(".").map((item: string) => item.trim());
      if (exst.length > 1) {
        paegUrlFullName = file[file.length - 1];
        if (exst[exst.length - 1] === "aspx") {
          pageUrl = "/Pages/" + _createurltitle(paegUrlFullName);
        }
      }
    }
    return pageUrl;
  }
  function _createurltitle(_title:any){
   const pattern: RegExp = /[^0-9a-zA-Z.]/g;
   var tmpTtile= decodeURIComponent(_title);
   tmpTtile= tmpTtile.replace(pattern, "");
   return tmpTtile;
 }
  function _getImageSrc(fullName: any) {
    let name: any = null;
    var tmpName = fullName.split("/").map((item: string) => item.trim());
    //var exst = imgUrl.split("/").map((item: string) => item.trim());
    let currImgSrc: any = null;
    if (tmpName.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name = tmpName[tmpName.length - 1];
      // if (staticpages !== null && staticpages.length > 0) {
      //   staticpages.forEach((element, i) => {
      //     if (element.name !== null && element.name === name) {
      //       currImgSrc = element.imgSrc;
      //       return;
      //     }
      //   });
      // }
    }
    return currImgSrc;

  }
  function _getImgUrl(imgUrl: any) {
    let imageName = "";
    if (typeof imgUrl != "undefined") {
      var exst = imgUrl.split("/").map((item: string) => item.trim());
      if (exst != null && exst.length > 0) {
        imageName = exst[exst.length - 1]

      }
    }
    return imageName;
  }
  function _getFileViewer(fileUrl:any){
    let docUrl = _getSiteUrl(fileUrl);
    return <a className="news-link" href={docUrl}>
            <div className="static-tile-img">
             <Document file={docUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={1} width={360} height={450}  pageIndex={1}/>
            </Document>
			</div>
			</a>
  }
  function _getContactHtml(contactString: any) {
    let contactHtml: any = null;
    var json = JSON.parse(contactString);
    let contactResult: any[] = [];
    let contactTitle: any = null;
    if ((typeof json !== "undefined") && json !== null) {

      let contactFields = json.properties.fields;
      let columnContact = "contact-col-lg-3 col-lg-3";
      let tmpArray: any[] = [];
      let sortArray: any[] = [];
      let _contactsMap = new Map();
      // eslint-disable-next-line valid-typeof
      if ((typeof contactFields !== "undefined") && contactFields.length > 0 && contatcsData !== null && contatcsData.length > 0) {
        contactFields.forEach((e1: any) => {
          contatcsData.forEach((e2: any) => {
            if (e1 === Number(e2.id)) {
              let orderId = 1;
              let _currRank: string = e2.ranking;
              if (_currRank != null && _currRank.length > 0)
                orderId = _currRank.length
              let currNachname = e2.nachname;
              if (!_contactsMap.has(e2.id)) {
                _contactsMap.set(e2.id, true);
                tmpArray.push({ id: orderId, name: currNachname, item: e2 });
              }
            }
          });
        });
        sortArray = tmpArray.sort(function (a, b) {
          if (a.id === b.id) {
            a = a.name || '';
            b = b.name || '';
            return a.localeCompare(b, 'de-DE')
          }
          return a.id - b.id
        });
        ////console.log("tmp:",tmpArray);
        ////console.log("sort",sortArray);
        sortArray.forEach((e2: any) => {
          let currEmail = "mailto:" + e2.item.email + "?subject=Kontakt";
          contactResult.push(
            <div key={e2.item.id} id={e2.item.id} style={{ order: e2.id }} className={columnContact}  >
              <div className="contact-box row">
                <div className="contact-box-col col-md-3">
                  <div className="imageArea-85">
                    <div className="image-134">
                      <img className="image-135" src={`${process.env.PUBLIC_URL}/assets/Kontakte/${e2.item.imgSrc}`} alt={e2.item.name} />
                    </div>
                  </div>
                </div>
                <div className="text-box col-md-8">
                  <div className="name-box">{e2.item.name}</div>
                  {/* <div className="desc-box">{e2.Unternehmen}</div>*/}
                  <div className="desc-box">{e2.item.position}</div>
                  <div className="desc-box"><a href={currEmail} style={{ visibility: e2.item.email ? 'visible' : 'hidden' }}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-email.svg`} style={{ visibility: e2.item.email ? 'visible' : 'hidden' }} className="icon-box" alt="email" /></a><div className="phone-box" style={{ visibility: e2.item.telefon ? 'visible' : 'hidden' }}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-phone.svg`} className="icon-box" alt="phone" />{e2.item.telefon}</div></div>
                </div>
              </div>
            </div>
          );
        })

        contactTitle = json.properties.description;
        contactHtml = <div className="row contact-box"><div className="col-lg-12 contact-group"><h4>{contactTitle}</h4></div>{contactResult}</div>;
      }
    }
    return contactHtml;
  }
  function groupByMulti(arr: any[], properties: any[]) {

    var groups = [];
    for (var i = 0, len = arr.length; i < len; i += 1) {
      var obj = arr[i];
      if (groups.length === 0) {
        groups.push([obj]);
      }
      else {
        var equalGroup = null;
        for (var a = 0, glen = groups.length; a < glen; a += 1) {
          var group = groups[a];
          var equal = true;
          var firstElement = group[0];
          // eslint-disable-next-line no-loop-func
          properties.forEach(function (property) {
            if (firstElement[property] !== obj[property]) {
              equal = false;
            }

          });
          if (equal) {
            equalGroup = group;
          }
        }
        if (equalGroup) {
          equalGroup.push(obj);
        }
        else {
          groups.push([obj]);
        }
      }
    }
    return groups;
  };
  function _getSiteUrl(fullName: any) {
    let pageUrl: any = null;
    let name: any = null;
    //////console.log("Fullname",fullName);
    //////console.log("sitepage",fullName.indexOf("/SitePages/") );
    if (fullName !== null && fullName.indexOf("sites/WVIB_Akademie#/") > 0) {
      let _tmpUrl = fullName.split('#');
      pageUrl = history.push( _tmpUrl[1]);
    } else
      if (fullName.indexOf("www.wvib.de") > -1 || fullName.toLowerCase().indexOf("/sitepages/") === -1) {
        pageUrl = fullName

      } else {
        var tmpName = fullName.split("/").map((item: string) => item.trim());
        if (tmpName.length > 1) {
          name = tmpName[tmpName.length - 1];
          pageUrl = "/Pages/" + _createurltitle(name);
        }
      }
    return pageUrl;
  }
  function _getQuickLinks(_Url: any, title: any) {
    let currentICon: any;
    let docHtml: any = null;
    let fileName = "";
    let fileFullName = "";
    if (_Url != null && _Url !== "" && _Url.indexOf("sites/WVIBWeb/") !== 0) {
      var file = _Url.toString().split("/").map((item: string) => item.trim());
      var exst = file[file.length - 1].toString().split(".").map((item: string) => item.trim());
      if (exst.length > 1) {
        fileFullName = file[file.length - 1];

        fileName = exst[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (exst != null && exst.length > 0) {
          switch (exst[exst.length - 1]) {
            case 'pdf':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pdf.svg`} alt="pdf Icon " />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'docx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_docx.png`} alt="docx Icon" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'xlsx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_xlsx.png`} alt="xslx Icon" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'pptx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="pptx icon" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'potx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="potx Icon" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'aspx':
              var siteName = fileName.split("/").map((item: string) => item.trim());
              let pageUrl = "/Pages/" + _createurltitle(fileFullName);
              if (siteName.length > 1)
                fileName = siteName[siteName.length - 1]
              docHtml = <div className="doc-box">{currentICon}<a className="intern-link" href={pageUrl}>{title}</a></div>;
              break;
            default:
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/ic24-download.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/News/${fileFullName}`}>{title}</a></div>;
              break;
          }
        }
      }
    }
    return docHtml;

  }
  function _getStaticHtml(staticPageInfo: any) {
    let currStaticpageItem: any = [];
    let title = "";
    let descr = "";
    let siteUrl = "";
    let labelTxt = "";
    let currImgSrc = "";
    let imageHtml: any = null;
    let Layout = "list";
    if (typeof staticPageInfo.customMetadata !== "undefined" && staticPageInfo.customMetadata !== "" && typeof staticPageInfo.customMetadata.imageSource !== "undefined" && staticPageInfo.customMetadata.imageSource !== "") {
      if (typeof staticPageInfo.customMetadata.imageSource.width !== "undefined" && staticPageInfo.customMetadata.imageSource.width !== "") {

      }
      Layout = "kachel";
    }
    if (typeof staticPageInfo.links.formURL !== "undefined" && staticPageInfo.links.formURL !== "") {
      return null;
    }
    else if (typeof staticPageInfo.links.url !== "undefined" && staticPageInfo.links.url !== "") {
      var tmpName = staticPageInfo.links.url.split("/").map((item: string) => item.trim());
      var currTmpLink: any = null;
      if (tmpName.length > 1)
        currTmpLink = tmpName[tmpName.length - 1];
      if (!internLinkMap.has(currTmpLink))
        internLinkMap.set(currTmpLink, true);
      siteUrl = _getSiteUrl(staticPageInfo.links.url);
      if (typeof staticPageInfo.imageSources.imageURL !== "undefined" && staticPageInfo.imageSources.imageURL !== "") {
        if (staticPageInfo.imageSources.imageURL.indexOf("/_api/v2.1/") !== -1) {
          currImgSrc = _getImageSrc(staticPageInfo.links.url);

        } else {
          currImgSrc = _getImgUrl(staticPageInfo.imageSources.imageURL);
        }
        if (currImgSrc !== null)
          imageHtml = `${process.env.PUBLIC_URL}/assets/News/${currImgSrc}`;
      }


    } else if (typeof staticPageInfo.links.linkUrl !== "undefined" && staticPageInfo.links.linkUrl !== "") {

      var tmpLink = staticPageInfo.links.linkUrl.split("/").map((item: string) => item.trim());
      var currLink: any = null;
      if (tmpLink.length > 1)
        currLink = tmpLink[tmpLink.length - 1];
      siteUrl = _getSiteUrl(staticPageInfo.links.linkUrl);
      if (typeof staticPageInfo.imageSources.imageSource !== "undefined") {
        if (staticPageInfo.imageSources.imageSource.indexOf("/_api/v2.1/") !== -1) {
          currImgSrc = _getImageSrc(staticPageInfo.links.url);
        } else {
          currImgSrc = _getImgUrl(staticPageInfo.imageSources.imageSource);
        }
        if (currImgSrc !== null) {
          imageHtml = `${process.env.PUBLIC_URL}/assets/News/${currImgSrc}`;

        } else {
          if (!internLinkMap.has(currLink))
            internLinkMap.set(currLink, true);
        }

      } else if (staticPageInfo.searchablePlainTexts.label) {
        Layout = "LabelButton";
        if (!internLinkMap.has(currLink))
          internLinkMap.set(currLink, true);
        ////console.log(staticPageInfo.searchablePlainTexts.label);
      }
    }
    else if (typeof staticPageInfo.links !== "undefined" && typeof (staticPageInfo.imageSources !== "undefined") && typeof (staticPageInfo.imageSource !== "undefined")) {
      //////console.log("", staticPageInfo.imageSources.imageSource);
      currImgSrc = _getImgUrl(staticPageInfo.imageSources.imageSource);
      if (currImgSrc !== null && currImgSrc !== "") {
        //////console.log(currImgSrc);
        imageHtml = `${process.env.PUBLIC_URL}/assets/News/${currImgSrc}`;
        Layout = "justImage";

      } else {
        Layout = "none"
      }
    }
    if (typeof staticPageInfo.searchablePlainTexts.title !== "undefined" && staticPageInfo.searchablePlainTexts.title !== "")
      title = staticPageInfo.searchablePlainTexts.title;
    if (typeof staticPageInfo.searchablePlainTexts.description !== "undefined" && staticPageInfo.searchablePlainTexts.description !== "")
      descr = staticPageInfo.searchablePlainTexts.description;
    if (typeof staticPageInfo.searchablePlainTexts.label !== "undefined" && staticPageInfo.searchablePlainTexts.label !== "")
      labelTxt = staticPageInfo.searchablePlainTexts.label;
      var isImage:boolean=false;
      if(siteUrl.indexOf(".aspx"))
      {
        isImage=true;
      }
    if (Layout === "kachel") {
      currStaticpageItem.push(
        <div className='static-tile-box'>
          <a className="news-link" href={siteUrl}>
            <div className="static-tile-img">
            {isImage?
              <img src={imageHtml} alt={imageHtml} />:
              <Document file={siteUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={1} width={360} height={450}  pageIndex={1}/>
              </Document>
            }
              {/* <img src={imageHtml} alt={imageHtml} /> */}
            </div>
          </a>
        </div>
      );
    } else if (Layout === "justImage") {
      if (pageImage == null)
        pageImage = imageHtml;
      currStaticpageItem.push(
        <div className='static-tile-box'>
          <div className="static-tile-justImg">
            <img src={imageHtml} alt={imageHtml} />
            <div hidden={captionText == null} className="img-Title">{captionText !== null ? parse(captionText) : null}</div>
            {/* <span hidden={imageHtml!==imgSrcWithCaption}>{ imgTile}</span> */}
          </div>
        </div>);
    } else if (Layout === "LabelButton") {
      currStaticpageItem.push(<div className='static-tile-box'>
        <a className="news-link" href={siteUrl}>
          <button className="labelButton">{labelTxt}</button>
        </a>
      </div>
      );
    }
    else if (Layout !== "none") {
      let CurrimageHtml: any = null;
      if (imageHtml != null) {
        CurrimageHtml = <div><div className="image-static-Box-list" onClick={(e) => window.open(siteUrl, "_self")}>
          <img className="news-image" src={imageHtml} alt={imageHtml} />
        </div></div>;
      }
      currStaticpageItem.push(<div>
        <div className='post-static-Item'>
          {CurrimageHtml}
          <div className='post-static-content'>
            <div className='post-static-title'><a className="news-link" href={siteUrl}>{title !== undefined && title !== null ? parse(title) : null}</a></div>
            <div className='post-static-desc'>{descr !== undefined && descr !== null ? parse(descr) : null}</div>
          </div>
        </div>
      </div>);
    }
    return currStaticpageItem;
  }

  function _getFileHtml(imgUrl: any) {
    let currentICon: any;
    let docHtml: any = null;
    let fileName = "";
    let fileFullName = "";
    if (imgUrl != null && imgUrl !== "" && imgUrl.indexOf("sites/WVIBWeb/") !== 0) {
      var file = imgUrl.toString().split("/").map((item: string) => item.trim());
      var exst = file[file.length - 1].toString().split(".").map((item: string) => item.trim());
      if (exst.length > 1) {
        fileFullName = file[file.length - 1];

        fileName = exst[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (exst != null && exst.length > 0) {
          switch (exst[exst.length - 1]) {
            case 'pdf':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pdf.svg`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{fileName}</a></div>
              break;
            case 'docx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_docx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{fileName}</a></div>
              break;
            case 'xlsx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_xslx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{fileName}</a></div>
              break;
            case 'pptx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{fileName}</a></div>
              break;
            case 'potx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{fileName}</a></div>
              break;
            case 'aspx':
              var siteName = fileName.split("/").map((item: string) => item.trim());
              let pageUrl = "/Pages/" + _createurltitle(fileFullName);
              if (siteName.length > 1)
                fileName = siteName[siteName.length - 1]
              docHtml = <div className="doc-box">{currentICon}<a className="intern-link" href={pageUrl}>{fileName}</a></div>;
              break;
            default:
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/ic24-download.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/News/${fileFullName}`}>{fileName}</a></div>;
              break;
          }
        }
      }
    }
    return docHtml;

  }
  //#endregion
}
