import * as React from 'react';

import { Carousel } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import AzureConnector from '../Module/AzureConnector';
const azurecon = new AzureConnector();
let result:any[]=[];
let htmlControl:any=[]
export interface ITopCarousel{
    newsData:any[];
}
export default function TopCarousel(props:ITopCarousel) {
    const [loadedData, setloadedData] = React.useState<any[]>([]);
   let history = useHistory();
    React.useEffect(() => {
        let tmpNews:any[]=[];
        if(props.newsData.length!==0)
        {
            props.newsData.forEach((element)=>{
                tmpNews.push({element});
            })
            tmpNews=tmpNews.sort((a, b) => b.publishedDate - a.publishedDate);
            setloadedData(tmpNews);
        }
      },[props.newsData]);
      if(loadedData!=null &&loadedData.length>0 )
      {
        //console.log("before  call GetUnique", new Date());
        GetUnique(loadedData);
        window.scrollTo(0, 0)
      }

return <div className="col-lg-12">{htmlControl}</div>;

function GetUnique(_topCarouselList:any[])
{
    let mutliItems=true;
    result.length=0;
    const map = new Map();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //console.log("begin GetUnique", new Date());    
    for (const item of _topCarouselList) {
        if(!map.has(item.element.id) &&item.element.category!==null&& item.element.category.indexOf("Öffentlichkeit") !==-1){
            map.set(item.element.id, true);    // set any value to Map
            let blogUrl ="https://training2me.sharepoint.com/sites/WVIB-test/SitePages/Information.aspx?newsId="+item.element.id;
            // let currImgSrc="https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
            let currImgSrc=`${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
            if(item.element.imgSrc!=null)
            {
                currImgSrc=`${process.env.PUBLIC_URL}/assets/News/${item.element.imgSrc}`;
            }
            result.push({
                id:item.element.id,
                title:item.element.title,
                urltitle:item.element.urltitle,
                category:item.element.category,
                escription:item.element.description,
                blogUrl:blogUrl,
                imgSrc:currImgSrc,
                date:new Date(item.element.publishedDate)
            });
        }
    }
    if(result.length > 0)
    {
    if(result.length>1)
    result=result.sort((a, b) => b.date - a.date);
    if(result.length>7)
    result=result.slice(0,7);
    if(result.length===1)
    mutliItems=false
    if(result!=null && result.length>0)
    {
    htmlControl= <div className="col-lg-12 mb-12 topCaroussel" >
    <Carousel indicators={mutliItems} controls={mutliItems} id="topCarousel" slide={true} interval={4000}>{result.map((newsItem)=>(
        <Carousel.Item onClick={() => {
            // history.push('/information/'+ newsItem.id)
            history.push('/information/'+ encodeURIComponent(newsItem.urltitle));
          } }>
            <img 
            className="d-block w-100"
            //src={`${banner.imgSrc}`}
             src={newsItem.imgSrc}
             alt={newsItem.title}
          />
           {/* <div className="black-overlay"></div> */}
        <Carousel.Caption>
            <h1 className="caroussel-Title" >
                <span>{newsItem.title}</span>
            </h1>
            {/* <div onClick={() => {
                history.push('/information/'+ newsItem.id)
            } } className="link-txt"><span className="arrow-right">mehr erfahren <span className="arrow"><img src="/assets/images/right_Arrow.png" alt="" /></span></span>
            </div> */}
        </Carousel.Caption>
         </Carousel.Item>
    ))}</Carousel>
    </div>
    //console.log("end GetUnique", new Date());    
    }
    }
}

}

