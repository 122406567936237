const { create } = require('xmlbuilder2');
const generateSitemap = () => {
    const root = create({ version: '1.0', encoding: 'UTF-8' }).ele('urlset', {
      xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9',
    });
  
    fetch('/assets/json/news.json').then((res) => res.json())
    .then((jsonNewsData) => {
        if (jsonNewsData !== undefined && jsonNewsData !== null ) {
        jsonNewsData.forEach(function (element) {
            if (element != null) {
                const urlElement = root.ele('url');
                urlElement.ele('loc', {}, `https://www.wvib.de/Information/${element.title}`);
                urlElement.ele('lastmod', {}, element.publishedDate);
            }
            });
        }
        });
  
  
    const xml = root.end({ prettyPrint: true });
    return xml;
  };
  
  module.exports = generateSitemap;