import * as React from 'react';
import {useHistory } from 'react-router-dom';
import AzureConnector from '../Module/AzureConnector_Anon';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/WordCloud.css');
// require('../css/AkademieStyles.css');
interface IWordCloudProps {
  noLoad?:boolean,
  extraTags?:[]
}

export default function WordCloud(props:IWordCloudProps) {
  const [tags, setTags] = React.useState(null);
  const [jsxArray, setJsxArray] = React.useState([]);
  const history = useHistory();
  React.useEffect(() => {
    if(tags === null && (props.noLoad === undefined || props.noLoad === false))
    {
      var urlEndPartKey:string = "/nav/keywords?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
      var azureCon:AzureConnector = new AzureConnector();
      azureCon.get(urlEndPartKey)
        .then((result: any) => {
            console.log("result: " + result);
            setTags(getTagsFromNav(result));
        })
        .catch((error: any) => {
            console.error(error);
        });
    }
    else if (tags === null && props.noLoad && props.extraTags)
    {
      setTags(props.extraTags);
    }
  }, [props.extraTags, props.noLoad, tags]);

  React.useEffect(() => {
    var temparray:any[] = [];
    if(tags != null)
    {
      for(var i:number = 0; i < tags.length; i++)
      {
        let text = tags[i].Key_Word;
        if(props.noLoad === true)
        {
          temparray.push(<div key={"cloudKey" + i} className="tag-item-Lite" onClick={() => {history.push({pathname:"Veranstaltungsübersicht", search:"?rubrik=" + text});}}><div className="tag-area-Lite"><div className="tag-Icon-box-Lite"><span>#</span></div></div><div className="tag-Txt-Lite">{tags[i].Key_Word}</div></div>);
        }
        else
        {
          temparray.push(<div key={"cloudKey" + i} className="tag-item" onClick={() => {history.push({pathname:"Veranstaltungsübersicht", search:"?rubrik=" + text});}}><div className="tag-area"><div className="tag-Icon-box"><span>#</span></div></div><div className="tag-Txt">{tags[i].Key_Word}</div></div>);
        }
      }

      if(!props.noLoad)
      {
        temparray = (shuffleArray(temparray)).slice(0, 15);
      }

      setJsxArray(temparray);
    }
  }, [history, props.noLoad, tags]);

  function getTagsFromNav(jsonInput: string | any[])
  {
    var resArray = [];

    for(var i = 0; i < jsonInput.length; i++)
    {
      resArray.push({"Key_Word": jsonInput[i].Key_Word});
    }
    return resArray;
  }

  function shuffleArray(array:any[]) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  if(jsxArray.length === 0)
  {
    return <div>
      Es wurden keine Tags gefunden.
    </div>;
  }

  return <div>
    {jsxArray}
  </div>;
}
