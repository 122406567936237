import React from 'react';
import ReactLoading from 'react-loading';
import '../css/custom-style.css';
// import logo from '../images/logo.png';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import GoogleAnalytics from './GoogleAnalytics';
import VeranstaltungKachelComponent from './VeranstaltungKachelComponent_Anon';

/* begin param*/
let result: any[] = [];
let eventsResult: any[] = [];
let currTermList: any[] = [];
let searchQuery: string;
let searchQueryArray:any[]=[];
let loading: boolean = false;
/* end param*/

export default function Search() {
  let history = useHistory();
  let postsBox: any = null;
  const [searchTxt, setSearchQuery] = React.useState("");
  const [searchTxtArray, setSearchQueryArray] = React.useState<any[]>([]);
  const [loadedData, setloadedData] = React.useState<any[]>([]);
  const [eventsData, setEventsData] = React.useState<any[]>([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { queryTxt } = useParams();
  const eventsJson=require('../assets/json/events.json');
  React.useEffect(() => {
    if ({ queryTxt } != null) {

      searchQuery = { queryTxt }.queryTxt;
      var tmpArray=searchQuery.split(' ');
      searchQueryArray = [...tmpArray];
      
    }
    setSearchQueryArray(searchQueryArray);
    setSearchQuery(searchQuery);
    
  }, [queryTxt]);

  React.useEffect(() => {
    if(eventsData.length===0)
    {
      fetch('/assets/json/events.json').then((res) => res.json())
      .then((jsonEventsData) => {
        setEventsData(getValfromNav(jsonEventsData));
      });
    }
    if(loadedData.length===0)
    {
      fetch('/assets/json/news.json').then((res) => res.json())
      .then((jsonNewsData) => {
        setloadedData(jsonNewsData);
      });
    }
  },[eventsData, eventsJson, loadedData]);
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  /* Fill html Container*/
  if (loadedData != null && loadedData.length > 0) {
    FillBodyContainer();
  }
  /*  Generate Themen List and Get Themen-Tiles for the Current Term*/

  return (
    <div className="App" key="information">
      <header className="App-header">
        <Header />
        {/* Page Content */}
        <div className="container-fluid">
          <div className="search-row">
            <div className="container-row">
              <div className="col-md-12 searchArea">
                <div className="container-fluid container-search">
                  <div className="row row-search">
                    <div className="col-md-4">
                      <div className="input-group">
                        <input type="search" name="searchInput" className="form-control" placeholder="Suchen..." 
                        // onChange={e => setSearchQuery(e.currentTarget.value)} 
                        onBlur={(e: { target: { value: any; }; }) => {
                          searchQuery=e.target.value;
                          if(e.target.value!=="")
                          {
                            
                            var tmpArray=searchQuery.split(' ');
                            searchQueryArray = [...tmpArray];
                          }else{
                            searchQueryArray=e.target.value;
                          }
                          setSearchQueryArray(searchQueryArray);
                          FillBodyContainer();
                          }} />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" disabled={!searchTxt} type="button" onClick={FillBodyContainer}>
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"><div className="wvib-Logo"><img src={`${process.env.PUBLIC_URL}/assets/News/Logo_2021_wvib_Schwarzwald_AG_PFADE_A4_Office.png`} className="logo-img" alt="logo" /></div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row container-row">
            {/* Posts Column */}
            <div className="col-sm-12 col-lg-8 posts-section" hidden={!loading}>
              {postsBox}
            </div>
            <div className="col-sm-12 col-lg-12 loading-box" hidden={loading}>
              <ReactLoading color={"#293940"} width={175} type={"spin"} />
              {postsBox}
            </div>
          </div>
          {/* /.row */}

        </div>
        {/* /.container */}
      </header>
      <GoogleAnalytics/>
      {/* <Flinque/> */}
      <Footer />
    </div>
  );

  function FillBodyContainer() {
    const map = new Map();
    result.length = 0;
    currTermList.length = 0;
    const newsMap = new Map();
 // const map = new Map();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const item of loadedData) {

      const currTxt = item.title;
      const currDesc = item.description;
      const currCanvas=item.canvas;
      const currCategory = item.category;
      // eslint-disable-next-line no-loop-func
      item.term.forEach((e1: any) => {
     
        searchQueryArray.forEach((element: string) => {
          if((
            (e1.termName.toLowerCase().indexOf(element.toLowerCase())) !== -1 ||
            (currTxt && currTxt.toLowerCase().indexOf(element.toLowerCase())!== -1) ||
            (currCanvas && currCanvas.toLowerCase().indexOf(element.toLowerCase())!==-1) ||
            (currDesc && currDesc.toLowerCase().indexOf(element.toLowerCase())!==-1) ||
            (currCategory && currCategory.toLowerCase().includes(element.toLowerCase())) 
            ) && !newsMap.has(item.id) 
            )
          {
            newsMap.set(item.id, true); 
            let blogUrl = "/information/" +encodeURIComponent(item.urltitle);
            // let currImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
            let currImgSrc=`${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
            if (item.imgSrc != null){
              currImgSrc = `${process.env.PUBLIC_URL}/assets/News/${item.imgSrc}`;
            }
            var curDate = item.publishedDate;
            if (curDate != null && curDate !== "") {
              var newDate = convertUTCDateToLocalDate(curDate);
              curDate = moment(newDate).locale('de').format("LL");
            }
            result.push({
              type:'news',
              id: item.id,
              title: item.title,
              category: item.category,
              termName: e1.termName,
              description: trunCat(item.description, 100),
              blogUrl: blogUrl,
              imgSrc: currImgSrc,
              date: curDate,
              sortDate:item.publishedDate
            });
            if (!map.has(e1.termId)){
              map.set(e1.termId, true);
              currTermList.push({
                termName: e1.termName,
                termId: e1.termId
              });
            }
          }  
        })
      });
      
    }
    eventsData.forEach((item)=>{
      const currTxt = item.Kind;
      const currDesc = item.Bezeichnung;
      const currNo=item.Key;
      if(item.KeyWords)
      item.KeyWords.forEach((e:any)=>{
        searchQueryArray.forEach((element: string) => {
        if((
          (e.Key_Word.toLowerCase().indexOf(element.toLowerCase())) !== -1 ||
          (currTxt && currTxt.toLowerCase().indexOf(element.toLowerCase())!== -1) ||
          (currNo && currNo.toLowerCase().indexOf(element.toLowerCase())!==-1) ||
          (currDesc && currDesc.toLowerCase().indexOf(element.toLowerCase())!==-1)
          ) && !newsMap.has(item.Key) 
          )
          {
            newsMap.set(item.Key, true); 
            result.push(item);
          }
        })
      })
      if(item.Texts)
      item.Texts.forEach((e:any)=>{
        searchQueryArray.forEach((element: string) => {
          if((
            (e.HTML_Content.toLowerCase().indexOf(element.toLowerCase())) !== -1 ||
            (currTxt && currTxt.toLowerCase().indexOf(element.toLowerCase())!== -1) ||
            (currNo && currNo.toLowerCase().indexOf(element.toLowerCase())!==-1) ||
            (currDesc && currDesc.toLowerCase().indexOf(element.toLowerCase())!==-1)
            ) && !newsMap.has(item.Key) 
            )
            {
              newsMap.set(item.Key, true); 
              result.push(item)
            }
          })
      });
    })

    if (result.length > 1)
    {
      console.log("result beofre sort",result);
      result = result.sort((a,b)=>new Date(b.sortDate).getTime()-new Date(a.sortDate).getTime());
    }
    /* generate Post-Box*/
    // let tmpResult:any[]=[];
    // eslint-disable-next-line array-callback-return
    postsBox = result.map(function (item,index) {
       if(item.type==="news")
       {
      //   tmpResult.push(
       return(<div className='post-Item post-search-item'>
          <div className='post-img'>
            <div className='ms-Image root-188 link-pointer'  onClick={() => {
                history.push(item.blogUrl)
              }}>
              <img src={item.imgSrc} className='post-img' alt={item.imgSrc} />
            </div>
          </div>
          <div className='post-content'>
            <div className='post-title link-pointer' onClick={() => {
                history.push(item.blogUrl)
              }}>{item.title}</div>
           <div className='post-desc'>{item.description}</div>
           <div className="link-box">
            <div className='post-authorDate'>
              <div className='post-date'>
                {item.date}
              </div>
            </div>
               {/* <span className='post-link' onClick={() => {
                history.push(item.blogUrl)
              }}>mehr lesen
              <hr className="read-line"></hr></span>  */}

            </div>
          </div>
        </div>
      )
       }
      else if(item.type==="event"){
      return(<VeranstaltungKachelComponent description = "" veranstaltung={item}/>)
      }
      // return tmpResult;
    });
     loading = true;
  }

  function convertUTCDateToLocalDate(date:any) {
    var offset = moment().utcOffset();
    var newDate = moment.utc(date).utcOffset(offset);
    return newDate;   
  }
  function trunCat(desc: string, max: number) {
    if (desc != null && desc.length > 0) {
      var suffix = "...";
      desc = desc.length < max ? desc : `${desc.substr(0, desc.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
    }
    return desc
  }

}




// function getValfromNav(eventsJson: any): React.SetStateAction<any[]> {
function getValfromNav(jsonInput: string | any[])
{
  var resArray:any[] = [];

  for(var i = 0; i < jsonInput.length; i++)
  {
    var dateSplit:any[] = jsonInput[i].Start_date.split("T")[0].split("-");
    var fullDate:string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];

    // eslint-disable-next-line @typescript-eslint/no-redeclare
    var dateSplit:any[] = jsonInput[i].End_date.split("T")[0].split("-");
    var endFullDate:string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];

    var text:string = "";
    if(jsonInput[i].Texts !== undefined && jsonInput[i].Texts !== '')
    {
      for(var j:number = 0; j < jsonInput[i].Texts.length; j++)
      {
        if(jsonInput[i].Texts[j].Text_Type_No === "1")

        text = jsonInput[i].Texts[j].HTML_Content;
        text = text.replace(/<[^>]*>?/gm, '');
        text = HtmlDecode(text);
        if(text.length > 200)
        {
          text = text.substring(0, 200) + "...";
        }
        break;
      }
    }

    var price:string = "-";
    if(jsonInput[i].Services)
    {
      if(jsonInput[i].Services.length > 1)
      {
        // eslint-disable-next-line @typescript-eslint/no-redeclare
        for(var j:number = 0; j < jsonInput[i].Services.length; j++)
        {
          if(jsonInput[i].Services[j].Mitglieder_Leistung === false)
          {
            price = jsonInput[i].Services[j].Amount_LCY_net;
            break;
          }
        }
      }
      else if (jsonInput[i].Services.length === 1)
      {
        price = jsonInput[i].Services[0].Amount_LCY_net;
      }
    }

    resArray.push({"Bezeichnung": jsonInput[i].Description,
                      "type":"event",
                      "Key": jsonInput[i].No,
                      "AuxiliaryIndex1": jsonInput[i].AuxiliaryIndex1,
                      "Datum": fullDate,
                      "sortDate":jsonInput[i].Start_date,
                      "EndDatum": endFullDate,
                      "Ort": jsonInput[i].Location_Name + ", " + jsonInput[i].Location_Address + ", " + jsonInput[i].Location_Post_Code + " " + jsonInput[i].Location_City,
                      "Auslastung": jsonInput[i].Participant_total + "/" + jsonInput[i].Max_Participant,
                      "Text": text,
                      "Dauer": jsonInput[i].Duration,
                      "Kind": jsonInput[i].Kind,
                      "Preis": price,
                      "Texts":jsonInput[i].Texts,
                      "KeyWords":jsonInput[i].KeyWords,
                      "button": "Details test"} );
  }

  return resArray;
}
function HtmlDecode(s:any)
{
  var el:HTMLDivElement = document.createElement("div");
  el.innerHTML = s;
  s = el.innerText;
  return s;
}

