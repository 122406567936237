import * as React from 'react';
import { DefaultButton, SearchBox } from 'office-ui-fabric-react';
import AzureConnector from '../Module/AzureConnector_Anon';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/WordCloud.css');
// require('../css/AkademieStyles.css');

export interface ISearchComponentProps {
  description: string;
  setVeranstaltung: React.Dispatch<any>;
  setSearchResult: React.Dispatch<any>;
  veranstaltungen: any;
  setCurrentVeranstaltung: React.Dispatch<any>;
  fullSearch: boolean;
  rubrik: string;
}

export default function SearchComponent(props: ISearchComponentProps) {
  const [searchValue, setSearchValue] = React.useState<string>("");
  let tdSearchLable:React.CSSProperties = {
    padding: '2% 2% 0',
    fontFamily: 'RalewayBold',
    color: '#293940'
  };

  let tdSearch:React.CSSProperties = {
    minWidth: '400px',
    padding: '0% 2% 2% 2%',
    height: '48px'
  };

  let tdButtons:React.CSSProperties = {
    padding: '0 2% 2% 0'
  };

  

  React.useEffect(() => {
  },[]);

  var buttonsJsx:any[] = createButtons(props.fullSearch);

  return (
    <table className="tdFullWidth">
      <tbody>
        <tr>
          <td style={tdSearchLable}>
            Ihr Suchbegriff
          </td>
        </tr>
        <tr>
          <td style={tdSearch}>
            <SearchBox id="SearchBox" className="testSearchbox" placeholder="Suchen..." onSearch={newValue => search()} value={searchValue}
            onChange={(e) => {
            var newValue=e.target.value;
            var currValue=newValue!==undefined&&newValue!==null ?newValue.toString():"";
              setSearchValue(currValue);
            }}
            />
          </td>
          <td style={tdButtons}>
            {buttonsJsx}
          </td>
        </tr>
      </tbody>
    </table>
  );


  function filter()
  {
    if(props.veranstaltungen == null)
    {
      alert("Es sind keine Daten zum filtern vorhanden.");
      return;
    }

    var searchString:any[] = searchValue.split(" ");

    var resultVeranstaltungen:any[] = [];

    for(var i:number = 0; i < props.veranstaltungen.length; i++)
    {
      var add:boolean = true;
      for(var j:number = 0; j < searchString.length; j++)
      {
        if(props.veranstaltungen[i].Bezeichnung.toLowerCase().indexOf(searchString[j].toLowerCase()) === -1 && props.veranstaltungen[i].Text.toLowerCase().indexOf(searchString[j].toLowerCase()) === -1)
        {
          add = false;
          break;
        }
      }

      if(add)
      {
        resultVeranstaltungen.push(props.veranstaltungen[i]);
      }
    }

    props.setCurrentVeranstaltung(resultVeranstaltungen);
  }

  function search()
  {
    if(searchValue == null ||searchValue === "")
    {
      return;
    }

    if(!props.fullSearch)
    {
      filter();
      return;
    }

    setSearchValue(searchValue.trim());

    var urlEndPart:string = "/nav/search/" + searchValue + "/events?expand=texts,services&code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
    // if(props.rubrik != "")
    // {
    //   urlEndPart = "/nav/search/keyword/" + props.rubrik + "/" + searchValue + "/events?expand=texts,services&code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
    // }
    const azureCon = new AzureConnector();

    azureCon.get(urlEndPart)
        .then((result: any) => {
            console.log("result: " + result);
            props.setSearchResult(true);
            props.setVeranstaltung(getValfromNav(result));
        })
        .catch((error: any) => {
          console.error(error);
          if(error === "no data")
          {
            props.setSearchResult(false);
          }
        });
  }

  function getValfromNav(jsonInput: string | any[])
  {
    var resArray:any[] = [];

    for(var i:number = 0; i < jsonInput.length; i++)
    {
      var dateSplit:any[] = jsonInput[i].Start_date.split("T")[0].split("-");
      var fullDate:string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
      
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      var dateSplit:any[] = jsonInput[i].End_date.split("T")[0].split("-");
      var endFullDate:string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];

      var text:string = "";
      if(jsonInput[i].Texts !== undefined && jsonInput[i].Texts !== '')
      {
        for(var j:number = 0; j < jsonInput[i].Texts.length; j++)
        {
          if(jsonInput[i].Texts[j].Text_Type_No === "1")
          {
            text = jsonInput[i].Texts[j].HTML_Content;
            text = text.replace(/<[^>]*>?/gm, '');
            text = HtmlDecode(text);
            if(text.length > 200)
            {
              text = text.substring(0, 200) + "...";
            }
            break;
          }
        }
      }

      var price:string = "-";
      if(jsonInput[i].Services)
      {
        if(jsonInput[i].Services.length > 1)
        {
          // eslint-disable-next-line @typescript-eslint/no-redeclare
          for(var j:number = 0; j < jsonInput[i].Services.length; j++)
          {
            if(jsonInput[i].Services[j].Mitglieder_Leistung == false)
            {
              price = jsonInput[i].Services[j].Amount_LCY_net;
              break;
            }
          }
        }
        else if (jsonInput[i].Services.length === 1)
        {
          price = jsonInput[i].Services[0].Amount_LCY_net;
        }
      }

      resArray.push({"Bezeichnung": jsonInput[i].Description,
                      "Key": jsonInput[i].No,
                      "AuxiliaryIndex1": jsonInput[i].AuxiliaryIndex1,
                      "Datum": fullDate,
                      "EndDatum": endFullDate,
                      "Ort": jsonInput[i].Location_Name + ", " + jsonInput[i].Location_Address + ", " + jsonInput[i].Location_Post_Code + " " + jsonInput[i].Location_City,
                      "Auslastung": jsonInput[i].Participant_total   + "/" + jsonInput[i].Max_Participant,
                      "Dauer": jsonInput[i].Duration,
                      "Text": text,
                      "Kind": jsonInput[i].Kind,
                      "Preis": price,
                      "button": "Details test"} );
    }

    return resArray;
  }

  function HtmlDecode(s:any)
  {
    var el:HTMLDivElement = document.createElement("div");
    el.innerHTML = s;
    s = el.innerText;
    return s;
  }

  
function createButtons(fullSearch: boolean)
  {
    var content:any[] = [];

    if(fullSearch)
    {
      content.push(<DefaultButton key={"primButtonKey"} className={"WvibButtonPrim_Search"} onClick={() => search()}>Suchen</DefaultButton>);
    }

    // content.push(<DefaultButton  key={"defButtonKey"} className={"WvibButton"} onClick={() => filter()}>Filtern</DefaultButton>);

    return content;
  }
}
