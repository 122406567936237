import * as React from 'react';
import {useHistory} from 'react-router-dom';
import SearchComponent from './SearchComponent_Anon';
import VeranstaltungKachelComponent from './VeranstaltungKachelComponent_Anon';
import WordCloud from './WordCloud_Anon';
import RubrikKachelComponent from './RubrikKachelComponent_Anon';
import AzureConnector from '../Module/AzureConnector_Anon';
import Header from './Header';
import Footer from './Footer';
import SearchLogo  from './SearchLogo';
import GoogleAnalytics from './GoogleAnalytics';
import { DefaultButton, SearchBox } from 'office-ui-fabric-react';
import { off } from 'process';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/WordCloud.css');
// require('../css/AkademieStyles.css');
export interface IStartseiteAkademieProps {
  description: string;
  onChangeHeader:any;
}

let tagCloud:React.CSSProperties = {
  margin: '10px 0',
};

let rubrikTable:React.CSSProperties = {
  margin: '5% -0.5em 0px -1em',
  width: '100%',
  maxWidth: '100%',
  boxShadow: 'none',
  color: 'black'
};

export default function StartseiteAkademie(props: IStartseiteAkademieProps) {
  const history = useHistory();
  const [veranstaltungen, setVeranstaltungen] = React.useState(null);
  const [currentVeranstaltungen, setCurrentVeranstaltungen] = React.useState(null);
  const [destinationKey, setDestinationKey] = React.useState("");
  const [destinationAux, setDestinationAux] = React.useState("");
  const [rubriken, setRubriken] = React.useState(null);
  const [rubrikTilesJsx, setRubrikTilesJsx] = React.useState(null);
  const [searchResult, setSearchResult] = React.useState(true);
  const [searchResultText, setSearchResultText] = React.useState("");
  const [currMetaData, setcurrMetaData] = React.useState(null);
  
  let currShareUrl = window.location.href;
  // const azureCon = new AzureConnector();

  React.useEffect(() => {
    if(destinationKey === "" && destinationAux === "")
    {
      // eslint-disable-next-line no-restricted-globals
      const search = new URLSearchParams(location.search);
      if (search.has('destinationKey') && search.has('destinationAux')) {
        setDestinationKey(search.get('destinationKey'));
        setDestinationAux(search.get('destinationAux'));
      }
    }else if(destinationKey !== "" && destinationAux !=="")
    {
      // history.push({pathname:"VeranstaltungsDetails", search:"?key=" + destinationKey + "&Aux=" + destinationAux});
      history.push({pathname:"VeranstaltungsDetails", search:"?key=" + destinationKey});
    }
    if(rubriken===null)
    {
      fetch('/assets/json/terms.json').then((res) => res.json())
      .then((result) => {
          setRubriken(result);
      })
    }
    if(currMetaData===null)
    {
      setcurrMetaData({title:"Seminarfinder",description:"Seminare und Lehrgänge",url:currShareUrl,image:"/assets/News/banner01.jpg"});
      props.onChangeHeader({title:"Seminarfinder",description:"Seminare und Lehrgänge",url:currShareUrl,image:"/assets/News/banner01.jpg"});
    }
  },[destinationAux, destinationKey, history, rubriken,currMetaData]);

  // React.useEffect(() => {
  //   if(destinationKey !== "" && destinationAux !=="")
  //   {
  //     history.push({pathname:"VeranstaltungsDetails", search:"?key=" + destinationKey + "&Aux=" + destinationAux});
  //   }
  // },[destinationAux, destinationKey, history]);
  
  React.useEffect(() => {
    if(!searchResult)
    {
      setSearchResultText("Es wurden keine Ergebnisse zu ihrer Suche gefunden.");
      setCurrentVeranstaltungen(null);
    }
    else
    {
      setSearchResultText("");
    }
  },[searchResult]);

  React.useEffect(() => {
    if(currentVeranstaltungen===null && veranstaltungen!==null)
    setCurrentVeranstaltungen(veranstaltungen);
  },[currentVeranstaltungen, veranstaltungen]);

  React.useEffect(() => {
    if(rubriken != null && rubriken !== "")
    {
      if(rubrikTilesJsx==null)
      {
        var tmploadRubrikTiles=loadRubrikTiles();
        setRubrikTilesJsx(tmploadRubrikTiles);
      }
    }
  }, [loadRubrikTiles, rubrikTilesJsx, rubriken]);

  const eventsTilesJsx = loadEventTiles();
  const searchJsx = loadSearch();
  const wordCloudJsx = loadWordCloud();

  return (
    <div>
      <header className={"App-header"}>
        <Header/>
        {/* <div className={"container-fluid "}>
            <div className="search-row">
              <div className="search-container">
                <SearchLogo/>
              </div>
            </div>
        </div> */}
        <div className={"WvibGenericPageLimiter"}>
          <div className={"container-fluid"}>
            <div className={"container-row"}>
             {/* <div className='col-lg-4 akademie-start-text' ><strong>Hinweis</strong>
              <p>
                Wenn Sie sich bei Ihrer letzten Bestellung für das vereinfachte Buchen registriert haben, nutzen Sie bitte den:
              </p>
              </div> */}
              {/* <div className='col-lg-8 akadmie-Button'> */}
              {/* <DefaultButton key={"primButtonKey"} className={"WvibButtonPrim_Search start-button"} onClick={() => {window.open("https://wvibde.sharepoint.com/sites/WVIB_Akademie#/","_blank")}}>Seminarfinder für registrierte Benutzer</DefaultButton>*/}
              {/* </div>  */}
              <div className='col-lg-12 akademie-Title'><h3>Akademie - Seminare und Lehrgänge</h3></div>
              <div style={{fontFamily:'Roboto'}}
               className="col-lg-12">
                <div> 
                  <a href={`${process.env.PUBLIC_URL}/assets/dokumente/wvib-Seminarkatalog-2023.pdf`} target='_self'><img style={{width: '100%'}} src="/assets/News/banner01.jpg" alt="banner"></img></a>
                </div>
                <div className='search-table-box' style={{border: '1px solid #C7C7C7', marginTop: '2%',}}>
                  {searchJsx}
                </div>
                <div style={{color: 'black', paddingTop: '1%'}}>
                  {searchResultText}
                </div>
                <div>
                  {eventsTilesJsx}
                </div>
                <div className={"container"} style={rubrikTable}>
                  <h3>Ihre Themen</h3>
                  {rubrikTilesJsx}
                </div>
                <div style={tagCloud}>
                  <h3 style={{marginBottom: '2%'}}>Relevante Themen</h3>
                  {wordCloudJsx}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <GoogleAnalytics/>
      <Footer/>
    </div>
  );

  function loadSearch()
  {
    return <SearchComponent key="searchKey" description="" setVeranstaltung={setVeranstaltungen} setSearchResult={setSearchResult} veranstaltungen={veranstaltungen} setCurrentVeranstaltung={setCurrentVeranstaltungen} fullSearch={true} rubrik=""/>;
  }

  function loadWordCloud()
  {
    return <WordCloud key="wordCloudKey"/>;
  }

  function loadEventTiles()
  {
    var eventTiles:any[] = [];

    if(currentVeranstaltungen != null)
    {
      var _currentVeranstaltungen:any[]=currentVeranstaltungen.sort((a:any, b:any) =>a.Datum.split('.').reverse().join().localeCompare(b.Datum.split('.').reverse().join()));
     
      for(var i:number = 0; i < _currentVeranstaltungen.length; i++)
      {
        eventTiles.push(<VeranstaltungKachelComponent key={"eventsTilesKey" + i} description="" veranstaltung={_currentVeranstaltungen[i]} />);
      }
      return eventTiles;
    }
    return <></>;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function loadRubrikTiles()
  {
    var rubrikTiles:any[] = [];

    for(var i:number = 0; i < rubriken.length; i++)
    {
    
      rubrikTiles.push(<div key={"rubrikKey" + i} className={`${"tile-Box"} col-lg-3 col-md-6 col-sm-12`} >
      <RubrikKachelComponent title={rubriken[i]}></RubrikKachelComponent>
    </div>);
    }

    return <div className={"row"} >{rubrikTiles}</div>;
  }
}
