import * as React from 'react';
//import newData from '../Model/news.json';
import { useHistory } from "react-router-dom";
import AzureConnector from '../Module/AzureConnector';
const azurecon = new AzureConnector();
let result: any[] = [];
let tmpContentHtml: any = null;
export default function Tiles() {
  let history = useHistory();
  const [loadedData, setloadedData] = React.useState<any[]>([]);
  // const [htmlControl, setcontrolResult] = React.useState(null);
  let tmpNews:any[]=[];
  React.useEffect(() => {
    // azurecon.getTestTerms().then((result:any)=>{
    //   result.forEach((element:any)=> {
    //     tmpNews.push({element});
        
    //   });
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   tmpNews=tmpNews.sort((a, b) => b.publishedDate - a.publishedDate);
    //   setloadedData(tmpNews);
    // });
    fetch('/assets/json/terms.json').then((res) => res.json())
      .then((result) => {
        setloadedData(result);
      })
  },[]);
  // React.useEffect(() => {
  //   if(loadedData!=null &&loadedData.length>0 && htmlControl==null)
  //   {
  //     GetUnique(loadedData);
  //   }
  // });
  if(loadedData!=null &&loadedData.length>0 )
    {
      GetUnique(loadedData);
    }
  return (<div className="row content-container"><h1 className="home-webPart-Title col-lg-12">Ihre Szene</h1>{tmpContentHtml}</div>);
  // function patchImg(elment: any) {
  //   var img = elment;
  //   img.target.src = process.env.PUBLIC_URL + "/images/events/Rubriken/WVIBTitel.png";
  //   return false;
  // }
  //  Method: Get Unique Terms by termid
  function GetUnique(_worldCloud: any[]) {
    result.length = 0;
    const map = new Map();
    // console.log(item.element.term)
    _worldCloud.forEach((e1: any) => {
      if (!map.has(e1)) {
        map.set(e1, true);
        let posUrl = '/posts/' + e1;
        result.push({
          title: e1,
          blogUrl: posUrl,
        });
      }
    });
    if(result!=null && result.length>0)
    {
    tmpContentHtml=result.map((item, i) => (
      <div id={String(i++)} className="home-tile-Box col-lg-3 col-md-6 col-sm-12">
        <div className="home-tile-outer" onClick={(e) => history.push(item.blogUrl)}>
          <div className="home-tile-inner" onClick={function () { }}>
            <div className="home-image-box">
              {/* <img className="smallIcon" src={process.env.PUBLIC_URL + "/images/events/Rubriken/" + item.title + ".png"} alt={item.title} onError={(e) => patchImg(e)} /> */}
              <img className="smallIcon" src={process.env.PUBLIC_URL + "/images/events/Rubriken/" + item.title + ".png"} alt={item.title} />

            </div>
            <div className="home-tileTitle">
              {item.title}
            </div>
          </div>
        </div>
      </div>
    ));
    
 //   setcontrolResult(tmpContentHtml);
    }
  }
}
