import React from 'react';
import ReactLoading from 'react-loading';
import '../css/custom-style.css';
import moment from 'moment';
import { Carousel } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// import newData from '../Model/news.json';
// import contactsData from '../Model/contacts.json';
//import evenstData from '../Model/veranstaltung.json';
// import themenData from '../Model/themen.json';
import { useHistory } from "react-router-dom";
import { FileIcon } from 'react-file-icon';
import Header from './Header';
import Footer from './Footer';
import SearchLogo from './SearchLogo';
import parse from 'html-react-parser';
import AzureConnector from '../Module/AzureConnector';
import { PnPClientStorage } from "@pnp/common";
import GoogleAnalytics from './GoogleAnalytics';
// import Flinque from './Flinque';
const storage = new PnPClientStorage();
let storageContacts: string = 'storageContacts';
let storageThemen: string = 'storageThemenstorageThemen';
let storageDocuments: string = 'storageDocuments';
let storageCommunties: string = 'storageCommunties';
const azurecon = new AzureConnector();
/* param */

let currTermList: any[] = [];
let currtermID: string;
let topBannerHtml: any = null;
let postsHtmlControl: any = null;
let wordCloudList: any[] = [];
let topVeranstaltungHtml: any = null;
let loading: boolean = false;

/* end param*/
export interface IPostsProps {
  onChangeHeader:any;
}

export default function Posts(props:IPostsProps) {
 //var jsonNewsData =require("../assets/json/news.json");
  const [newsData, setNews] = React.useState<any[]>([]);
  const [documentsData, setstorageDocuments] = React.useState<any[]>([]);
  const [storageEvents, setStorageEvents] = React.useState<any[]>([]);
  const [contatcsData, setcontatcsData] = React.useState<any[]>([]);
  const [communtiesData, setCommuntiesData] = React.useState<any[]>([]);
  const [themenData, setthemenData] = React.useState<any[]>([]);
  const [currentTermName, setcurrentTermName] = React.useState("");
  const [currMetaData, setcurrMetaData] = React.useState(null);
  let eventsHtml: any = null;
  let weiterbildungHtml: any = null;
  let communitiesHtml: any = null;
  let docshtml: any = null;
  let testMontialHtml: any = null;
  let themenMapHtml: any = null;
  let contactHtml: any = null;
  let wordcloudHtml: any = null;
  let history = useHistory();
  let emptyDiv: any[] = [];
  let educationArray:any[]=["E-TRAINING","Lehrgang","Lehrgang Klammer mit Modulen",
  "Lehrgangsmodul","Online-Seminar","Seminar"];
  let currShareUrl = window.location.href;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { termId }:any = useParams();

  //#region get all Information from Azure or Session , set usestate
  React.useEffect(() => {
    if ({ termId } != null) {
      currtermID = { termId }.termId;
      if(currtermID==='Frauen, MINT, Zukunft')
        {
          currtermID="Diversity";
          history.push("/posts/" + currtermID);  
          window.location.reload();
        }
      document.title = currtermID;
      setcurrentTermName(currtermID);
    }
  }, [currentTermName, termId]);
  React.useEffect(() => {
    if(currentTermName!=="")
    {
    azurecon.getEvents("keywords/" + currentTermName + "/events").then((result: any) => {
      let tmpStatics: any[] = [];
      result.forEach((element: any) => {
        tmpStatics.push(element);
      });
      setStorageEvents(tmpStatics);
    });
  }

  }, [currentTermName]);
  React.useEffect(() => {
    
    if(newsData.length===0)
    {
      fetch('/assets/json/news.json').then((res) => res.json())
      .then((jsonNewsData) => {
        setNews(jsonNewsData);
      });
    }
    let storDocumens = storage.session.get(storageDocuments);
    if (storDocumens != null && storDocumens.length > 0) {
      setstorageDocuments(storDocumens);
    } else {
      azurecon.getDocs().then((result: any) => {
        let tmpStatics: any[] = [];
        result.forEach((element: any) => {
          tmpStatics.push(element);
        });
        setstorageDocuments(tmpStatics);
      });
    }
    let storContacts = storage.session.get(storageContacts);
    if (storContacts != null && storContacts.length > 0) {
      setcontatcsData(storContacts);
    } else {
      azurecon.getContacts().then((result: any) => {
        let tmpStatics: any[] = [];
        result.forEach((element: any) => {
          tmpStatics.push(element);
        });
        setcontatcsData(tmpStatics);
      });
    }
    let storCommunities = storage.session.get(storageCommunties);
    if (storCommunities != null && storCommunities.length > 0) {
      setCommuntiesData(storCommunities);
    } else {
      azurecon.getCommunitiesList().then((result: any) => {
        let storCommunities: any[] = [];
        result.forEach((element: any) => {
          storCommunities.push(element);
        });
        //////console.log(storCommunities);
        setCommuntiesData(storCommunities);
      });
    }
    let storThemen = storage.session.get(storageThemen);
    if (storThemen != null && storThemen.length > 0) {
      setthemenData(storThemen);
    } else {
      azurecon.getThemenImages().then((result: any) => {
        let tmpStatics: any[] = [];
        result.forEach((element: any) => {
          tmpStatics.push(element);
        });
        setthemenData(tmpStatics);
      });
    }
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  if (newsData != null && newsData.length > 0) {
    GetNewsList(newsData);
  } else {
    topVeranstaltungHtml = null;
    topBannerHtml = null;
    postsHtmlControl = null;
  }

  /* Generate Themen List and Get Themen-Tiles for the Current Term*/
  if (themenData != null && themenData.length > 0) {
    GetThemenList(themenData);
  } else {
    testMontialHtml = (<div className="placeholder"></div>);
    themenMapHtml = (<div className="placeholder"></div>);
  }
  /* Get Contacts for current Term*/
  if (contatcsData != null && contatcsData.length > 0) {
    GetContacts(contatcsData);
  } else {
    contactHtml = (<div className="placeholder"></div>);
  }

  /* Get Events for current Term*/
  if (documentsData != null && documentsData.length > 0) {
    SetDocuments(documentsData);
  } else {
    docshtml = (<div className="placeholder"></div>);
  }
  if (storageEvents != null && storageEvents.length > 0) {
    GetEvent(storageEvents);
  } else {
    eventsHtml = (<div className="placeholder"></div>);
  }
  if (communtiesData != null && communtiesData.length > 0) {
    GetCommunites(communtiesData);
  } else {
    communitiesHtml = (<div className="placeholder"></div>);
  }

//#endregion
 //#region main retrun , main render
  return (
    <div className="App" key="posts">
      <header className="App-header">
        {/* Page Content */}
        <Header />
        <div className="container-fluid">
          <div className="search-row">
            <div className="container-row">
              <SearchLogo />
            </div>
          </div>
          <div className="row container-row" hidden={!loading}>
          <div className="col-sm-12 col-md-12 col-lg-12">
          <nav className="breadcrumb-nav">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/" target="_self">Home</a></li>
            <li className="breadcrumb-item"><span className="last-Item">{currentTermName}</span></li>
          </ol>
        </nav>
          </div>

            {/* Posts Column */}
            <div className="col-sm-12 col-md-12 col-lg-12 post-col">
              {topBannerHtml}
            </div>
            <div className="col-sm-12 col-lg-8 posts-section">
              {emptyDiv}
              {topVeranstaltungHtml}
              <div className="col-lg-12 post-container">{postsHtmlControl}</div>
            </div>
            {/* Sidebar Widgets Column */}
            <div className="col-sm-12 col-lg-4 right-sidebar">
              <div>
                {testMontialHtml}
                {communitiesHtml}
                {contactHtml}
                {themenMapHtml}
              </div>
            </div>
            <div className="col-sm-12 col-lg-12">
              <div className="row bottom-row">
                <div className="col-sm-12 col-lg-8 event-Box">
                  {eventsHtml}
                </div>
                <div className="col-sm-12 col-lg-8 event-Box">
                  {weiterbildungHtml}
                </div>
                
                {/* <div className="col-sm-12 col-lg-4">
                  {contactHtml}
                </div> */}
              </div>
              <div className="row bottom-row">
                <div className="col-sm-12 col-lg-8 event-Box">
                  {docshtml}
                </div>
                {/* <div className="col-sm-12 col-lg-4">
                  
                </div> */}
                {/* <div className="tag-content" hidden={!wordcloudHtml}>
                {wordcloudHtml}
              </div> */}
              </div>
            </div>
          </div>
          {/* /.row */}
          <div className="row  loading-box" hidden={loading}>
            <ReactLoading color={"#293940"} width={175} type={"spin"} />
          </div>
        </div>
        {/* /.container */}
      </header>
      <GoogleAnalytics/>
      {/* <Flinque/> */}
      <Footer />
    </div>
  );
  //#endregion
//#region begin help methods

  function trunCat(desc: string, max: number) {
    if (desc != null && desc.length > 0) {
      var suffix = "...";
      desc = desc.length < max ? desc : `${desc.substr(0, desc.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
    }
    return desc
  }
  function _getSiteUrl(fullName: any) {
    let pageUrl: any = null;
    let name: any = null;
    var tmpName = fullName.toString().split(/[\s/]+/);
    if (tmpName.length > 1) {
      name = tmpName[tmpName.length - 1];
      pageUrl = "/Pages/" + name;
    }
    return pageUrl;
  }
  //#endregion
  
  //#region main Methods to get specific infos and Set htmls Objects
  function ParseCurrCode(currtTXT:any){
    var parseHtmlCode=null;
    if(currtTXT!==undefined && currtTXT!==null)
    {
      parseHtmlCode=parse(currtTXT);
    }
    return parseHtmlCode;
  }
  function GetEvent(_eventsList: any[]) {
    eventsHtml = null;
    weiterbildungHtml=null;
    let veranstaltung: any[] = [];
    let weiterbildung: any[] = [];
    let eventResults: any[] = [];
    let educationResults: any[] = [];
    let eventsList:any[]=[];
    let educationList:any[]=[];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const divMap = new Map();
    const educationMap = new Map();
    if(_eventsList.length>9)
    _eventsList =_eventsList.slice(0, 9);
    _eventsList = _eventsList.sort((a, b) => new Date(a.Start_date).getTime() - new Date(b.Start_date).getTime());
    educationList=_eventsList.filter(a=>educationArray.includes(a.Kind));
    eventsList=_eventsList.filter(a=>!(educationArray.includes(a.Kind)));
    ////console.log(educationList);
    ////console.log(eventsList);
    // begin render Veranstaltung
    eventsList.forEach((v, i) => {
      eventResults.push({
        id: i,
        title: v.Description,
        // description: trunCat(v.Description_2, 50),
        description: v.Description_2,
        location: v.Location_City,
        endDate: v.End_date,
        startDate: v.Start_date,
        // eventLink: {pathname:"/VeranstaltungsDetails", search:"?key=" +  v.No + "&Aux=" + v.AuxiliaryIndex1}
        eventLink: {pathname:"/VeranstaltungsDetails", search:"?key=" +  v.No }
        
      });
    });
    eventResults = eventResults.sort((a, b) => a.startDate - b.startDate);
    eventResults.forEach(item => {
      if (!divMap.has(item.id)) {
        divMap.set(item.id, true);
        // const startDate = moment.utc(new Date(item.startDate)).format('DD.MM.YYYY');
        const startDate = moment(new Date(item.startDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
        //const startTime = item.Time_from;
        //let _bottomStartDate="";
        //let _bottomStartDate = moment(item.startDate).locale('de').format('llll');
        // change Time if empty, beacuse it not exist in Start_Date
        // if (startTime != null && startTime !== "") {
        //   let timeArray = startTime.split(':');
        //   let currStartDate = moment(item.startDate).hour(timeArray[0]).minute(timeArray[1]).toDate();
        //   _bottomStartDate = moment(currStartDate).locale('de').format('llll');
        // }
        if (item.endDate !== "" && item.endDate !== item.startDate && new Date(item.endDate) > new Date(item.startDate)) {
          // const endDate = moment.utc(new Date(item.endDate)).format('DD.MM.YYYY');
          const endDate = moment(new Date(item.endDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
          veranstaltung.push(
            <div className="row event-item" onClick={() => {history.push(item.eventLink) }}>
              <div className="col-md-2 event-date-post">
                <div className="startEndDate">
                  <div className="startDate-box">{startDate}</div><hr />
                  <div className="endDate-box">{endDate}</div>
                </div>
              </div>
              <div className="col-md-10 event-Txt-Box">
                <div className="card-body">
                  <h5 className="card-title event-title">{ParseCurrCode(item.title)}</h5>
                  { <p className="card-text event-desc">{ParseCurrCode(item.description)}</p> }
                  {/* <p className="card-text event-loc">{item.location}</p> */}
                </div>
              </div>
            </div>
          );
        } else {
          let day, restDate = null;
          if (item.startDate !== null && item.startDate !== "") {
            day = moment(item.startDate).locale('de').format('DD');
            restDate = moment(item.startDate).locale('de').format('MMM YYYY');
          }
          veranstaltung.push(
            <div className="row event-item" onClick={() => {history.push(item.eventLink) }}>
              <div className="col-md-2 event-date-post">
                <div className="startDate">
                  <div className="day-box">{day}</div>
                  <div className="year-box">{restDate}</div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="card-body event-Txt-Box">
                  <h5 className="card-title event-title">{ParseCurrCode(item.title)}</h5>
                  { <p className="card-text event-desc">{ParseCurrCode(item.description)}</p> }
                  {/* <p className="card-text event-loc">{_bottomStartDate}</p> */}
                </div>
              </div>
            </div>
          );
        }
      }
    });
    educationList.forEach((v, i) => {
      educationResults.push({
        id: i,
        title: v.Description,
        // description: trunCat(v.Description_2, 50),
        description: v.Description_2,
        location: v.Location_City,
        endDate: v.End_date,
        startDate: v.Start_date,
        eventLink: {pathname:"/VeranstaltungsDetails", search:"?key=" +  v.No}
        // eventLink: {pathname:"/VeranstaltungsDetails", search:"?key=" +  v.No + "&Aux=" + v.AuxiliaryIndex1}
      });
    });
    educationResults = educationResults.sort((a, b) => a.startDate - b.startDate);
    educationResults.forEach(item => {
      if (!educationMap.has(item.id)) {
        educationMap.set(item.id, true);
        // const startDate = moment.utc(new Date(item.startDate)).format('DD.MM.YYYY');
        const startDate = moment(new Date(item.startDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
        //const startTime = item.Time_from;
        //let _bottomStartDate="";
        //let _bottomStartDate = moment(item.startDate).locale('de').format('llll');
        // change Time if empty, beacuse it not exist in Start_Date
        // if (startTime != null && startTime !== "") {
        //   let timeArray = startTime.split(':');
        //   let currStartDate = moment(item.startDate).hour(timeArray[0]).minute(timeArray[1]).toDate();
        //   _bottomStartDate = moment(currStartDate).locale('de').format('llll');
        // }
        if (item.endDate !== "" && item.endDate !== item.startDate && new Date(item.endDate) > new Date(item.startDate)) {
          // const endDate = moment.utc(new Date(item.endDate)).format('DD.MM.YYYY');
          const endDate = moment(new Date(item.endDate)).tz('Europe/Berlin').format('DD.MM.YYYY');
          weiterbildung.push(
            <div className="row event-item"onClick={() => {history.push(item.eventLink) }}>
              <div className="col-md-2 event-date-post">
                <div className="startEndDate">
                  <div className="startDate-box">{startDate}</div><hr />
                  <div className="endDate-box">{endDate}</div>
                </div>
              </div>
              <div className="col-md-10 event-Txt-Box">
                <div className="card-body">
                  <h5 className="card-title event-title">{ParseCurrCode(item.title)}</h5>
                  { <p className="card-text event-desc">{ParseCurrCode(item.description)}</p> }
                  {/* <p className="card-text event-loc">{item.location}</p> */}
                </div>
              </div>
            </div>
          );
        } else {
          let day, restDate = null;
          if (item.startDate !== null && item.startDate !== "") {
            day = moment(item.startDate).locale('de').format('DD');
            restDate = moment(item.startDate).locale('de').format('MMM YYYY');
          }
          weiterbildung.push(
            <div className="row event-item" onClick={() => {history.push(item.eventLink) }}>
              <div className="col-md-2 event-date-post">
                <div className="startDate">
                  <div className="day-box">{day}</div>
                  <div className="year-box">{restDate}</div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="card-body event-Txt-Box">
                  <h5 className="card-title event-title">{ParseCurrCode(item.title)}</h5>
                  { <p className="card-text event-desc">{ParseCurrCode(item.description)}</p> }
                  {/* <p className="card-text event-loc">{_bottomStartDate}</p> */}
                </div>
              </div>
            </div>
          );
        }
      }
    });
    if (veranstaltung.length > 0) {
      let tmpEvents: any = null;
      tmpEvents = veranstaltung.map(function (item) {
        return <div className="event-item-box">{item}</div>;
      });
      if (tmpEvents != null)
        eventsHtml = <div className="card mb-3 event-Box"><h4 className="webpart-Title col-lg-12">Veranstaltungen</h4>{tmpEvents}</div>
    } else {
      eventsHtml = <div className="placeholder"></div>
    }
    if (weiterbildung.length > 0) {
      let tmpEvents: any = null;
      tmpEvents = weiterbildung.map(function (item) {
        return <div className="event-item-box">{item}</div>;
      });
      if (tmpEvents != null)
      weiterbildungHtml = <div className="card mb-3 event-Box"><h4 className="webpart-Title col-lg-12">Weiterbildung</h4>{tmpEvents}</div>
    } else {
      weiterbildungHtml = <div className="placeholder"></div>
    }

  }
  function GetCommunites(_communitiesList: any) {
    let linksHTMLArray: any[] = [];
    communitiesHtml = null;
    let listMap = new Map();

    for (const item of _communitiesList) {
      // eslint-disable-next-line no-loop-func
      item.terms.forEach((e1: any) => {
        if (e1.termName === currtermID && !listMap.has(item.id)) {
          listMap.set(item.id, true);
          let linkUrl = "";
          let title = item.title;
          if (item.link != null && item.link !== "") {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            linkUrl = _getSiteUrl(item.link);
            linksHTMLArray.push(
              <div className="row quickLink-Item" onClick={() => history.push(linkUrl)}>
                <div className="col-md-1">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/quikLinkIcon.png`} className="quicklinksIcon" alt="icon" />
                </div>
                <div className="col-md-10 quicklink-txt" data-automation-id="quick-links-item-title">
                  {ParseCurrCode(title)}
                </div>
              </div>);
          }

        }
      });
    }
    // if(linksHTMLArray.length>5)
    // linksHTMLArray=linksHTMLArray.slice(0,5);
    if (linksHTMLArray.length > 0)
      communitiesHtml = <div className="col-md-12"><h4 className="webpart-Title col-lg-12">Communities</h4>{linksHTMLArray}</div>
  }
  function SetDocuments(_docsList: any) {
    let documentsHTML: any[] = [];
    docshtml = null;
    let docsMap = new Map();
    for (const item of _docsList) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      var currentICon: JSX.Element;
      // eslint-disable-next-line no-loop-func
      item.term.forEach((e1: any) => {
        if (e1.termName === currtermID && !docsMap.has(item.imgSrc)) {
          docsMap.set(item.imgSrc, true);
          var exst = item.imgSrc.toString().split(/[\s.]+/);
          //var name="";
          if (exst != null && exst.length > 0) {
            //name=exst[0];
            switch (exst[exst.length - 1]) {
              case 'pdf':
                currentICon = <FileIcon extension="pdf" />;
                break;
              case 'docx':
                currentICon = <FileIcon extension="docx" />;
                break;
              case 'xlsx':
                currentICon = <FileIcon extension="xslx" />;
                break;
              case 'pptx':
                currentICon = <FileIcon extension="pptx" />;
                break;

            }
          }
          documentsHTML.push(
            <div className="row doc-Item" onClick={() => { window.open(`${process.env.PUBLIC_URL}/assets/dokumente/${item.imgSrc}`, '_self') }}>
              <div className="col-md-1">
                <div className="doc-icon">
                  {currentICon}
                </div>
              </div>
              <div className="col-md-11">
                <div className="card-body doc-item">
                  <h5 className="card-title doc-title">{item.name!==null?item.name:item.imgSrc}</h5>
                </div>
              </div>
            </div>
          );
        }
      }
      );
    }
    if (documentsHTML.length > 0) {
      let tmpDocResult: any = null;
      tmpDocResult = documentsHTML.map(function (item) {
        return <div className="doc-item">{item}</div>;
      });
      if (tmpDocResult !== null)
        docshtml = <div className="row"><h4 className="webpart-Title col-lg-12">Dokumente</h4>{tmpDocResult}</div>

    } else {
      docshtml = null;
    }

  }
  function GetThemenList(_themenList: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let themenResult: any[] = [];
    testMontialHtml = null;
    themenMapHtml = null;
    const map = new Map();
    for (const item of _themenList) {
      // eslint-disable-next-line no-loop-func
      item.term.forEach((e1: any) => {
        if (e1.termName === currtermID) {
          // let currImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
          let currImgSrc=`${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
          if (item.imgSrc != null) {
            currImgSrc = `${process.env.PUBLIC_URL}/assets/themen/${item.imgSrc}`;
          }
          themenResult.push({
            title: item.title,
            category: item.category,
            termId: Number(e1.termId),
            description: item.description,
            themeUrl: item.themeUrl,
            imgSrc: currImgSrc,
            date: new Date(item.publishedDate)
          });
        }
      }
      );
    }
    if (themenResult.length > 1)
      themenResult = themenResult.sort((a, b) => b.date - a.date);
    themenResult.forEach(e1 => {
      switch (e1.category) {
        case 'Testimonial':
          if (!map.has(e1.category)) {
            map.set(e1.category, true);
            testMontialHtml = (
              <div className="col-sm-6 col-lg-12 themenItem" key={e1.category}>
                <img src={`${process.env.PUBLIC_URL}${e1.imgSrc}`} className='card-img-top themen-img' alt={e1.imgSrc} />
              </div>);

          };
          break;

        case 'Werbung':
          if (!map.has(e1.category)) {
            map.set(e1.category, true);
            themenMapHtml = (
              <div className="col-sm-6 col-lg-12 themenItem mapLink" key={e1.category} onClick={e=>{window.open(e1.themeUrl,'_self')}}>
                <img src={`${process.env.PUBLIC_URL}${e1.imgSrc}`} className='card-img-top themen-img' alt={e1.imgSrc} />
              </div>);

          };
          break;
      }
    });
  }
  function GetContacts(_contactList: any[]) {
    contactHtml = null;
    let contactTMpResult: any[] = [];
    let contactResult: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const map = new Map();
    for (const item of _contactList) {
      // eslint-disable-next-line no-loop-func
      item.term.forEach((e1: any) => {
        ////console.log("Termname aus der Liste",e1.termName);
        ////console.log("URL wert",currtermID);
        if (e1.termName === currtermID && !map.has(item.name)) {
          map.set(item.name, true);
          contactTMpResult.push(item);
         
        }
      });
    }
    // sort Contacts per Ranking
    if(contactTMpResult.length>0)
    contactTMpResult=contactTMpResult.sort((a,b)=>a.ranking.length-b.ranking.length);
    for (const item of contactTMpResult) {
      // eslint-disable-next-line no-loop-func
      // item.term.forEach((e1: any) => {
      //   if (e1.termName === currtermID && !map.has(item.name)) {
      //     map.set(item.name, true);
          let currEmail = "mailto:" + item.email + "?subject=Kontakt";
          contactResult.push(
            <div className={`contact-col col-md-12`} >
              <div className="contact-box row">
                <div className="contact-box-col col-md-3">
                  <div className="imageArea-85">
                    <div className="image-134">
                      <img className="image-135" src={`${process.env.PUBLIC_URL}/assets/Kontakte/${item.imgSrc}`} alt={item.name} />
                    </div>
                  </div>
                </div>
                <div className="text-box col-md-9">
                  <div className="name-box">{item.name}</div>
                  {/* <div className="desc-box">{e2.Unternehmen}</div>*/}
                  <div className="desc-box">{item.position}</div> 
                  {/* <div className="desc-box"><a href={currEmail}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-email.svg`} className="icon-box" alt="email" /></a><div className="phone-box"><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-phone.svg`} className="icon-box" alt="phone" />{item.telefon}</div></div> */}
                  <div className="desc-box"><a href={currEmail} style={{visibility: item.email? 'visible' : 'hidden' }}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-email.svg`} style={{visibility: item.email? 'visible' : 'hidden' }} className="icon-box" alt="email" /></a><div className="phone-box" style={{visibility: item.telefon ? 'visible' : 'hidden' }}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-phone.svg`} className="icon-box" alt="phone" />{item.telefon}</div></div>
                  {/* <div className="desc-box"></div> */}
                </div>
              </div>
            </div>);

      //   }
      // }
      // );
    }
    if (contactResult.length > 0) {
      let tmpContactHtml: any = [];
      tmpContactHtml = contactResult.map(function (item) {
        return <div className="contact-item">{item}</div>;
      });
      if (tmpContactHtml !== null)
        contactHtml = <div className="row contact-row"><div className="post-contact-box"><h4 className="webpart-Title col-lg-12">​​​​​​​Ansprechpartner</h4>{tmpContactHtml}</div></div>
    }
  }

  function GetNewsList(_newsList: any[]) {
    let result: any[] = [];
    let resultTopBanner: any[] = [];
    let resultTopVeranstaltung: any[] = [];
    topVeranstaltungHtml = null;
    topBannerHtml = null;
    postsHtmlControl = null;
    result.length = 0;
    wordCloudList.length = 0;
    const map = new Map();
    const wordMap = new Map();
    const postsMap = new Map();
    if(_newsList.length>1)
    
    _newsList=_newsList.sort((a,b)=>new Date(b.publishedDate).getTime()-new Date(a.publishedDate).getTime());
    
    for (const item of _newsList) {
      // eslint-disable-next-line no-loop-func
      item.term.forEach((e1: any) => {
        if (e1.termName === currtermID) {
         var isNotTop=true;
          //e1.termName!=="Top-Banner" || e1.termName!=="Top-Veranstaltung"
          item.term.forEach((e2: any) => {
            if ((e2.termName !== "Top-Banner") && (e2.termName !== "Top-Veranstaltung") && (e2.termName !== currtermID)) {
              if (!wordMap.has(e2.termId)) {
                wordMap.set(e2.termId, true);
                wordCloudList.push({
                  label: e2.termName,
                })
              }
            }
          });
          let blogUrl = "/information/" + encodeURIComponent(item.urltitle)+'/'+currentTermName;
           // let currImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
          let currImgSrc = `${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
          if (item.imgSrc != null) {
            currImgSrc = `${process.env.PUBLIC_URL}/assets/News/${item.imgSrc}`;
          }
          if(currMetaData===null){
            var tmpDescr=item.description!==undefined&&item.description!==null?trunCat(item.description,50):"";
            setcurrMetaData({title:currtermID,description:tmpDescr,url:currShareUrl,image:currImgSrc});
            props.onChangeHeader({title:currtermID,description:tmpDescr,url:currShareUrl,image:currImgSrc});
          }
          var curDate = item.publishedDate;
          if (curDate != null && curDate !== "") {
            var newDate = convertUTCDateToLocalDate(curDate);
            curDate = moment(newDate).locale('de').format("LL");
          }
          let topBannerResult = item.term.filter((term: { termName: string; }) => term.termName === "Top-Banner");
          let topEventResult = item.term.filter((term: { termName: string; }) => term.termName === "Top-Veranstaltung");
          if (topBannerResult.length > 0) {
            resultTopBanner.push({
              id: item.id,
              title: item.title,
              urltitle: item.urltitle,
              category: item.category,
              escription: item.description,
              blogUrl: blogUrl,
              imgSrc: currImgSrc,
              date: new Date(item.publishedDate)
            });
          }
          if (topEventResult.length > 0) {
            resultTopVeranstaltung.push({
              id: item.id,
              title: item.title,
              urltitle: item.urltitle,
              category: item.category,
              escription: item.description,
              blogUrl: blogUrl,
              imgSrc: currImgSrc,
              date: new Date(item.publishedDate)
            });
          }
          item.term.forEach((e2: any) => {
            if ((e2.termName === "Top-Banner") || (e2.termName === "Top-Veranstaltung")) {
              isNotTop=false;
            }
          });
          if(isNotTop)
          {
            if(!postsMap.has(item.id))
            {
              result.push({
              id: item.id,
              title: item.title,
              category: item.category,
              termName: e1.termName,
              description: trunCat(item.description, 150),
              blogUrl: blogUrl,
              imgSrc: currImgSrc,
              date: curDate
              });
              postsMap.set(item.id, true);
            }
        }
       
          if (!map.has(e1.termId)) {
            map.set(e1.termId, true);
            currTermList.push({
              termName: e1.termName,
              termId: e1.termId
            });
          }
        }
      });
    }
    if (resultTopVeranstaltung.length > 1)
      resultTopVeranstaltung = resultTopVeranstaltung.sort((a, b) => b.date - a.date);
    if (resultTopBanner.length > 1)
      resultTopBanner = resultTopBanner.sort((a, b) => b.date - a.date);
    if (result.length > 1) {
     // result = result.sort((a, b) => b.date - a.date);
      if (result.length > 8)
        result = result.slice(0, 8);
      postsHtmlControl = result.map(function (item) {
        return (
          <div className='post-Item'>
            <div className='post-img'>
              <div className='ms-Image root-188 link-pointer'  onClick={() => {
                  history.push(item.blogUrl)
                }}>
                <img src={item.imgSrc} className='post-img' alt={item.imgSrc} />
              </div>
            </div>
            <div className='post-content'>
              <div className='post-title link-pointer' onClick={() => {
                  history.push(item.blogUrl)
                }}>{ParseCurrCode(item.title)}</div>
             <div className='post-desc'>{item.description}</div>
             <div className="link-box">
              <div className='post-authorDate'>
                <div className='post-date'>
                  {item.date}
                </div>
              </div>
                 {/* <span className='post-link' onClick={() => {
                  history.push(item.blogUrl)
                }}>mehr lesen
                <hr className="read-line"></hr></span>  */}

              </div>
            </div>
          </div>
        )
      });
      topBannerHtml = null;
      if (resultTopBanner.length > 0) {
        let multiItems = true;
        if (resultTopBanner.length > 3)
          resultTopBanner = resultTopBanner.slice(0, 3);
        if (resultTopBanner.length === 1)
          multiItems = false
        resultTopBanner = resultTopBanner.slice(0, 3);
        topBannerHtml = <div className="topCaroussel" >
          <Carousel indicators={multiItems} controls={multiItems} id="topCarousel" slide={true} interval={4000}>{resultTopBanner.map((banner) => (
            <Carousel.Item onClick={() => {
              // history.push('/information/'+ newsItem.id)
              history.push('/information/'+ encodeURIComponent(banner.urltitle));
            } }>
              <img
                className="d-block w-100"
                //src={`${banner.imgSrc}`}
                src={banner.imgSrc}
                alt={banner.title}
              />
              {/* <div className="black-overlay"></div> */}
              <Carousel.Caption>
                <h1 className="caroussel-Title" onClick={() => {
                  history.push('/information/' + encodeURIComponent(banner.urltitle)+'/'+currentTermName);
                }}>
                  <span>{banner.title}</span>
                </h1>
                <div onClick={() => {
                  history.push('/information/' + encodeURIComponent(banner.urltitle)+'/'+currentTermName);
                }} className="link-txt"><span className="arrow-right">mehr erfahren</span><span className="arrow"><img src="/assets/images/right_Arrow.png" alt="" /></span></div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}</Carousel>
        </div>;
      }

      if (resultTopVeranstaltung.length > 0) {
        let multiItems = true;
        if (resultTopVeranstaltung.length > 3)
          resultTopVeranstaltung = resultTopVeranstaltung.slice(0, 3);
        if (resultTopVeranstaltung.length === 1)
          multiItems = false
        topVeranstaltungHtml = <div className="topCaroussel" >
          <Carousel indicators={multiItems} controls={multiItems} id="topVeranstaltung" slide={true} interval={4000}>{resultTopVeranstaltung.map((banner) => (
            <Carousel.Item onClick={() => {
              // history.push('/information/'+ newsItem.id)
              history.push('/information/'+ encodeURIComponent(banner.urltitle));
            } }>
              <img
                className="d-block w-100"
                //src={`${banner.imgSrc}`}
                src={banner.imgSrc}
                alt={banner.title}
              />
              {/* <div className="black-overlay"></div> */}
              <Carousel.Caption>
                <h1 className="caroussel-Title" onClick={() => {
                  history.push('/information/' +encodeURIComponent(banner.urltitle)+'/'+currentTermName);
                }}>
                  <span>{banner.title}</span>
                </h1>
                <div onClick={() => {
                  history.push('/information/' +encodeURIComponent(banner.urltitle)+'/'+currentTermName);
                }} className="link-txt"><span className="arrow-right">mehr erfahren</span><span className="arrow"><img src="/assets/images/right_Arrow.png" alt="" /></span></div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}</Carousel>
        </div>;
      }

    }

    if (wordCloudList.length > 15) {
      wordCloudList = GetRandom(wordCloudList, 15);
    }
    if (wordCloudList.length > 0) {

      wordcloudHtml = wordCloudList.map(function (item) {
        return (<div className="tag-item" onClick={(e) => history.push('/posts/' + item.label)}><div className="tag-area"><div className="tag-Icon-box"><span>#</span></div></div><div className="tag-Txt">{item.label}</div></div>)
      });
      wordcloudHtml = <div className="row tagCloudBox"><h4 className="webpart-Title col-lg-12">Häufige Schlagworte</h4>{wordcloudHtml}</div>
    } else {
      wordcloudHtml = (<div className="placeholder"></div>)
    }

    loading = true;
  }
  function convertUTCDateToLocalDate(date:any) {
    var offset = moment().utcOffset();
    var newDate = moment.utc(date).utcOffset(offset);
    return newDate;     
  }
  function GetRandom(arr: any, n: any) {
    var currResult = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      currResult[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return currResult
  }
  //#endregion
}
