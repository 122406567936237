import axios from 'axios';
import { PnPClientStorage } from "@pnp/common";
const storage = new PnPClientStorage();
// import qs from 'qs';
const FUNCTIONCODE = 'yD5kzLej5_cvfPkUeZQKuJBuqaVg8xavYvPmIc4hWcVsAzFuqikJOQ==';
const FUNCTIONID="default";
const azureFuncUrl = 'https://wvibpulictestbc.azurewebsites.net/api';
//const qs = require('qs');
export default class AzureConnectorToBC {
  private storageNews: string = 'storageNews';
  private storageContacts: string = 'storageContacts';
  private storageStaticpages: string = 'staticpages';
  private storageThemen: string = 'storageThemen';
  private storageEvents: string = 'storageEvents';
  private storageDocuments: string = 'storageDocuments';
  private storageConfig: string = 'storageConfig';
  private storageCommunties: string = 'storageCommunties';
  private storageTerms: string = 'terms';


  azureInstance: any;
 
 //#region get Contacts
  public getContacts(): Promise<void> {

      return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
          let config = {
              headers: {
                  // Authorization: "Bearer " + this.auth_token,
                  Accept: "application/json, text/plain, */*",
                  'Content-Type': "application/json; charset=utf-8",
                  'x-functions-key': FUNCTIONCODE,
                  'x-functions-id': FUNCTIONID
              }
          };
          let currAzureFuncUrl = azureFuncUrl + '/sp/contacts';
          axios.get(currAzureFuncUrl, config).then((res: any): void => {
              let currArray: any[] = [];
              if (res.data.length > 0) {
                  res.data.forEach((element: any) => {
                      currArray.push(element);
                  });
                  // //console.log(currArray);
                  // currArray=currArray.sort((a, b) => (a.ranking) - (b.ranking));
                  // //console.log("after sort",currArray);
                  resolve(currArray);
              } else {
                  resolve(currArray);
              }
          }).catch((error: any) => {
              //console.log(error);
              reject(error);
          });

      });

  }
  //#endregion
  public getETestAF(WSServiceName: string) {
    return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
        //#region  get EventsCard Section
        let config = {
            headers: {
                // Authorization: "Bearer " + this.auth_token,
                Accept: "application/json, text/plain, */*",
                'Content-Type': "application/json; charset=utf-8",
                'x-functions-key': FUNCTIONCODE,
                'x-functions-id': FUNCTIONID
            }
        };
        let currAzureFuncUrl = azureFuncUrl + '/bc/' + WSServiceName;
        axios.get(currAzureFuncUrl, config).then((res) => {
        // Handle successful response
        console.log("Response data:", res.data);
        let currArray: any[] = [];

        if (res.data !== null) {
            currArray.push(res.data)
            resolve(currArray);
        } else {
            resolve(currArray);
        }
    }).catch((error) => {
        // Handle error
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log("Server responded with status:", error.response.status);
            console.log("Response data:", error.response.data);
            reject(error);
        } else if (error.request) {
            // The request was made but no response was received
            console.log("No response received from server.");
            reject(error);
        } else {
            // Something else went wrong
            console.log("Error:", error.message);
            reject(error);
        }
    });
    //     axios.get(currAzureFuncUrl, config).then((res: any): void => {
    //         let currArray: any[] = [];

    //         if (res.data !== null) {
    //             currArray.push(res.data)
    //             resolve(currArray);
    //         } else {
    //             resolve(currArray);
    //         }
    //     }).catch((error: any) => {
    //         //console.log(error);
    //         reject(error);
    //     });
    //     //#endregion
     });
}
  public getEventsCard(WSServiceName: string) {
      return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
          //#region  get EventsCard Section
          let config = {
              headers: {
                  // Authorization: "Bearer " + this.auth_token,
                  Accept: "application/json, text/plain, */*",
                  'Content-Type': "application/json; charset=utf-8",
                  'x-functions-key': FUNCTIONCODE,
                  'x-functions-id': FUNCTIONID
              }
          };
          let currAzureFuncUrl = azureFuncUrl + '/bc/' + WSServiceName;
          axios.get(currAzureFuncUrl, config).then((res) => {
            // Handle successful response
            console.log("Response data:", res.data);
            let currArray: any[] = [];
    
            if (res.data !== null) {
                currArray.push(res.data)
                resolve(res.data);
            } else {
                resolve(currArray);
            }
        }).catch((error) => {
            if (error.response) {
                console.log("Server responded with status:", error.response.status);
                console.log("Response data:", error.response.data);
                reject(error);
            } else if (error.request) {
                console.log("No response received from server.");
                reject(error);
            } else {
                console.log("Error:", error.message);
                reject(error);
            }
        });
          //#endregion
      });
  }
  public getEventlineCluster(WSServiceName: string) {
    return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
        //#region  get EventsCard Section
        let config = {
            headers: {
                // Authorization: "Bearer " + this.auth_token,
                Accept: "application/json, text/plain, */*",
                'Content-Type': "application/json; charset=utf-8",
                'x-functions-key': FUNCTIONCODE,
                'x-functions-id': FUNCTIONID
            }
        };
        let currAzureFuncUrl = azureFuncUrl + '/bc/' + WSServiceName;
        axios.get(currAzureFuncUrl, config).then((res) => {
          // Handle successful response
          console.log("Response data:", res.data);
          let currArray: any[] = [];
  
          if (res.data !== null) {
              currArray.push(res.data)
              resolve(res.data);
          } else {
              resolve(currArray);
          }
      }).catch((error) => {
          if (error.response) {
              console.log("Server responded with status:", error.response.status);
              console.log("Response data:", error.response.data);
              reject(error);
          } else if (error.request) {
              console.log("No response received from server.");
              reject(error);
          } else {
              console.log("Error:", error.message);
              reject(error);
          }
      });
        //#endregion
    });
}
  //#region StartseiteConfig-Data Section
 
}
