import * as React from 'react';
import { useHistory} from 'react-router-dom';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/WordCloud.css');
// require('../css/AkademieStyles.css');

export interface IRubrikKachelComponentProps {
  title: string;
}

export default function RubrikKachelComponent(props: IRubrikKachelComponentProps) {
  const history = useHistory();
  
  React.useEffect(() => {
  },[]);


 return (
    // <div style={topTable} onClick={function() {history.push({pathname:"Veranstaltungsübersicht", search:"?rubrik=" + props.title});} }>
    //   {/* <div>
    //     <img style={smallIcon} id={guid} src={"images/events/Rubriken/" + props.title + ".png"} onError={patchImg}></img>
    //   </div> */}
    //   <div style={tileTitle}>
    //     {props.title}
    //   </div>
    // </div>
    <div className="tile-outer" onClick={(e) => history.push({pathname:"Veranstaltungsübersicht", search:"?rubrik=" + props.title})}>
      <div className="tile-inner">
        <div className="tileTitle">
        <div className="tileSpan">{props.title}</div>
        </div>
      </div>
    </div>
  );

}
